import styles from 'components/cards/order/orderCard.module.scss';
import utilStyles from 'styles/utils.module.scss';

import PipeIcon from "images/icons/pipe.svg";
import ArrowRightIcon from "images/icons/arrow_right_757575.svg";
import classNames from "classnames";
import {formatDate} from "common/utils";
import Link from "next/link";
import {getOrderDetailRoute, getProductRoute} from "common/const";


export default function OrderCard (props) {
    const {children, orderNumber, orderedAt, showLinkToDetail} = props;

    const onDetailClick = (e) => {
        e.stopPropagation();
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderCardDetailClick',
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={utilStyles.noLineHeight}>
                    <span className={styles.date}>{formatDate(orderedAt)}</span>
                    <PipeIcon className={styles.pipeIcon} />
                    <span className={styles.orderNumber}>{orderNumber}</span>
                </div>
                {
                    showLinkToDetail &&
                        <div className={classNames(utilStyles.noLineHeight, utilStyles.cursorPointer)}>
                            <Link href={getOrderDetailRoute(orderNumber)}>
                                <a className={classNames(utilStyles.noLineHeight)}  onClick={onDetailClick}>
                                    <span className={styles.orderDetailLink}>주문상세</span>
                                </a>
                            </Link>
                            <ArrowRightIcon className={styles.arrowIcon} />
                        </div>
                }
            </div>
            {children}
        </div>
    );
}
