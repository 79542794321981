import BaseOrderOptionCard, {ActionButton, AddToPouchButton} from "components/cards/order/BaseOrderOptionCard";
import Link from "next/link";
import {getExchangeDetailRoute} from "common/const";
import styles from "components/cards/order/baseOrderOptionCard.module.scss";


const ExchangeDetailButton = ({orderNumber, exchangeId}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderExchangeCardExchangeDetailClick',
        );
    };
    return (
        <Link href={getExchangeDetailRoute(orderNumber, exchangeId)}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>교환 상세</ActionButton>
            </a>
        </Link>
    )
}

const CancelExchangeButton = ({cancelExchange}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderExchangeCardCancelExchangeClick',
        );
        cancelExchange();
    };
    return (
        <div className={styles.buttonWrapper} onClick={onClick}>
            <ActionButton>교환 철회</ActionButton>
        </div>
    )
}

const PickupCheckButton = ({trackingLink}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderExchangePickupCheckClick',
        );
    };
    return (
        <Link href={trackingLink}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>수거 조회</ActionButton>
            </a>
        </Link>
    )
}

const ResendCheckButton = ({trackingLink}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderExchangeCardResendCheckClick',
        );
    };
    return (
        <Link href={trackingLink}>
            <a className={styles.buttonWrapper} target="_blank" onClick={onClick}>
                <ActionButton>배송 조회</ActionButton>
            </a>
        </Link>
    )
}

const GetButtons = (orderNumber, orderOption, onPurchaseConfirmSuccess) => {
    switch (orderOption.status) {
        case '교환접수':
            return (
                <>
                    <ExchangeDetailButton orderNumber={orderNumber} exchangeId={orderOption.order_exchange_id}/>
                    <AddToPouchButton orderOption={orderOption}/>
                </>
            )
        case '교환상품 수거중':
            return (
                <>
                    <ExchangeDetailButton orderNumber={orderNumber} exchangeId={orderOption.order_exchange_id}/>
                    {
                        orderOption.pickup_tracking_link &&
                        <PickupCheckButton trackingLink={orderOption.pickup_tracking_link} />
                    }
                    <AddToPouchButton orderOption={orderOption}/>
                </>
            )
        case '교환상품 배송중':
            return (
                <>
                    <ExchangeDetailButton orderNumber={orderNumber} exchangeId={orderOption.order_exchange_id}/>
                    {
                        orderOption.resend_tracking_link &&
                        <ResendCheckButton trackingLink={orderOption.resend_tracking_link} />
                    }
                </>
            )
        default:
            return (
                <>
                    <ExchangeDetailButton orderNumber={orderNumber} exchangeId={orderOption.order_exchange_id}/>
                    <AddToPouchButton orderOption={orderOption}/>
                </>
            )
    }
}

export default function OrderExchangeCard(props) {
    const {orderNumber, orderOption, showCancelOrNoButton, cancelExchange} = props;

    const onPurchaseConfirmSuccess = props.onPurchaseConfirmSuccess || function () {};

    let finalStatus = orderOption.status;
    let extra = '';
    if ((orderOption.status === '교환상품 수거중' || orderOption.status === '교환접수') && orderOption.is_pending) {
        extra = ' (발송보류)';
    }
    if ((orderOption.status === '교환상품 수거중' || orderOption.status === '교환상품 배송중') && orderOption.is_delayed) {
        extra = ' (배송지연)';
    }
    finalStatus += extra;

    return (
        <BaseOrderOptionCard
            productOverrideLink={orderOption.product_override_link}
            brandName={orderOption.brand_name}
            count={orderOption.count}
            productName={orderOption.product_name}
            productId={orderOption.product_id}
            status={finalStatus}
            imageUrl={orderOption.product_image_url}
            optionName={orderOption.option_name}
            price={orderOption.unit_sell_price}
            disableLink={props.disableLink}
        >
            {
                showCancelOrNoButton
                    ?
                    (
                        orderOption.status === '교환접수' &&
                        <CancelExchangeButton cancelExchange={cancelExchange}/>
                    )
                    : GetButtons(orderNumber, orderOption, onPurchaseConfirmSuccess)
            }
        </BaseOrderOptionCard>
    )
}
