import styles from "components/cards/faqCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import {useState} from "react";
import classNames from "classnames";
import LinesEllipsis from "react-lines-ellipsis";
import Paginator from "components/Paginator";
import Image from "next/image";
import {breakLines, customConfirm} from "common/utils";
import PipeIcon from "images/icons/pipe.svg";
import LockIcon from "images/icons/lock.svg";
import {getProductRoute} from "common/const";
import Link from "next/link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {removeLocalReferralInfo} from "common/referral";

export default function FAQCard (props) {
    const {question, answer, showAnsweredState, showDelete, date, nickname, product, onClick, isOpen, image, isSecret} = props;
    const onDelete = props.onDelete || function () {};

    const answered = !!answer;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const pipeIconSize = isMobile ? 12 : 16;

    const onProductClick = () => {
        if (product) {
            removeLocalReferralInfo(product.id);
        }
    }

    return (
        <div className={classNames(styles.container, isOpen ? styles.containerActive : undefined, !isSecret && styles.clickable)}
             onClick={() => {!isSecret && onClick();}}>
            <div>
                {
                    showAnsweredState ?
                        <div className={styles.answeredStateContainer}>
                            <div className={styles.answeredStateWrapper}>
                                <span className={classNames(styles.answerState, answered ? styles.answered : undefined)}>
                                    {answered ? "답변완료" : "답변대기"}
                                </span>
                                <div className={styles.answerMeta}>
                                    {
                                        nickname &&
                                        <>
                                            <span className={styles.answerMetaItem}>
                                                {nickname}
                                            </span>
                                            <PipeIcon
                                                width={pipeIconSize} height={pipeIconSize} viewBox="0 0 16 16"
                                                className={styles.answerMetaIcon}
                                            />
                                        </>
                                    }
                                    <span className={styles.answerMetaItem}>
                                        {date}
                                    </span>
                                </div>
                                {
                                    product ?
                                        <div className={styles.productInfoWrapper}>
                                            <span className={styles.verticalMiddle}>
                                                제품
                                            </span>
                                            <PipeIcon
                                                width={pipeIconSize} height={pipeIconSize} viewBox="0 0 16 16"
                                                className={styles.answerMetaIcon}
                                            />
                                            <Link href={getProductRoute(product.id)}>
                                                <a target="_blank" className={classNames(utilStyles.noLineHeight)}
                                                   onClick={e => {
                                                       onProductClick();
                                                       e.stopPropagation();
                                                   }}
                                                >
                                                    <span className={styles.productInfo}>{`[${product.brand}] ${product.name}`}</span>
                                                </a>
                                            </Link>
                                        </div> :
                                        <div style={{height: 8}}/>
                                }
                            </div>
                            {
                                isOpen && showDelete ?
                                    <div className={styles.deleteButton} onClick={(e) => {
                                        e.stopPropagation();
                                        customConfirm(
                                            '문의를 삭제하시겠습니까?',
                                            '삭제하기',
                                            '취소',
                                            () => {
                                                onDelete();
                                            },
                                            () => {},
                                        );
                                    }}>
                                        <span className={styles.deleteButtonText}>삭제</span>
                                    </div>
                                    : <div/>
                            }
                        </div> :
                        <div/>
                }
                <div className={utilStyles.flexRow}>
                    <span className={styles.icon}>Q</span>
                    <>
                        {
                            isSecret ?
                                <span>
                                    <span className={styles.content}>{"비밀글 입니다."}</span>
                                    <LockIcon className={styles.secretLock}/>
                                </span>
                                :
                                <span className={styles.content}>
                                    {
                                        //TODO: truncate
                                        !isOpen ?
                                            <LinesEllipsis
                                                text={question.split('\n').slice(0, 2).join('\n')}
                                                maxLine={2}
                                                ellipsis="..."
                                                trimRight
                                                basedOn='letters' />
                                            :
                                            breakLines(question)
                                    }
                                </span>
                        }
                    </>
                    {
                        isOpen && !!image &&
                        <div className={styles.image}>
                            <Image unoptimized layout="fill" src={image} />
                        </div>
                    }
                </div>
            </div>
            {
                isOpen && answered &&
                <div className={styles.answerContainer}>
                    <span className={styles.icon}>A</span>
                    <span className={styles.content}>{breakLines(answer)}</span>
                </div>
            }
        </div>
    )
};
