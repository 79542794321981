import styles from "components/input/signUpAgreement.module.scss";
import utilStyles from "styles/utils.module.scss";
import CheckboxIcon from 'images/icons/checkbox_checked.svg';
import UncheckedCheckboxIcon from 'images/icons/checkbox_unchecked.svg';
import classNames from "classnames";
import {marketingAgreementRoute, privacyPolicyRoute, termsOfServiceRoute} from "common/const";

export const KEY_AGE  = 'is_age_confirmed';
export const KEY_TERMS_OF_SERVICE = 'is_terms_of_service_agreed';
export const KEY_PRIVACY_POLICY = 'is_privacy_policy_agreed';
export const KEY_MARKETING_AGREED  = 'is_marketing_agreed';

export default function SignUpAgreement (props) {
    const {formik, disabled} = props;

    const {values, setValues, setFieldValue} = formik;

    const allAgreed = values[KEY_AGE] && values[KEY_TERMS_OF_SERVICE] && values[KEY_PRIVACY_POLICY] && values[KEY_MARKETING_AGREED];

    return (
        <div className={styles.container}>
            <div className={classNames(styles.checkboxContainer, styles.agreeAllContainer)} onClick={
                () => {
                    if (disabled) {
                        return;
                    }
                    if (allAgreed) {
                        setValues(values => {
                            const newValues = JSON.parse(JSON.stringify(values));
                            newValues[KEY_AGE] = false;
                            newValues[KEY_TERMS_OF_SERVICE] = false;
                            newValues[KEY_PRIVACY_POLICY] = false;
                            newValues[KEY_MARKETING_AGREED] = false;
                            return newValues;
                        })
                    } else {
                        setValues(values => {
                            const newValues = JSON.parse(JSON.stringify(values));
                            newValues[KEY_AGE] = true;
                            newValues[KEY_TERMS_OF_SERVICE] = true;
                            newValues[KEY_PRIVACY_POLICY] = true;
                            newValues[KEY_MARKETING_AGREED] = true;
                            return newValues;
                        })
                    }
                }
            }>
                {
                    allAgreed ?
                        <CheckboxIcon className={styles.checkbox} />
                        :
                        <UncheckedCheckboxIcon className={styles.checkbox} />
                }
                <span className={classNames(styles.agreeText, styles.agreeAllText)}>약관 모두 동의</span>
            </div>
            <div className={styles.border}/>
            <div className={styles.checkboxContainer} onClick={() => !disabled && setFieldValue(KEY_AGE, !values[KEY_AGE])}>
                {
                    !!values[KEY_AGE] ? <CheckboxIcon className={styles.checkbox} /> : <UncheckedCheckboxIcon className={styles.checkbox} />
                }
                <span className={styles.agreeText}>[필수] 만 14세 이상</span>
            </div>
            <div className={styles.checkboxContainer} onClick={() => !disabled && setFieldValue(KEY_TERMS_OF_SERVICE, !values[KEY_TERMS_OF_SERVICE])}>
                {
                    !!values[KEY_TERMS_OF_SERVICE] ? <CheckboxIcon className={styles.checkbox} /> : <UncheckedCheckboxIcon className={styles.checkbox} />
                }
                <span className={styles.agreeText}>[필수] 이용약관 동의</span>
                <a className={styles.seeMore} onClick={e => e.stopPropagation()} target="_blank" href={termsOfServiceRoute}>내용보기</a>
            </div>
            <div className={styles.checkboxContainer} onClick={() => !disabled && setFieldValue(KEY_PRIVACY_POLICY, !values[KEY_PRIVACY_POLICY])}>
                {
                    !!values[KEY_PRIVACY_POLICY] ? <CheckboxIcon className={styles.checkbox} /> : <UncheckedCheckboxIcon className={styles.checkbox} />
                }
                <span className={styles.agreeText}>[필수] 개인정보 처리방침 동의</span>
                <a className={styles.seeMore} onClick={e => e.stopPropagation()} target="_blank" href={privacyPolicyRoute}>내용보기</a>
            </div>
            <div className={styles.checkboxContainer} onClick={() => !disabled && setFieldValue(KEY_MARKETING_AGREED, !values[KEY_MARKETING_AGREED])}>
                {
                    !!values[KEY_MARKETING_AGREED] ? <CheckboxIcon className={styles.checkbox} /> : <UncheckedCheckboxIcon className={styles.checkbox} />
                }
                <span className={styles.agreeText}>[선택] 마케팅 정보 수신 및 활용 동의</span>
                <a className={styles.seeMore} onClick={e => e.stopPropagation()} target="_blank" href={marketingAgreementRoute}>내용보기</a>
            </div>
        </div>
    )
}