import utilStyles from 'styles/utils.module.scss';
import styles from 'components/layout/TopBar/topBar.module.scss';

import classNames from "classnames";
import Logo from "images/logo.svg";

import DeliveryIcon from "images/icons/delivery.svg";
import PouchIcon from "images/icons/pouch.svg";
import SearchIcon from "images/icons/search.svg";
import HomeIcon from "images/icons/home_black.svg";
import NotificationIcon from "images/icons/notification.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useContext, useState} from "react";
import UserContext from "context/AuthContext";
import Link from "next/link";
import {getHomeRoute, notificationRoute, ordersRoute, pouchRoute, searchRoute} from "common/const";
import {useRouter} from "next/router";
import PouchContext from "context/PouchContext";
// import ArrowBackIcon from "images/icons/arrow_back.svg";
import ArrowLeftIcon from "images/icons/arrow_left_757575.svg";
import {Axios} from "api";
import {toast} from "react-toastify";
import {captureException, captureMessage} from "@sentry/nextjs";
import {requestAppStoreReview} from "common/app_store_review";
import {isApp} from "common/utils";


export default function TopBar ({isBack, home, search, notification, order, pouch, isPro}) {
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const userContext = useContext(UserContext);
    const pouchContext = useContext(PouchContext);
    const user = userContext ? userContext.user : null;
    const isBetaTesterCandidate = !!user && user.is_beta_tester_candidate;
    const isBetaTester = !!user && user.is_beta_tester;
    const router = useRouter();

    const pouchItemCount = user ? (user.pouch_item_count ? user.pouch_item_count : 0) : pouchContext.pouchItems.length;

    const deliveryCount = user && user.order_in_progress_count ? user.order_in_progress_count : 0;

    const onLogoClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("TopBarLogoClick");
    };

    const onHomeIconClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("TopBarHomeIconClick");
    };

    const onSearchIconClick = () => {
        setIsSearchFocused(true);
        typeof mixpanel !== 'undefined' && mixpanel.track("TopBarSearchIconClick");
    };

    const onNotificationIconClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("TopBarSearchIconClick");
    }

    const onDeliveryIconClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("TopBarDeliveryIconClick", {deliveryCount});
    };

    const onPouchIconClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("TopBarPouchIconClick", {pouchItemCount});
    };

    const toggleBeta = async () => {
        try {
            const res = await Axios.put('v1/user/beta/');
            if (res.status < 400) {
                if (res.data.is_beta_tester) {
                    toast.info('베타 테스터가 되었습니다. 새로고침 됩니다.');
                } else {
                    toast.info('베타 테스터가 해제되었습니다. 새로고침 됩니다.');
                }
                router.reload();
            } else {
                toast.info(res.data.display_message || '베타 여부 변경에 실패했어요. 잠시 후 다시 시도해주세요.');
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            toast.info('베타 여부 변경에 실패했어요. 잠시 후 다시 시도해주세요.');
            captureException(e);
        }
    };

    return (
        <>
            <div className={classNames(utilStyles.fullWidth, styles.topBar)} style={{backgroundColor: isPro ? 'black' : undefined}} >
                <div className={classNames(utilStyles.rootContainer, styles.container, utilStyles.flexRow, utilStyles.justifyContentSpaceBetween)}>
                    <>
                        {
                            !isBack ?
                                <Link href={getHomeRoute()}>
                                    <div className={styles.logoWrapper}>
                                        <a onClick={onLogoClick}>
                                            <Logo
                                                viewBox="0 0 134 24"
                                                height={18}
                                                width={105}
                                                className={classNames(utilStyles.verticalMiddle, isPro ? styles.whiteIcon : undefined)}
                                            />
                                        </a>
                                        {
                                            !!isPro &&
                                                <div className={styles.pro}>PRO</div>
                                        }
                                        {
                                            isBetaTesterCandidate &&
                                            <div
                                                className={classNames(styles.betaCandidate, isBetaTester && styles.beta)}
                                                onClick={toggleBeta}
                                            >베타 {isBetaTester ? 'O' : 'X'}</div>
                                        }
                                        {
                                            isBetaTester && isApp() &&
                                            <div
                                                className={classNames(styles.betaCandidate)}
                                                onClick={requestAppStoreReview}
                                            >리뷰</div>
                                        }
                                    </div>
                                </Link>
                                :
                                <ArrowLeftIcon
                                    viewBox="0 0 16 16"
                                    className={
                                        classNames(styles.leftIcon, utilStyles.cursorPointer, isPro ? styles.whiteIcon : undefined)
                                    } onClick={() => router.back()}
                                />
                        }

                    </>
                    <div>
                        {
                            home &&
                            <Link href={getHomeRoute()}>
                                <a className={styles.element} onClick={onHomeIconClick}>
                                    <HomeIcon viewBox="0 0 24 24" className={classNames(styles.icon, isPro ? styles.whiteSearchIcon : undefined)} />
                                </a>
                            </Link>
                        }
                        {
                            search &&
                            <Link href={searchRoute}>
                                <a className={styles.element} onClick={onSearchIconClick}>
                                    <SearchIcon className={classNames(styles.icon, isPro ? styles.whiteSearchIcon : undefined)} />
                                </a>
                            </Link>
                        }
                        {
                            !!user && notification &&
                            <Link href={notificationRoute}>
                                <a className={styles.element} onClick={onNotificationIconClick}>
                                    {
                                        user.notification_checked_ts < user.last_notification_ts &&
                                        <div className={styles.notificationNewBadge} />
                                    }
                                    <NotificationIcon className={classNames(styles.icon, isPro ? styles.whiteIcon : undefined)} />
                                </a>
                            </Link>
                        }
                        {
                            order &&
                            <Link href={ordersRoute}>
                                <a className={styles.element} onClick={onDeliveryIconClick}>
                                    <DeliveryIcon viewBox="0 0 24 24" className={classNames(styles.icon, isPro ? styles.whiteIcon : undefined)} />
                                    {
                                        deliveryCount > 0
                                            ?
                                            <div className={styles.itemCount}>
                                                <span>{deliveryCount}</span>
                                            </div>
                                            :
                                            <div/>
                                    }
                                </a>
                            </Link>
                        }
                        {
                            pouch &&
                            <Link href={pouchRoute}>
                                <a className={styles.element} onClick={onPouchIconClick}>
                                    <PouchIcon viewBox="0 0 24 24" className={classNames(styles.icon, isPro ? styles.whitePouchIcon : undefined)} />
                                    {
                                        pouchItemCount > 0 &&
                                        <div className={styles.itemCount}>
                                            <span>{pouchItemCount}</span>
                                        </div>
                                    }
                                </a>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
