import styles from "components/cards/order/baseOrderOptionCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import SpeechBubble from "components/SpeechBubble";
import Image from "next/image";

import PipeIcon from "images/icons/pipe.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {numberWithComma} from "common/utils";
import {
    getExchangeDetailRoute,
    getOrderDetailRoute,
    getProductRoute,
    getRefundDetailRoute, getReviewGroupRoute,
    reviewRoute
} from "common/const";
import Link from "next/link";
import {useContext, useEffect, useState} from "react";
import UserContext from "context/AuthContext";
import {Axios} from "api";
import Modal from "components/Modal";
import FullButton from "components/buttons/FullButton";
import CheckIcon from "images/icons/check_circle.svg";
import classNames from "classnames";
import ArrowRightIcon from "images/icons/arrow_right_757575.svg";
import {on} from "react-use/lib/misc/util";
import addOptionCountsToPouch from "api/addToPouch";
import {useRouter} from "next/router";
import {STATUS_CONFIRM_PURCHASE} from "pages/mypage/orders";
import {captureException} from "@sentry/nextjs";
import {getReferralInfo, removeLocalReferralInfo} from "common/referral";
import PouchContext from "context/PouchContext";
import {toast} from "react-toastify";


export const ActionButton = (props) => {
    const onClick = props.onClick || function () {};
    const {children} = props;
    return (
        <div className={styles.actionButton} onClick={(e) => {
            onClick();
        }}>
            {children}
        </div>
    )
}

export const AddToPouchButton = ({orderOption}) => {
    const {fetchUser} = useContext(UserContext);
    const pouchContext = useContext(PouchContext);

    const addToPouch = () => {
        const [referralId, referralPostId] = getReferralInfo();
        const options = [
            {
                option_id: orderOption.product_option_id,
                count: 1,
                referral_id: referralId,
                referral_post_id: referralPostId,
                price: orderOption.unit_sell_price
            }
        ];
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardAddToPouch'
        );
        addOptionCountsToPouch(options, false, () => {
            toast.info('장바구니에 담겼습니다.');
            fetchUser();
        }, pouchContext, true, orderOption.product_id, orderOption.product_name);
    }
    return (
        <a className={styles.buttonWrapper} onClick={addToPouch}>
            <ActionButton>장바구니 담기</ActionButton>
        </a>
    )
}

export const WriteReviewButton = (props) => {
    const {productId, orderOption} = props;
    const router = useRouter();
    const onClick = orderOption.review_permission_group.permissions.length > 1 ? () => {
        router.push(getReviewGroupRoute(orderOption.review_permission_group.group_id));
    } : (props.onClick || function () {});
    const maxPoint = orderOption.review_permission_group.max_points;

    return (
        <a className={styles.buttonWrapper} onClick={onClick}>
            <ActionButton>리뷰 작성</ActionButton>
            <div className={styles.speechBubbleWrapper}>
                <SpeechBubble
                    sidePadding={6} verticalPadding={6}
                    pointLength={5}
                    bottom
                >
                    <div className={styles.bubbleContent}>
                        최대 {maxPoint}p
                    </div>
                </SpeechBubble>
            </div>
        </a>
    )
}

export const PurchaseConfirmButton = (props) => {
    const {orderNumber, optionId, pointsToGiveOnPurchaseConfirm} = props;
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [grantPoint, setGrantPoint] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);
    const onPurchaseConfirmSuccess = props.onPurchaseConfirmSuccess || function () {};
    const [purchaseConfirmResult, setPurchaseConfirmResult] = useState(null);

    const confirmPurchase = async () => {
        try {
            setIsLoading(true);
            const res = await Axios.post(`v1/store/orders/${orderNumber}/options/${optionId}/purchase-confirm`, {});
            if (res.status < 400) {
                userContext.setPoint(res.data.total_point);
                setGrantPoint(res.data.grant_point);
                setPurchaseConfirmResult(res.data);
                userContext.fetchUser();
            } else {
                console.log(res);
                alert(res.data.display_message || '일시적인 오류로 구매확정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            captureException(e);
            alert('일시적인 오류로 구매확정에 실패했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (grantPoint && !isModalOpen) {
            onPurchaseConfirmSuccess();
        }
    }, [isModalOpen]);

    return (
        <a className={styles.buttonWrapper}>
            <Modal isOpen={isModalOpen} round close={() => setIsModalOpen(false)}>
                <div className={styles.purchaseConfirmModalContainer}>
                    {
                        grantPoint === undefined ?
                            <>
                                <span className={styles.modalTitle}>
                                    {
                                        pointsToGiveOnPurchaseConfirm > 0 ?
                                        <>
                                            지금 구매확정 하시면<br /><span className={styles.modalPoint}>{pointsToGiveOnPurchaseConfirm}p</span>가 적립됩니다!
                                        </>
                                        :
                                        <>구매를 확정하시겠어요?
                                        </>
                                    }

                                </span>
                                <span className={styles.modalDescription}>구매확정 이후에는 반품/교환이 불가하므로<br />반드시 제품을 받으신 후 진행해주세요.</span>
                                <div className={styles.modalButtonWrapper}>
                                    <FullButton fontSize={16} title="구매 확정하기" height={44} onClick={confirmPurchase} />
                                </div>
                                <div className={styles.modalButtonWrapper}>
                                    <FullButton fontSize={16} white title="취소" height={44} onClick={() => setIsModalOpen(false)} />
                                </div>
                            </>
                            :
                            <div>
                                <CheckIcon viewBox="0 0 32 32" width={isMobile ? 28 : 32} height={isMobile ? 28 : 32} />
                                {
                                    grantPoint > 0 &&
                                    <span className={styles.modalGrantPoint}>+{numberWithComma(grantPoint)}p</span>
                                }
                                <span className={styles.modalGrantDone}>구매확정이 완료되었습니다.</span>
                                <div className={classNames(styles.modalTotalPointContainer)}>
                                    <span>나의 포인트</span>
                                    <span className={classNames(styles.modalTotalPoint)}>{numberWithComma(userContext.user.point)}p</span>
                                </div>
                                <Link href={reviewRoute}>
                                    <a className={styles.reviewRewardContainer} onClick={() => {
                                        // setIsModalOpen(false);
                                    }}>
                                        <span className={styles.reviewRewardText}>리뷰를 작성하면 <br/>
                                            <span className={styles.reviewRewardEmphasis}>
                                                최대 {purchaseConfirmResult ? numberWithComma(purchaseConfirmResult.max_review_points) : '1,000'}p
                                            </span>를 드려요! ✍🏻
                                        </span>
                                        <div className={styles.writeReviewButtonWrapper}>
                                            <FullButton useDiv fontSize={12} title="리뷰쓰기" height={28} />
                                        </div>
                                    </a>
                                </Link>
                                <div className={styles.modalButtonWrapper}>
                                    <FullButton fontSize={16} title="다음에" height={44} onClick={() => setIsModalOpen(false)} light />
                                </div>
                            </div>
                    }
                </div>
            </Modal>
            <ActionButton onClick={() => setIsModalOpen(true)}>구매 확정</ActionButton>
            {
                !!pointsToGiveOnPurchaseConfirm &&
                <div className={styles.speechBubbleWrapper}>
                    <SpeechBubble
                        sidePadding={12} verticalPadding={6}
                        pointLength={5}
                        backgroundColor={'#FF939F'}
                        bottom
                    >
                        <div className={styles.bubbleContent}>
                            {pointsToGiveOnPurchaseConfirm}p 받기
                        </div>
                    </SpeechBubble>
                </div>
            }
        </a>
    )
}


export default function BaseOrderOptionCard (props) {
    const {
        count, brandName, productId, productName, optionName, price,
        status, imageUrl, children, disableLink, productOverrideLink, giftReceiverName, isReservation,
        reservationTime,
    } = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    const onProductClick = () => {
        removeLocalReferralInfo(productId);
    }

    const Wrapper = ({children}) => {
        return (
            disableLink
            ?
                <div className={styles.informationContainer}>
                    {children}
                </div>
            :
                <Link href={productOverrideLink ? productOverrideLink : getProductRoute(productId)}>
                    <a className={styles.informationContainer} onClick={onProductClick}>
                        {children}
                    </a>
                </Link>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.infoSection}>
                <div className={styles.statusWrapper}>
                    <span className={styles.status}>{status}</span>
                    {
                        giftReceiverName &&
                        <>
                            <PipeIcon/>
                            <div className={styles.giftReceiverName}>
                                TO. {giftReceiverName}
                            </div>
                        </>
                    }
                </div>
                <Wrapper>
                    <div className={styles.imageWrapper}>
                        <Image layout="fill" objectFit="cover" unoptimized src={imageUrl} />
                    </div>
                    <div className={styles.infoWrapper}>
                        <span className={styles.brand}>{brandName}</span>
                        <span className={styles.productName}>{productName}</span>
                        <div className={styles.optionInfoWrapper}>
                            <span className={styles.optionName}>{optionName}</span>
                            <>
                                <PipeIcon className={styles.pipeIcon}/>
                                <span className={styles.count}>
                                    {isReservation ? reservationTime : `${count}개`}
                                </span>
                            </>
                        </div>
                        <span className={styles.price}>{numberWithComma(price)}<span className={styles.won}>원</span></span>
                    </div>
                </Wrapper>

            </div>
            {
                children &&
                <div className={styles.buttonSection}>
                    {children}
                </div>
            }
        </div>
    )
}
