import {forwardRef, useEffect, useState} from "react";
import Image from "next/image";
import classNames from "classnames";

import utilStyles from 'styles/utils.module.scss';
import styles from 'components/buttons/naverLoginButton.module.css';
import naverLogo from 'images/icons/naver.png';
import NaverLogo from "images/icons/naver.svg";
import Script from "next/script";
import {
    AUTH_TYPE_GOOGLE,
    AUTH_TYPE_KAKAO,
    AUTH_TYPE_NAVER,
    GOOGLE_CLIENT_ID, KAKAO_CLIENT_ID,
    NAVER_CLIENT_ID,
    SITE_URL
} from "common/const";
import {red} from "@material-ui/core/colors";
import {v4 as uuidv4} from "uuid";
import {useRouter} from "next/router";
import authStyles from "pages/auth/index.module.scss";
import NaverIcon from "images/icons/naver_logo.svg";
import {LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE} from "pages/auth/sign-in";
import SpeechBubble from "components/SpeechBubble";


export const REDIRECT_URI = 'redirect_uri';

const getRedirectUri = (isSignUp) => {
    let uri = `${SITE_URL}/auth/sign-in?auth_type=${AUTH_TYPE_NAVER}`
    if (isSignUp) {
        uri += '&is_sign_up=true'
    }
    return encodeURIComponent(uri);
}

export const promptNaverLogin = (redirect, isSignUp) => {
    // if (!router.isReady) {
    //     return;
    // }
    const state = uuidv4();
    window.localStorage.setItem(AUTH_TYPE_NAVER, state);
    window.localStorage.setItem(REDIRECT_URI, redirect);
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=${NAVER_CLIENT_ID}&redirect_uri=${getRedirectUri(isSignUp)}&state=${state}`;
};

const NaverLoginButton = (props) => {
    const router = useRouter();

    const {disabled, signUp, full, lastLoginType} = props;

    return (
        <a onClick={!disabled ? () => {signUp ? promptNaverLogin(router.query.redirect, true) : promptNaverLogin(router.query.redirect)} : () => {}}>
            {
                full ?
                    <div className={authStyles.wideSNSLoginButton} style={{backgroundColor: '#1EC800'}}>
                        {
                            lastLoginType === AUTH_TYPE_NAVER &&
                            <div className={authStyles.lastLoginBubble} style={{top: -20}}>
                                <SpeechBubble
                                    sidePadding={12} verticalPadding={6}
                                    pointLength={5} borderRadius={9999}
                                    bottom
                                    backgroundColor={utilStyles.brand}
                                >
                                <span className={authStyles.lastLoginBubbleText}>
                                    최근 로그인
                                </span>
                                </SpeechBubble>
                            </div>
                        }
                        <div
                            className={classNames(utilStyles.fullFlex, utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter, utilStyles.textAlignCenter, authStyles.wideButtonText)}
                            style={{color: 'white'}}
                        >
                            <div className={utilStyles.socialIcon}>
                                <NaverIcon />
                            </div>
                            네이버로 계속하기
                        </div>
                    </div>
                    :
                    <NaverLogo className={utilStyles.cursorPointer} />
            }
        </a>
    );
};

export default NaverLoginButton;
