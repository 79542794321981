import 'styles/global.scss';
import {UserContextProvider} from "context/AuthContext";
import React, {useEffect, useRef, useState} from "react";
import Head from "next/head";
import {useRouter} from "next/router";
import {SERVICE_ENV} from "common/const";
import semver from "semver";
import styles from 'pages/_app.module.scss';
import classNames from "classnames";
import {ProductScrapContextProvider} from "context/ProductScrapContext";
import {captureException} from "@sentry/nextjs";
import FeedbackIcon from "images/icons/feedback.svg";
import ScrollTopIcon from "images/icons/scroll_top.svg";
import {PostScrapContextProvider} from "context/PostScrapContext";
import {CategoryContextProvider} from "context/CategoryContext";
import {setGlobalReferralInfo} from "common/referral";
import {Slide, ToastContainer} from "react-toastify";
import CloseIcon from "images/icons/close.svg";
import {KeepAliveProvider} from 'react-next-keep-alive';

import 'react-toastify/dist/ReactToastify.css';
import {MediaContextProvider} from "context/MediaContext";
import Script from "next/script";
import {SellerProductContextProvider} from "context/SellerProductContext";
import {PouchContextProvider} from "context/PouchContext";
import {getScrollKeyForRouteKey, isApp, isIOS, postRNMessage} from "common/utils";
import "styles/react-confirm-alert.scss";
import {ABTestContextProvider} from "context/ABTestContext";
import {PostListContextProvider} from "context/PostListContext";
import {ScrollContextProvider} from "context/ScrollContext";
import {AddPostButton} from "components/buttons/AddPostButton";
import {ModalContextProvider} from "context/ModalContext";
import {PushContextProvider} from "context/PushContext";
import {ToastContextProvider} from "context/ToastContext";
import "styles/odometer.css";
import {v4} from "uuid";
import Cookies from "js-cookie";
import {ReviewScrapContextProvider} from "context/ReviewScrapContext";
import LoadingDot from "components/LoadingDot";
import layoutStyles from "components/layout/layout.module.scss";
import {completeReward} from "common/rewards";
import {REWARD_KEY_LOGIN} from "pages/rewards";
import {getHmkCidInfo, recordBrowseInfo, registerHmkCid, setHmkpid} from "common/hmkcid";
import {redirectToApp, redirectToLogin} from "common/redirect";
import ScriptRunner from "common/ScriptRunner";


(function () {
    if (typeof Object.id == "undefined") {
        var id = 0;

        Object.id = function (o) {
            if (typeof o.__uniqueid == "undefined") {
                Object.defineProperty(o, "__uniqueid", {
                    value: ++id,
                    enumerable: false,
                    // This could go either way, depending on your
                    // interpretation of what an "id" is
                    writable: false
                });
            }

            return o.__uniqueid;
        };
    }
})();

const ScrollTopButton = () => {
    const router = useRouter();
    if (
        router.pathname === '/palette/shorts/[pid]' || router.pathname === '/auth/sign-in'
    ) {
        return <div/>;
    }
    return (
        <div className={styles.scrollTopButtonWrapper} onClick={() => {
            typeof mixpanel !== 'undefined' && mixpanel.track('ScrollTopButtonClick');
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }}>
            <ScrollTopIcon viewBox="0 0 48 48"/>
        </div>

    )
}

const FeedbackButton = () => {
    return (
        <div className={styles.feedbackButtonContainer}>
            <FeedbackIcon viewBox="0 0 20 20"/>
            <span className={styles.feedbackText}>피드백 하기</span>

        </div>
    )
}


export let isRefreshed = true;

let scrollPos = 0;
let accDown = 0;
let accUp = 0;


const MESSAGE_TYPE_PUSH_REGISTRATION = 'push_registration';
const MESSAGE_TYPE_PUSH_OPEN = 'push_open';
const MESSAGE_TYPE_REDIRECT_LOGIN = 'redirect_login';
export const MESSAGE_TYPE_PUSH_PERMISSION_RESULT = 'push_permission_result';
export const MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_RESULT = 'request_push_permission_result';
export const MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_ANDROID_RESULT = 'request_push_permission_android_result';
export const MESSAGE_TYPE_DEVICE_UID_RESULT = 'device_uid_result';
export const MESSAGE_TYPE_USER_AGENT_RESULT = 'user_agent_result';
export const MESSAGE_TYPE_TRACKING_STATUS_RESULT = 'tracking_status_result';
export const MESSAGE_TYPE_FOREGROUND = 'foreground';
export const MESSAGE_TYPE_REGISTER_PUSH_TOKEN = 'register_push_token';
const MESSAGE_TYPE_GO_BACK = 'go_back';
export const MESSAGE_TYPE_SHARE_RESULT = 'share_result';
const MESSAGE_TYPE_DEEP_LINK_INFO = 'deep_link_info';
const MESSAGE_TYPE_REFERRAL_INFO = 'referral_info';
export const MESSAGE_TYPE_OFFERWALL_CLOSED = 'offerwall_closed';
export const MESSAGE_TYPE_OFFERWALL_POINTS = 'offerwall_points';

const REVERSE_MESSAGE_TYPE_MESSAGE_INITIALIZE = 'message_initialize';
export const REVERSE_MESSAGE_TYPE_HAS_PUSH_PERMISSION = 'has_push_permission';
export const REVERSE_MESSAGE_TYPE_REQUEST_PUSH_PERMISSION = 'request_push_permission';
export const REVERSE_MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_ANDROID = 'request_push_permission_android';
export const REVERSE_MESSAGE_TYPE_GET_DEVICE_UID = 'get_device_uid';
export const REVERSE_MESSAGE_TYPE_OPEN_SETTINGS = 'open_settings';
export const REVERSE_MESSAGE_TYPE_OPEN_OFFERWALL_PAGE = 'open_offerwall_page';
export const REVERSE_MESSAGE_TYPE_GET_USER_AGENT = 'get_user_agent';
export const REVERSE_MESSAGE_TYPE_GET_TRACKING_STATUS = 'get_tracking_status';
export const REVERSE_MESSAGE_TYPE_REQUEST_TRACKING_PERMISSION = 'request_tracking_permission';
export const REVERSE_MESSAGE_TYPE_SHARE = 'share';
export const REVERSE_MESSAGE_TYPE_REFRESH = 'refresh';
export const REVERSE_MESSAGE_TYPE_REFRESH_OFFERWALL_POINTS = 'refresh_offerwall_points';
export const REVERSE_MESSAGE_TYPE_REQUEST_STORE_REVIEW = 'request_store_review';

export default function App({Component, pageProps}) {
    const getLayout = Component.getLayout;
    const router = useRouter();

    const callbackDictRef = useRef({});

    const getTrackingStatus = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_GET_TRACKING_STATUS,
            key: callbackId,
        })
    }

    const requestTrackingPermission = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_REQUEST_TRACKING_PERMISSION,
            key: callbackId,
        })
    }

    const touchStartYRef = useRef(0);
    const overflowStartYRef = useRef(null);
    const overflowControllerRef = useRef(null);
    const isRefreshingRef = useRef(false);

    const [currentAppVersion, setCurrentAppVersion] = useState('0.0.0');
    useEffect(() => {
        const appVersion = typeof window !== 'undefined' && isApp() ? (window.RNAppVersion || '0.0.0') : '0.0.0';
        setCurrentAppVersion(appVersion);
    }, []);

    // Implementation for pull to refresh
    useEffect(() => {
        if (!currentAppVersion || semver.lt(currentAppVersion, '1.0.5')) return;
        const handleTouchStart = (evt) => {
            if (isRefreshingRef.current) return;
            const root = document.querySelector('.__bottom_modal__');
            if (!!root && root.style.visibility === 'visible') {
                return;
            }
            if (overflowControllerRef.current) {
                overflowControllerRef.current.style.transition = null;
            }
            touchStartYRef.current = evt.touches[0].pageY;
        };
        const handleTouchMove = (evt) => {
            if (isRefreshingRef.current) return;
            const root = document.querySelector('.__bottom_modal__');
            if (!!root && root.style.visibility === 'visible') {
                return;
            }
            const y = evt.touches[0].pageY;
            if (document.scrollingElement.scrollTop === 0 && y > touchStartYRef.current) {
                if (overflowStartYRef.current === null) {
                    overflowStartYRef.current = y;
                }
                const overflowAmount = Math.min(100, (y - overflowStartYRef.current) / 2.5);
                if (overflowControllerRef.current) {
                    overflowControllerRef.current.style.transform = `translateY(${overflowAmount}px)`
                }
            } else {
                overflowStartYRef.current = null;
                if (overflowControllerRef.current) {
                    overflowControllerRef.current.style.transition = `transform 300ms ease-out`;
                    overflowControllerRef.current.style.transform = `translateY(${-40}px)`;
                }
            }
        };
        const handleTouchEnd = (evt) => {
            if (isRefreshingRef.current) return;
            const root = document.querySelector('.__bottom_modal__');
            if (!!root && root.style.visibility === 'visible') {
                return;
            }
            const y = evt.changedTouches[0].pageY;
            const overflowAmount = Math.min(100, (y - overflowStartYRef.current) / 2.5);

            if (overflowAmount === 100 && overflowStartYRef.current !== null) {
                const nextWrapper = document.querySelector(`.${layoutStyles.rootWrapper}`);
                if (nextWrapper) {
                    nextWrapper.style.filter = 'blur(1px)';
                    nextWrapper.style.backdropFilter = 'blur(1px)';
                }
                if (semver.lt(currentAppVersion, '1.0.6')) {
                    router.reload();
                } else {
                    postRNMessage({
                        type: REVERSE_MESSAGE_TYPE_REFRESH,
                    });
                }
            } else {
                overflowStartYRef.current = null;
                if (overflowControllerRef.current) {
                    overflowControllerRef.current.style.transition = `transform 300ms ease-out`;
                    overflowControllerRef.current.style.transform = `translateY(${-40}px)`;
                }
            }
        }

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd)
        }
    }, [currentAppVersion]);

    const currentRouteKeyRef = useRef();
    const fromRouteRef = useRef(null);

    useEffect(() => {
        currentRouteKeyRef.current = window.history.state.key;
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            completeReward(
                router,
                REWARD_KEY_LOGIN,
                '출석 포인트 {point}P를 받았어요!',
                '포인트를 더 받아보세요'
            );
        }, 10000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const saveScrollPosition = (url, {shallow}) => {
            fromRouteRef.current = router.asPath;
            if (router.asPath.split('?')[0] !== url.split('?')[0]) {
                if (!router.components[router.route]?.Component.restoreScroll) {
                    return;
                }

                sessionStorage.setItem(
                    getScrollKeyForRouteKey(currentRouteKeyRef.current),
                    JSON.stringify({
                        x: window.pageXOffset,
                        y: window.pageYOffset,
                        ts: Date.now(),
                    })
                );

                // sessionKey를 50개 이하로 유지
                const storageKeys = Object.entries(sessionStorage).filter(
                    entry => entry[0].startsWith('__next_scroll_')
                ).sort((a, b) => JSON.parse(a[1]).ts - JSON.parse(b[1]).ts).map(e => e[0])

                if (storageKeys.length > 50) {
                    for (let i = 0; i < storageKeys.length - 50; i++) {
                        sessionStorage.removeItem(storageKeys[i]);
                    }
                }
            }
        };
        const restoreScrollPositionOnBack = (url) => {
            currentRouteKeyRef.current = window.history.state.key;
            window.history.scrollRestoration = 'manual';

            if (fromRouteRef.current) {
                if (fromRouteRef.current.split('?')[0] === url.split('?')[0]) {
                    // 같은 페이지로 이동할 경우 스크롤 복원하지 않음
                    return;
                }
            }

            // 페이지 이동 후 저장되어 있던 위치로 스크롤 복원
            const _scroll = sessionStorage.getItem(getScrollKeyForRouteKey(window.history.state.key));
            if (_scroll) {
                // 스크롤 복원 후 저장된 위치 제거
                const {x, y} = JSON.parse(_scroll);

                if (!router.components[router.route]?.Component?.restoreScroll) {
                    return
                }

                setTimeout(() => {
                    window.scrollTo(x, y);
                    sessionStorage.removeItem(getScrollKeyForRouteKey(window.history.state.key));
                }, 0);
            } else {
                if (router.components[router.route]?.Component?.preventInitialScroll) {
                    return
                }

                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 0);
            }
        };

        router.events.on('routeChangeStart', saveScrollPosition);
        router.events.on('routeChangeComplete', restoreScrollPositionOnBack);
        return () => {
            router.events.off('routeChangeStart', saveScrollPosition);
            router.events.off('routeChangeComplete', restoreScrollPositionOnBack);
        }
    }, [router]);

    const onRNMessage = (messageEvent) => {
        let message = null;
        try {
            message = JSON.parse(messageEvent.data);
        } catch (e) {
            return;
        }
        if (message.type === MESSAGE_TYPE_PUSH_REGISTRATION) {
            const data = message.data;
            window.RNPushToken = data.token;
            window.RNUserAgent = data.userAgent;
            window.RNDeviceUid = data.deviceUid;
        } else if (message.type === MESSAGE_TYPE_PUSH_OPEN) {
            const data = message.data;
            if (data.initialRoute) {
                const hasQueryParams = data.initialRoute.indexOf('?') > -1;
                if (hasQueryParams) {
                    const queryParams = data.initialRoute.split('?')[1];
                    const hmkCid = new URLSearchParams(queryParams).get('hmkcid');
                    registerHmkCid(hmkCid);
                }
                router.push(data.initialRoute);
                // router.push(message.data.initialRoute);
            }
            if (data.pushKey) {
                typeof mixpanel !== 'undefined' && mixpanel.track('PushNotificationClick', {pushKey: data.pushKey});
            }
        } else if (message.type === MESSAGE_TYPE_REDIRECT_LOGIN) {
            redirectToLogin(router, false, undefined, undefined, true);
        } else if (message.type === MESSAGE_TYPE_GO_BACK) {
            router.back();
        } else if (message.type === MESSAGE_TYPE_TRACKING_STATUS_RESULT) {
            const data = message.data;
            const callback = callbackDictRef.current[data.key] || function () {
            };
            const result = data.status;
            callback(result);
            if (callbackDictRef.current[data.key]) {
                delete callbackDictRef.current[data.key];
            }
        } else if (message.type === MESSAGE_TYPE_DEEP_LINK_INFO) {
            const data = message.data;
            if (data.hmkcid) {
                registerHmkCid(data.hmkcid);
            }
            if (data.facebookclickid && isApp()) {
                Cookies.set('_fbc', data.facebookclickid, {
                    expires: 14, sameSite: 'Lax', domain: '.hemekolab.com',
                });
            }
        } else if (message.type === MESSAGE_TYPE_REFERRAL_INFO) {
            const data = message.data;
            setTimeout(() => {
                typeof mixpanel !== 'undefined' && mixpanel.track(
                    'RegisterReferralInfo', data,
                );
            }, 1000);
        }
    }

    useEffect(() => {
        if (isApp()) {
            if (isIOS()) {
                window.addEventListener('message', onRNMessage);
                postRNMessage({
                    type: REVERSE_MESSAGE_TYPE_MESSAGE_INITIALIZE,
                });
                return () => {
                    window.removeEventListener('message', onRNMessage);
                }
            } else {
                document.addEventListener('message', onRNMessage);
                postRNMessage({
                    type: REVERSE_MESSAGE_TYPE_MESSAGE_INITIALIZE,
                });
                return () => {
                    document.removeEventListener('message', onRNMessage);
                }
            }
        }
    }, []);

    useEffect(async () => {
        if (!router.isReady) {
            return;
        }
        if (router.query.r) {
            const query = router.query;
            setGlobalReferralInfo(query.r);
            delete query['r'];
            router.replace({query: query});
        }
    }, [router.isReady]);


    const [canTrackIOS, setCanTrackIOS] = useState(null);

    useEffect(() => {
        if (SERVICE_ENV === 'prod') {
            const trackPixel = () => {
                import('react-facebook-pixel')
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        ReactPixel.init('525419872294244');
                        ReactPixel.pageView();
                        console.log('pendingTasks', window.pendingTasks);
                        if (window.pendingTasks) {
                            for (const task of window.pendingTasks) {
                                console.log('task', task);
                                ReactPixel.track(task[0], task[1]);
                            }
                        }
                        window.pixel = ReactPixel;

                        router.events.on('routeChangeComplete', () => {
                            ReactPixel.pageView();
                        });
                    });
            }
            if (isIOS() && isApp()) {
                if (canTrackIOS) {
                    trackPixel();
                }
            } else {
                trackPixel();
            }
        }
    }, [router.events, canTrackIOS]);

    useEffect(() => {
        getTrackingStatus(status => {
            if (status === 'not-determined') {
                requestTrackingPermission(status => {
                    if (status === 'unavailable' || status === 'authorized') {
                        setCanTrackIOS(true);
                    }
                })
            } else if (status === 'unavailable' || status === 'authorized') {
                setCanTrackIOS(true);
            }
        })
    }, []);

    useEffect(() => {
        const storage = globalThis?.sessionStorage;
        if (!storage) return;
        // Set the previous path as the value of the current path.
        const prevPath = storage.getItem("currentPath");
        if (window.location.pathname === prevPath) {
            return;
        }
        storage.setItem("prevPath", prevPath);
        // Set the current path value by looking at the browser's location object.
        storage.setItem("currentPath", window.location.pathname);
    }, [router.asPath]);

    useEffect(() => {
        try {
            if (typeof window === 'undefined') return;
            const eventName = `PageView${window.location.pathname}`;
            const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
            typeof mixpanel !== 'undefined' && mixpanel.track(eventName, queryParams);
        } catch (e) {
            captureException(e);
        }
    }, [router.asPath]);

    useEffect(() => {
        typeof mixpanel !== 'undefined' && mixpanel.register({
            'is_app': isApp(),
        });
    }, []);


    useEffect(() => {
        if (typeof window === 'undefined') return;
        const hmkCid = new URLSearchParams(window.location.search).get('hmkcid');

        if (hmkCid) {
            registerHmkCid(hmkCid);
        } else {
            const hmkCidInfo = getHmkCidInfo();
            console.log('hmkCidInfo', hmkCidInfo);
            typeof mixpanel !== 'undefined' && mixpanel.register(hmkCidInfo);
        }
        recordBrowseInfo();
    }, [router.asPath]);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        const searchParams = new URLSearchParams(window.location.search);
        const fbClickId = searchParams.get('facebookclickid');
        const siteUrl = `.${window.location.host.split('.').slice(-2).join('.')}`;
        console.log('siteUrl', siteUrl);

        const hmkpid = searchParams.get('hmkpid');
        if (hmkpid) {
            setHmkpid(hmkpid);
        }

        if (fbClickId) {
            Cookies.set('_fbc', fbClickId, {
                expires: 14, sameSite: 'Lax', domain: siteUrl,
            });
        }
        for (let param of searchParams) {
            const [key, value] = param;
            if (key !== 'gainfo') {
                continue;
            }
            const cookieKeyValues = value.split(';');
            for (let cookieKeyValue of cookieKeyValues) {
                if (cookieKeyValue === '') {
                    continue;
                }
                const [cookieKey, cookieValue] = cookieKeyValue.split('=');
                console.log(
                    'cookie', cookieKey, cookieValue,
                )
                Cookies.set(cookieKey, cookieValue, {
                    expires: 365, domain: siteUrl,
                });
            }
        }
    }, [router.pathname]);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        const directToApp = new URLSearchParams(window.location.search).get('directToApp');
        if (directToApp === 'true' && !isApp()) {
            redirectToApp();
        }
    }, [router.pathname]);

    useEffect(() => {
        router.events.on('routeChangeStart', (b) => {
            isRefreshed = false;
        });
    }, []);

    useEffect(() => {
        const isFromAdmin = new URLSearchParams(window.location.search).get('is_from_admin');
        if (isFromAdmin === 'true') {
            localStorage.setItem('is_from_admin', 'true');
        }
    }, []);

    const path = router.pathname;
    const highFloatingSection = (
        path === '/store/pouch' ||
        path === '/store/orders/[ordNum]/checkout' ||
        path === '/store/orders/[ordNum]/success'
    );

    const mixpanelToken = SERVICE_ENV === 'prod'
        ? 'da8becfb75c5a066c32bf898dfc0ce8a'
        : 'ba70e4b12d6bb8ae4bd67670315ea9aa';

    const mixpanelDebugMode = SERVICE_ENV !== 'prod' ? 'true' : 'false';

    return (
        <>
            <Head>
                <meta name="facebook-domain-verification" content="202yt1c354krjsqucc6okrmrjpby4e"/>
                <meta name="naver-site-verification" content="94a4a4578e807156f6dae4eb090050be6151df62"/>
                <meta name="google-site-verification" content="dbrDyQVnXCDwb3W7fVSXyULEmU20GoDylVFfrLt42cg"/>
                <meta name="description" content="매일매일 새로운 뷰티 정보! 헤메코랩에서 아티스트의 화장품 정보를 만나보세요" key="description"/>
                <meta name="viewport"
                      content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,viewport-fit=cover"/>

                <title>헤메코랩 - 하나만 사도 어디로든 무료배송</title>
                <meta property="og:type" content="website" key="ogType"/>
                <meta property="og:title" content="헤메코랩 - 하나만 사도 어디로든 무료배송" key="ogTitle"/>
                <meta property="og:site_name" content="HEMEKO LAB" key="ogSiteName"/>
                <meta property="og:description" content="매일매일 새로운 뷰티 정보! 헤메코랩에서 아티스트의 화장품 정보를 만나보세요"
                      key="ogDescription"/>
                <meta property="og:image" content="https://d1cnx04b8cgzcv.cloudfront.net/static/hemeko_logo.png"
                      key="ogImage"/>
                <meta property="og:url" content="https://www.hemekolab.com" key="ogUrl"/>

                <link rel="icon" href="/favicon.ico" type="image/x-icon"/>
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon"/>
            </Head>
            {
                (typeof window != 'undefined' && !window.mixpanel && <Script
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                  (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init('${mixpanelToken}', {debug: ${mixpanelDebugMode}}); 
mixpanel.track('Sign up');
      `,
                    }}
                />)
            }
            {
                SERVICE_ENV === 'prod' &&
                <>
                    <Script
                        src="https://www.googletagmanager.com/gtag/js?id=G-25MVGS6QX2"
                        strategy="afterInteractive"
                    />
                    <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
(function(w, d, a){
    w.__beusablerumclient__ = {
        load : function(src){
            var b = d.createElement("script");
            b.src = src; b.async=true; b.type = "text/javascript";
            d.getElementsByTagName("head")[0].appendChild(b);
        }
    };w.__beusablerumclient__.load(a + "?url=" + encodeURIComponent(d.URL));
})(window, document, "//rum.beusable.net/load/b221017e232514u173");
`
                        }}
                    />
                    <Script id="google-analytics" strategy="afterInteractive">
                        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    
    gtag('config', 'G-25MVGS6QX2');
    `
                        }
                    </Script>
                    <Script
                        src="https://wcs.naver.net/wcslog.js"
                        strategy="afterInteractive"
                    />
                    <Script
                        src="https://karrot-pixel.business.daangn.com/0.2/karrot-pixel.umd.js"
                        strategy="afterInteractive"
                    />
                </>
            }
            {
                (currentAppVersion && semver.gte(currentAppVersion, '1.0.5')) &&
                <div className={styles.refreshControllerContainer}>
                    <div className={styles.refreshControllerWrapper} ref={overflowControllerRef}>
                        <div className={styles.refreshController}>
                            <LoadingDot/>
                        </div>
                    </div>
                </div>
            }
            <ToastContainer
                enableMultiContainer
                // containerId={'default'}
                position="bottom-center"
                autoClose={1500}
                hideProgressBar
                newestOnTop={true}
                transition={Slide}
                closeOnClick
                limit={1}
                icon={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                closeButton={<CloseIcon viewBox="0 0 20 20" width={20} height={20} className={styles.toastCloseIcon}/>}
                pauseOnHover={true}
            />
            <ToastContainer
                enableMultiContainer
                containerId={'new'}
                className={'newToast'}
                toastClassName={'newToastToast'}
                bodyClassName={'newToastBody'}
                progressClassName={'newToastProgress'}
                position="bottom-center"
                autoClose={1500}
                hideProgressBar
                newestOnTop={true}
                transition={Slide}
                // closeOnClick
                limit={1}
                icon={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                closeButton={<div></div>}
                pauseOnHover={true}
            />
            <SellerProductContextProvider>
                <ReviewScrapContextProvider>
                    <ProductScrapContextProvider>
                        <PostScrapContextProvider>
                            <UserContextProvider isPageProtected={pageProps.protected}>
                                <ABTestContextProvider>
                                    <CategoryContextProvider>
                                        <PostListContextProvider>
                                            <PouchContextProvider>
                                                <ScrollContextProvider>
                                                    <PushContextProvider>
                                                        <ModalContextProvider>
                                                            <ToastContextProvider>
                                                                <MediaContextProvider>
                                                                    <KeepAliveProvider router={router}>
                                                                        {
                                                                            !!getLayout ?
                                                                                getLayout(
                                                                                    <Component {...pageProps}
                                                                                               key={router.asPath.split('?')[0]}/>
                                                                                )
                                                                                :
                                                                                <Component {...pageProps}
                                                                                           key={router.asPath.split('?')[0]}/>
                                                                        }
                                                                    </KeepAliveProvider>
                                                                    <div className={styles.floatingContainer}>
                                                                        <div className={styles.floatingWrapper}>
                                                                            <div
                                                                                className={classNames(styles.floatingSection, highFloatingSection ? styles.highFloatingSection : undefined)}>
                                                                                <AddPostButton/>
                                                                                <ScrollTopButton/>
                                                                                {/*<FeedbackButton />*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </MediaContextProvider>
                                                            </ToastContextProvider>
                                                        </ModalContextProvider>
                                                    </PushContextProvider>
                                                </ScrollContextProvider>
                                            </PouchContextProvider>
                                        </PostListContextProvider>
                                    </CategoryContextProvider>
                                </ABTestContextProvider>
                            </UserContextProvider>
                        </PostScrapContextProvider>
                    </ProductScrapContextProvider>
                </ReviewScrapContextProvider>
            </SellerProductContextProvider>
            <span itemScope="" itemType="http://schema.org/Organization">
                <link itemProp="url" href="https://www.hemekolab.com"/>
                <a itemProp="sameAs" href="https://www.youtube.com/channel/UC9SxIMTbO5pf97hCV0Ta1PQ"></a>
                <a itemProp="sameAs" href="https://www.instagram.com/hemeko.o/"></a>
                <a itemProp="sameAs" href="https://www.facebook.com/hemeko.o/"></a>
            </span>
            {
                SERVICE_ENV === 'prod' &&
                <>
                    <ScriptRunner
                        conditionalProperty="karrotPixel"
                        script={`
if (window.karrotPixel) {
    karrotPixel.init('1723109516073400001');
    try {
        karrotPixel.track('ViewPage');
    } catch {}
}
`
                        }
                    />
                <ScriptRunner
                    conditionalProperty="wcs"
                    script={`
if(!wcs_add) var wcs_add = {};
wcs_add["wa"] = "s_53d333521e94";
if (!_nasa) var _nasa={};
if (window.wcs) {
    wcs.inflow("hemekolab.com");
    wcs_do(_nasa);
}
`
                    }
                />
                </>
            }
        </>
    )
}

if (SERVICE_ENV === 'prod') {
    console.log = () => {
    };
}
