import utilStyles from "styles/utils.module.scss";
import styles from "components/cards/order/orderCountBriefCard.module.scss";

import {useRouter} from "next/router";
import classNames from "classnames";
import {
    STATUS_CLAIMED,
    STATUS_CONFIRM_PURCHASE,
    STATUS_DELIVERING,
    STATUS_DELIVERY_DONE,
    STATUS_PAYMENT_DONE,
    STATUS_PAYMENT_WAIT,
    STATUS_PREPARING
} from "pages/mypage/orders";
import {ordersRoute} from "common/const";


export default function OrderCountBriefCard (props) {
    const {routeWithPathname, noTopPadding} = props;
    const orderHistoryCountDict = props.orderHistoryCountDict || {};
    const statusToCount = orderHistoryCountDict.order_status_to_count || {};
    const router = useRouter();

    const onCountLabelClick = (targetStatus) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderCountBriefCardCountLabelClick',
            { targetStatus }
        );
        if (routeWithPathname) {
            router.push(
                {
                    pathname: ordersRoute,
                    query: {status: targetStatus}
                }
            )
        }
        else {
            router.replace(
                {
                    query: Object.assign({}, router.query,  {status: router.query.status === targetStatus ? '' : targetStatus})
                }
            )
        }
    }

    //
    // return (
    //     <div className={styles.container}>
    //         <div className={styles.countContainer}>
    //             <div className={styles.orderCountLabelWrapper} onClick={
    //                 () => router.replace({query: Object.assign({}, router.query, {status: STATUS_PAYMENT_WAIT})})
    //             }>
    //                 <div className={styles.orderCountUnit}>
    //                     <div className={utilStyles.fullFlex} />
    //                     <div className={
    //                         classNames(
    //                             styles.circleContainer,
    //                             router.query.status === STATUS_PAYMENT_WAIT ? styles.highlightedCircle : undefined
    //                         )
    //                     }>
    //                         0
    //                     </div>
    //                     <div className={styles.link} />
    //                 </div>
    //                 <span className={styles.label}>입금대기중</span>
    //             </div>
    //             <div className={styles.orderCountLabelWrapper} onClick={
    //                 () => router.replace({query: Object.assign({}, router.query,  {status: STATUS_PAYMENT_DONE})})
    //             }>
    //                 <div className={styles.orderCountUnit}>
    //                     <div className={styles.link} />
    //                     <div className={
    //                         classNames(
    //                             styles.circleContainer,
    //                             router.query.status === STATUS_PAYMENT_DONE ? styles.highlightedCircle : undefined
    //                         )
    //                     }>
    //                         0
    //                     </div>
    //                     <div className={styles.link} />
    //                 </div>
    //                 <span className={styles.label}>결제완료</span>
    //             </div>
    //             <div className={styles.orderCountLabelWrapper} onClick={
    //                 () => router.replace({query: Object.assign({}, router.query,  {status: STATUS_PREPARING})})
    //             }>
    //                 <div className={styles.orderCountUnit}>
    //                     <div className={styles.link} />
    //                     <div className={
    //                         classNames(
    //                             styles.circleContainer,
    //                             router.query.status === STATUS_PREPARING ? styles.highlightedCircle : undefined
    //                         )
    //                     }>
    //                         0
    //                     </div>
    //                     <div className={styles.link} />
    //                 </div>
    //                 <span className={styles.label}>배송준비중</span>
    //             </div>
    //             <div className={styles.orderCountLabelWrapper} onClick={
    //                 () => router.replace({query: Object.assign({}, router.query,  {status: STATUS_DELIVERING})})
    //             }>
    //                 <div className={styles.orderCountUnit}>
    //                     <div className={styles.link} />
    //                     <div className={
    //                         classNames(
    //                             styles.circleContainer,
    //                             router.query.status === STATUS_DELIVERING ? styles.highlightedCircle : undefined
    //                         )
    //                     }>
    //                         0
    //                     </div>
    //                     <div className={styles.link} />
    //                 </div>
    //                 <span className={styles.label}>배송중</span>
    //             </div>
    //             <div className={styles.orderCountLabelWrapper} onClick={
    //                 () => router.replace({query: Object.assign({}, router.query,  {status: STATUS_DELIVERY_DONE})})
    //             }>
    //                 <div className={styles.orderCountUnit}>
    //                     <div className={styles.link} />
    //                     <div className={
    //                         classNames(
    //                             styles.circleContainer,
    //                             router.query.status === STATUS_DELIVERY_DONE ? styles.highlightedCircle : undefined
    //                         )
    //                     }>
    //                         0
    //                     </div>
    //                     <div className={utilStyles.fullFlex} />
    //                 </div>
    //                 <span className={styles.label}>배송완료</span>
    //             </div>
    //         </div>
    //         <div className={styles.otherBox}>
    //             <div className={styles.additionalStateCount}>
    //                 <span className={styles.additionalState}>취소/교환/반품</span>
    //                 <span className={styles.additionalCount}>0</span>
    //             </div>
    //             <div className={styles.verticalBorder} />
    //             <div className={styles.additionalStateCount}>
    //                 <span className={styles.additionalState}>구매확정</span>
    //                 <span className={styles.additionalCount}>0</span>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className={styles.container} style={{paddingTop: noTopPadding ? 0 : undefined}}>
            <div className={styles.countContainer}>
                <div className={styles.orderCountLabelWrapper} onClick={
                    () => onCountLabelClick(STATUS_PAYMENT_WAIT)
                }>
                    <div className={styles.orderCountUnit}>
                        <div className={utilStyles.fullFlex} />
                        <div className={
                            classNames(
                                styles.circleContainer,
                                router.query.status === STATUS_PAYMENT_WAIT ? styles.highlightedCircle : undefined
                            )
                        }>
                            {statusToCount[STATUS_PAYMENT_WAIT] || 0}
                        </div>
                        <div className={styles.link} />
                    </div>
                    <span className={styles.label}>입금대기중</span>
                </div>
                <div className={styles.link} />
                <div className={styles.orderCountLabelWrapper} onClick={
                    () => onCountLabelClick(STATUS_PAYMENT_DONE)
                }>
                    <div className={styles.orderCountUnit}>
                        <div className={styles.link} />
                        <div className={
                            classNames(
                                styles.circleContainer,
                                router.query.status === STATUS_PAYMENT_DONE ? styles.highlightedCircle : undefined
                            )
                        }>
                            {statusToCount[STATUS_PAYMENT_DONE] || 0}
                        </div>
                        <div className={styles.link} />
                    </div>
                    <span className={styles.label}>결제완료</span>
                </div>
                <div className={styles.link} />
                <div className={styles.orderCountLabelWrapper} onClick={
                    () => onCountLabelClick(STATUS_PREPARING)
                }>
                    <div className={styles.orderCountUnit}>
                        <div className={styles.link} />
                        <div className={
                            classNames(
                                styles.circleContainer,
                                router.query.status === STATUS_PREPARING ? styles.highlightedCircle : undefined
                            )
                        }>
                            {statusToCount[STATUS_PREPARING] || 0}
                        </div>
                        <div className={styles.link} />
                    </div>
                    <span className={styles.label}>배송준비중</span>
                </div>
                <div className={styles.link} />
                <div className={styles.orderCountLabelWrapper} onClick={
                    () => onCountLabelClick(STATUS_DELIVERING)
                }>
                    <div className={styles.orderCountUnit}>
                        <div className={styles.link} />
                        <div className={
                            classNames(
                                styles.circleContainer,
                                router.query.status === STATUS_DELIVERING ? styles.highlightedCircle : undefined
                            )
                        }>
                            {statusToCount[STATUS_DELIVERING] || 0}
                        </div>
                        <div className={styles.link} />
                    </div>
                    <span className={styles.label}>배송중</span>
                </div>
                <div className={styles.link} />
                <div className={styles.orderCountLabelWrapper} onClick={
                    () => onCountLabelClick(STATUS_DELIVERY_DONE)
                }>
                    <div className={styles.orderCountUnit}>
                        <div className={styles.link} />
                        <div className={
                            classNames(
                                styles.circleContainer,
                                router.query.status === STATUS_DELIVERY_DONE ? styles.highlightedCircle : undefined
                            )
                        }>
                            {statusToCount[STATUS_DELIVERY_DONE] || 0}
                        </div>
                        <div className={utilStyles.fullFlex} />
                    </div>
                    <span className={styles.label}>배송완료</span>
                </div>
            </div>
            <div className={styles.otherBox}>
                <div className={styles.additionalStateCount} onClick={
                    () => onCountLabelClick(STATUS_CLAIMED)
                }>
                    <span className={styles.additionalState}>취소/교환/반품</span>
                    <span className={classNames(styles.additionalCount, router.query.status === STATUS_CLAIMED ? utilStyles.brandColorText : undefined)}>{orderHistoryCountDict.claim_count || 0}</span>
                </div>
                <div className={styles.verticalBorder} />
                <div className={styles.additionalStateCount} onClick={
                    () => onCountLabelClick(STATUS_CONFIRM_PURCHASE)
                }>
                    <span className={styles.additionalState}>구매확정</span>
                    <span className={classNames(styles.additionalCount, router.query.status === STATUS_CONFIRM_PURCHASE ? utilStyles.brandColorText : undefined)}>{statusToCount[STATUS_CONFIRM_PURCHASE] || 0}</span>
                </div>
            </div>
        </div>
    );
}
