import NavigationLayout from "components/layout/NavigationLayout";
import styles from "pages/mypage/faq.module.scss";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import myPageStyles from "pages/mypage/index.module.scss";
import {MyInfoCard} from "components/cards/MyInfoCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useRouter} from "next/router";
import {chunkedArray} from "common/utils";
import {faq} from "docs/faq";
import classNames from "classnames";
import {useContext, useState} from "react";
import FAQCard from "components/cards/FAQCard";
import Paginator from "components/Paginator";
import UserContext from "context/AuthContext";


export const TabSelector = (props) => {
    const {colSize, options, value, isActiveTabBold, noRadius, fontSize, isActiveTabDark, noHighlightActiveTab, customCell, lightBorder, noSideBorder} = props;
    const onChange = props.onChange || function () {
    };

    // const countPerRow = Math.ceil(options.length / lineCount);
    const nestedList = chunkedArray(options, colSize);

    return (
        <div className={styles.tabSelectorContainer} style={{borderRadius: noRadius ? 0 : undefined}}>
            {
                nestedList.map((list, i) => {
                    return (
                        <div key={i} className={styles.rowContainer}>
                            {
                                list.map((el, idx) => {
                                    const cl = [];
                                    if (!noRadius) {
                                        if (i === 0 && idx === 0) {
                                            cl.push(styles.elementTopLeft);
                                        }
                                        if (i === 0 && idx === list.length - 1) {
                                            cl.push(styles.elementTopRight);
                                        }
                                        if (i === nestedList.length - 1 && idx === 0) {
                                            cl.push(styles.elementBottomLeft);
                                        }
                                        if (i === nestedList.length - 1 && idx === list.length - 1) {
                                            cl.push(styles.elementBottomRight);
                                        }
                                    }
                                    if (noSideBorder && idx === 0) {
                                        cl.push(styles.noLeftBorder);
                                    }
                                    if (!noSideBorder && idx === list.length - 1) {
                                        cl.push(styles.borderRight);
                                    }
                                    if (i === nestedList.length - 1) {
                                        cl.push(styles.borderBottom);
                                    }
                                    const key = el ? Object.keys(el)[0] : null;
                                    const name = el ? Object.values(el)[0] : '';
                                    const isActive = key === value;
                                    return (
                                        customCell
                                            ? customCell(el, classNames(styles.element, ...cl,
                                                isActive && !noHighlightActiveTab ? styles.activeContent : undefined,
                                                isActiveTabDark && (isActive ? styles.darkActiveContent : styles.grayInactiveContent),
                                                lightBorder && styles.lightBorder,
                                            ), key || `empty_${idx}`, i, idx)
                                            :
                                            <div
                                                className={classNames(styles.element, ...cl)}
                                                key={key || `empty_${idx}`}
                                                onClick={() => {
                                                    onChange(key);
                                                }}
                                            >
                                                <div
                                                    className={
                                                        classNames(
                                                            styles.content,
                                                            isActive && !noHighlightActiveTab ? styles.activeContent : undefined,
                                                            isActiveTabDark && (isActive ? styles.darkActiveContent : styles.grayInactiveContent)
                                                        )
                                                    }
                                                    style={{
                                                        fontWeight: isActive && isActiveTabBold ? 'bold' : undefined,
                                                        fontSize: fontSize
                                                    }}>
                                                    {customCell ? customCell(el) : name}
                                                </div>
                                            </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

const FAQ = () => {
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const [categoryKey, setCategoryKey] = useState(Object.keys(faq)[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeQuestions, setActiveQuestions] = useState(new Set());
    const countPerPage = 10;

    const router = useRouter();
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const onTabChange = (tab) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageFaqTabChange', {tab: tab});
        setCategoryKey(tab);
        setCurrentPage(1);
    };

    const onCardClick = (key) => {
        const shouldClose = activeQuestions.has(key);
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageFaqCardClick', {key: key, shouldClose});
        console.log(`activeQuestions: ${activeQuestions.size}`)
        if (shouldClose) {
            activeQuestions.delete(key);
        } else {
            activeQuestions.add(key);
        }
        setActiveQuestions(new Set(activeQuestions));
    };

    return (
        <div className={utilStyles.pageContainer}>
            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                <TitleBar title="FAQ" isBack close={() => router.back()}/>
            </div>
            <div className={styles.container}>
                {
                    !isMobile && user &&
                    <>
                        <MyInfoCard/>
                        <div style={{height: isLarge ? 60 : 40}}/>
                    </>
                }
                <div className={styles.selectorWrapper}>
                    <TabSelector value={categoryKey} onChange={onTabChange}
                         options={Object.keys(faq).map(e => {
                            return {[e]: faq[e].name}
                         })} colSize={isLarge ? 6 : 3} />
                </div>
            </div>
            <div className={styles.faqContainer}>
                <div className={styles.title}>{faq[categoryKey].name}</div>
                <div className={styles.listRoot}>
                    {
                        faq[categoryKey].qaList.slice(
                            (currentPage - 1) * countPerPage, currentPage * countPerPage
                        ).map(({question, answer}, i) => {
                            const key = `${categoryKey}${currentPage}${i}`;
                            return <FAQCard
                                key={key} question={question}
                                answer={answer} isOpen={activeQuestions.has(key)}
                                onClick={() => onCardClick(key)}/>
                        })
                    }
                </div>
            </div>
            <div className={styles.paginatorMargin}/>
            <Paginator totalCount={faq[categoryKey].qaList.length} size={isLarge ? 10 : 5} countPerPage={10}
                       currentPage={currentPage} onChangeCurrentPage={(e) => setCurrentPage(e)}/>
        </div>
    )
};

FAQ.getLayout = NavigationLayout;
// export async function getStaticProps(context) {
//     return {
//         props: {
//             protected: true,
//         },
//     }
// }
export default FAQ;
