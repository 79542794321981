import styles from "components/orderStatusInfo.module.scss";
import utilStyles from 'styles/utils.module.scss';


import ArrowRightIcon from "images/icons/arrow_right_sharp.svg";
import AlertIcon from "images/icons/alert.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";


export default function OrderStatusInfo () {
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isDesktop = useMediaQuery(`(max-width:${utilStyles.breakpointDesktop})`);

    return (
        <div>
            <div className={styles.titleWrapper}>
                <AlertIcon className={styles.verticalMiddle} />
                <span className={styles.title}>주문/배송조회 안내</span>
            </div>
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>01.</span>
                        <span className={styles.state}>주문접수</span>
                    </div>
                    <span className={styles.content}>
                        주문 후 입금확인 중 {!isDesktop ? <br /> : undefined}(주문취소 가능)
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>02.</span>
                        <span className={styles.state}>결제완료</span>
                    </div>
                    <span className={styles.content}>
                        결제 및 입금완료 {!isDesktop ? <br /> : undefined}(주문취소 가능)
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>03.</span>
                        <span className={styles.state}>제품준비중</span>
                    </div>
                    <span className={styles.content}>
                        배송을 위한 제품 {!isDesktop ? <br /> : undefined}준비중 (주문취소 불가)
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>04.</span>
                        <span className={styles.state}>배송중</span>
                    </div>
                    <span className={styles.content}>
                        배송 시작 및 배송중 {!isDesktop ? <br /> : undefined}&nbsp;
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>05.</span>
                        <span className={styles.state}>배송완료</span>
                    </div>
                    <span className={styles.content}>
                        배송 완료 및 구매 확정 가능 (7일 이후 자동 구매확정)
                    </span>
                </div>
            </div>
        </div>
    )
}
