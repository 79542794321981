import {Axios} from "api/index";
import {getHmkCidInfo} from "common/hmkcid";


export const order = async (data) => {
    const hmkCidInfo = getHmkCidInfo();
    const extendedData = {
        ...data,
        metadata: hmkCidInfo,
    };

    return Axios.post('v1/store/orders/', extendedData);
};
