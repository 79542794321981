import NavigationLayout from "components/layout/NavigationLayout";
import ArrowRightIcon from "images/icons/arrow_right_757575_16.svg";

import styles from "pages/mypage/myInfo.module.scss";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import myPageStyles from "pages/mypage/index.module.scss";
import {MyInfoCard} from "components/cards/MyInfoCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useRouter} from "next/router";
import {useCallback, useContext, useEffect, useState} from "react";
import UserContext from "context/AuthContext";
import {Axios} from "api";
import {
    AUTH_TYPE_APPLE,
    AUTH_TYPE_EMAIL,
    AUTH_TYPE_KAKAO,
    AUTH_TYPE_NAVER, certCheckRoute, IAMPORT_STORE_ID,
    marketingAgreementRoute,
    signUpCompleteRoute, SITE_URL,
    snsSignUpRoute, storeRoute, withdrawRoute
} from "common/const";
import Loading from "components/Loading";

import NaverLogo from "images/icons/naver.svg";
import KakaoLogo from "images/icons/kakao.svg";
import AppleLogo from "images/icons/apple_circle.svg";
import GoogleLogo from "images/icons/google.svg";
import FullButton from "components/buttons/FullButton";
import {useFormik} from "formik";
import * as yup from "yup";
import {
    KEY_NICKNAME,
    KEY_NICKNAME_CONTAINS_ALPHABET,
    KEY_NICKNAME_EXISTS,
    KEY_NICKNAME_LEGAL,
    KEY_PASSWORD,
    KEY_PASSWORD_CONFIRM,
    KEY_PASSWORD_CONTAINS_ALPHABET,
    KEY_PASSWORD_CONTAINS_NUMBER,
    KEY_PASSWORD_CONTAINS_SPECIAL,
    NICKNAME_ERROR_FIELDS,
    PASSWORD_ERROR_FIELDS,
    REGEX_CONTAINS_ALPHABET,
    REGEX_CONTAINS_DIGIT,
    REGEX_CONTAINS_SPECIAL,
    REGEX_NICKNAME_LEGAL
} from "pages/auth/sign-up";
import TextInput from "components/input/TextInput";
import CheckedInputError from "components/input/CheckedInputError";
import classNames from "classnames";
import {
    KEY_AGE,
    KEY_MARKETING_AGREED,
    KEY_PRIVACY_POLICY,
    KEY_TERMS_OF_SERVICE
} from "components/input/SignUpAgreement";
import IamportLayout from "components/layout/IamportLayout";
import axios from "axios";
import BulletText from "components/BulletText";
import {confirm, customConfirm, dashedPhoneNumber, isApp, onFileSelect} from "common/utils";
import CheckboxText from "components/input/CheckboxText";
import Link from "next/link";
import IamportScript from "components/IamportScript";
import {captureException, captureMessage} from "@sentry/nextjs";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import Image from "next/image";
import PushContext from "context/PushContext";
import {v4} from "uuid";


const KEY_OLD_PASSWORD = 'old_password';
export const PasswordEditor = (props) => {
    const {isEditModeEnabled, isOldPasswordRequired, optionalData, hideLabel, noMargin} = props;
    const onSuccess = props.onSuccess || function () {};
    const [isEditMode, setIsEditMode] = useState(!isEditModeEnabled);
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [KEY_PASSWORD]: '',
            [KEY_PASSWORD_CONFIRM]: '',
            [KEY_OLD_PASSWORD]: '',
        },
        validationSchema: yup.object({
            [KEY_OLD_PASSWORD]: isOldPasswordRequired ? yup.string().required('필수입력') : undefined,
            [KEY_PASSWORD]: yup.string().required().min(8).max(16),
            [KEY_PASSWORD_CONFIRM]: yup.string().when(KEY_PASSWORD, (password, schema) => {
                return schema.oneOf([password]);
            }),
            [KEY_PASSWORD_CONTAINS_ALPHABET]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password) return schema.required();
                if (!password.match(REGEX_CONTAINS_ALPHABET)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_PASSWORD_CONTAINS_NUMBER]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password) return schema.required();
                if (!password.match(REGEX_CONTAINS_DIGIT)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_PASSWORD_CONTAINS_SPECIAL]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password) return schema.required();
                if (!password.match(REGEX_CONTAINS_SPECIAL)) {
                    return schema.oneOf([true]).required();
                }
            }),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const data = Object.assign(JSON.parse(JSON.stringify(values)), optionalData);
                delete data[KEY_PASSWORD_CONFIRM];
                const res = await Axios.post('v1/auth/change-password/', data);
                if (res.status < 400) {
                    onSuccess();
                    if (isEditModeEnabled) setIsEditMode(false);
                    resetForm();
                } else if (res.status === 403) {
                    setFieldError(KEY_OLD_PASSWORD, '비밀번호 확인');
                } else {
                    if (res.data.display_message) {
                        alert(res.data.display_message);
                    } else {
                        captureMessage(JSON.stringify(res.data));
                        alert('비밀번호 변경에 실패했습니다. 잠시 후 다시 시도해주세요.');
                    }
                }
            } catch (e) {
                captureException(e);
                alert('비밀번호 변경에 실패했습니다. 잠시 후 다시 시도해주세요.');
            } finally {
                setIsLoading(false);
            }
        },
    });

    const {errors, values, resetForm, setFieldValue, touched, setFieldTouched, handleSubmit, setTouched, setFieldError} = formik;

    const onSubmit = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoPasswordEditorSubmitClick',
        );
        handleSubmit();
    };

    const onCancel = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoPasswordEditorCancelClick',
        );
        if (isEditModeEnabled) setIsEditMode(false);
        resetForm();
    };

    const onChangeClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoPasswordEditorChangeClick',
        );
        setIsEditMode(true);
    };

    return (
        <div className={classNames(styles.editorContainer, isEditMode ? styles.editorContainerActive : undefined)} style={{marginTop: noMargin ? 0 : undefined}}>
            <div>
                {
                    !hideLabel &&
                    <span className={styles.sectionSubtitle}>비밀번호</span>
                }
                {
                    !isEditMode && <span className={styles.content} style={{fontSize: 13}}>●●●●●●●●●●</span>
                }
            </div>
            {
                !isEditMode ?
                    <div className={styles.editorButtonWrapper}>
                        <FullButton white height={36} title="변경" fontSize={15} nonBold onClick={onChangeClick} />
                    </div>
                    :
                    <div style={{marginTop: 4, width: '100%'}}>
                        {
                            isOldPasswordRequired &&
                            <>
                                <TextInput onEnter={handleSubmit} readonly={isLoading} error={touched[KEY_OLD_PASSWORD] && errors[KEY_OLD_PASSWORD]} onBlur={() => setFieldTouched(KEY_OLD_PASSWORD)} value={values[KEY_OLD_PASSWORD]} onChange={e => setFieldValue(KEY_OLD_PASSWORD, e)} type="password" placeholder="사용중인 비밀번호를 입력해주세요" maxLength={16} />
                                {
                                    (!!errors[KEY_OLD_PASSWORD] && touched[KEY_OLD_PASSWORD]) &&
                                    <div style={{marginTop: 6}}>
                                        <CheckedInputError isValid={false} message={errors[KEY_OLD_PASSWORD]} />
                                    </div>
                                }
                                <div style={{height: 12}}/>
                            </>

                        }
                        <TextInput onEnter={handleSubmit} readonly={isLoading} error={touched[KEY_PASSWORD] && PASSWORD_ERROR_FIELDS.filter(field => errors[field]).length > 0} onBlur={() => setFieldTouched(KEY_PASSWORD)} value={values[KEY_PASSWORD]} onChange={e => setFieldValue(KEY_PASSWORD, e)} type="password" placeholder="새 비밀번호를 입력해주세요" maxLength={16} />
                        {
                            (!!values[KEY_PASSWORD] || touched[KEY_PASSWORD]) &&
                            <div style={{marginTop: 6}}>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_ALPHABET]} message="영문포함" />
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_NUMBER]} message="숫자포함" />
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_SPECIAL]} message="특수문자포함" />
                                <CheckedInputError isValid={!errors[KEY_PASSWORD]} message="8-16 자리" />
                            </div>
                        }
                        <div style={{height: 12}}/>
                        <TextInput onEnter={onSubmit} readonly={isLoading} error={touched[KEY_PASSWORD_CONFIRM] && !!errors[KEY_PASSWORD_CONFIRM]}
                                   onBlur={() => setFieldTouched(KEY_PASSWORD_CONFIRM)} value={values[KEY_PASSWORD_CONFIRM]} onChange={e => setFieldValue(KEY_PASSWORD_CONFIRM, e)}
                                   type="password" placeholder="새 비밀번호를 다시 입력해주세요" maxLength={16} />
                        {
                            !!values[KEY_PASSWORD_CONFIRM] &&
                            <div style={{marginTop: 6}}>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONFIRM]} message="비밀번호 일치"/>
                            </div>
                        }
                        <div style={{height: 24}}/>
                        <div className={styles.buttonsContainer}>
                            {
                                isEditModeEnabled &&
                                <div className={styles.submitButtonWrapper}>
                                    <FullButton disabled={isLoading} height={48} fontSize={16} light title="변경 취소" onClick={onCancel} />
                                </div>
                            }
                            <div className={styles.submitButtonWrapper}>
                                <FullButton disabled={isLoading} height={48} fontSize={16} title={isEditModeEnabled ? "저장" : "확인"}
                                            onClick={onSubmit} />
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
};

export const ProfileImageEditor = (props) => {
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const {horizontal} = props;
    const onChange = props.onChange || function () {};
    const [isLoading, setIsLoading] = useState(false);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    const uploadProfileImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            setIsLoading(true);
            const res = await Axios.post('v1/user/profile-image/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (res.status < 400) {
                userContext.setUser(res.data);
                onChange(res.data.profile_image_url);
            } else {
                alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const onDeleteProfileImage = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoDeleteProfileImageClick'
        );
        customConfirm(
            '프로필 사진을 삭제하시겠어요?',
            '삭제하기',
            '취소',
            () => {
                typeof mixpanel !== 'undefined' && mixpanel.track(
                    'MyPageMyInfoDeleteProfileConfirmClick'
                );
                onDeleteProfileImageConfirm();
            },
            () => {
                typeof mixpanel !== 'undefined' && mixpanel.track(
                    'MyPageMyInfoDeleteProfileCancelClick'
                );
            },
        );
    };

    const onDeleteProfileImageConfirm = async () => {
        try {
            setIsLoading(true);
            const res = await Axios.delete(`v1/user/profile-image/`);
            if (res.status < 400) {
                userContext.setUser(res.data);
                onChange(res.data.profile_image_url);
            } else {
                alert('사진 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            alert('사진 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.');
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    if (!user) return <div></div>;

    const onImageInputClick = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoProfileImageChangeTextClick'
        );
        if (isLoading) {
            return;
        }
        e.target.value = null;
    };

    return (
        <div className={classNames(styles.profileImageEditor, horizontal ? utilStyles.flexRow : utilStyles.flexCol)}>
            {
                user.profile_image_url
                    ?
                    <div className={classNames(styles.profileImage, styles.profileImageBorder, horizontal ? styles.profileImageHorizontal : undefined)}>
                        <Image unoptimized src={user.profile_image_url} layout="fill" />
                        <div className={styles.profileImageDelete} onClick={onDeleteProfileImage}>삭제</div>
                    </div>
                    : <DefaultProfileIcon className={classNames(styles.profileImage, horizontal ? styles.profileImageHorizontal : undefined)} viewBox="0 0 32 32" />
            }
            <div className={classNames(styles.profileNicknameContainer, horizontal ? styles.profileNicknameContainerHorizontal : undefined)}>
                <div className={classNames(styles.profileNicknameWrapper, horizontal ? styles.profileNicknameWrapperHorizontal : undefined)}>
                    <span className={classNames(styles.profileNickname, horizontal ? styles.profileNicknameHorizontal : undefined)}>{user.nickname}</span>
                    {/*<ArrowRightIcon className={styles.profileNicknameArrow} viewBox="0 0 16 16" />*/}
                </div>
                <div className={classNames(styles.profileEditText, utilStyles.cursorPointer)}>
                    <span>프로필 사진 바꾸기</span>
                    <input type="file" id="img" name="img" accept="image/*" disabled={isLoading} className={styles.imageInputInvisible}
                           onChange={onFileSelect(uploadProfileImage)}
                           onClick={onImageInputClick}
                    />
                </div>
            </div>
        </div>
    )
}

export const NicknameForm = ({formik, isLoading}) => {
    const {errors, values, setFieldValue, touched, setFieldTouched, resetForm, handleSubmit, setTouched, setFieldError} = formik;

    const checkNickname = async (nickname) => {
        if (!nickname) {
            setFieldValue(KEY_NICKNAME_EXISTS, '');
        }
        try {
            const res = await Axios.get('v1/auth/check-nickname/', {params: {nickname: nickname}});
            console.log(res);
            if (res.status === 409) {
                setFieldValue(KEY_NICKNAME_EXISTS, '사용중인 유저네임입니다.');
            } else {
                setFieldValue(KEY_NICKNAME_EXISTS, '');
            }
        } catch (e) {
            captureException(e);
            setFieldValue(KEY_NICKNAME_EXISTS, '');
        }
    }

    const onChangeNickname = (nickname) => {
        if (!nickname) {
            setFieldValue(KEY_NICKNAME, '');
        }
        const lowerNick = nickname.toLowerCase();
        if (lowerNick.match(REGEX_NICKNAME_LEGAL)) {
            setFieldValue(KEY_NICKNAME, lowerNick);
        }
    }

    const nickNameError = !!values[KEY_NICKNAME_EXISTS] || (!!touched[KEY_NICKNAME] && NICKNAME_ERROR_FIELDS.filter(field => errors[field]).length > 0);

    return (
        <>
            <TextInput readonly={isLoading} error={nickNameError} value={values[KEY_NICKNAME]} onBlur={() => setFieldTouched(KEY_NICKNAME)} onChangeThrottled={checkNickname} onChange={onChangeNickname} placeholder="영문, 숫자, 마침표(.), 언더바(_)만 사용 가능" maxLength={20} />
            {
                (!!values[KEY_NICKNAME] || touched[KEY_NICKNAME]) &&
                <div style={{marginTop: 6}}>
                    <CheckedInputError isValid={!errors[KEY_NICKNAME_CONTAINS_ALPHABET]} message="영문포함" />
                    <CheckedInputError isValid={!errors[KEY_NICKNAME]} message="2-20자리" />
                    {
                        values[KEY_NICKNAME_EXISTS] && <CheckedInputError isValid={false} message="사용중인 유저네임" />
                    }
                </div>
            }
        </>
    )
}


NicknameForm.initialValue = {
    [KEY_NICKNAME]: '',
    [KEY_NICKNAME_EXISTS]: '',
};

NicknameForm.validationSchema = {
    [KEY_NICKNAME]: yup.string().required().min(2).max(20),
    [KEY_NICKNAME_CONTAINS_ALPHABET]: yup.boolean().when(KEY_NICKNAME, (nickname, schema) => {
        if (!nickname) return schema.required();
        if (!nickname.match(REGEX_CONTAINS_ALPHABET)) {
            return schema.oneOf([true]).required();
        }
    }),
    [KEY_NICKNAME_LEGAL]: yup.boolean().when(KEY_NICKNAME, (nickname, schema) => {
        if (!nickname) return schema;
        if (!nickname.match(REGEX_NICKNAME_LEGAL)) {
            return schema.oneOf([true]).required();
        }
    }),
}

NicknameForm.preSubmit = (values, callback) => {
    const actualCallback = callback || function () {};
    if (values[KEY_NICKNAME_EXISTS]) {
        actualCallback();
        alert('사용중인 유저네임입니다.');
        return false;
    }
    return true;
}

const NicknameEditor = () => {
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: NicknameForm.initialValue,
        validationSchema: yup.object(NicknameForm.validationSchema),
        onSubmit: async values => {
            if (!NicknameForm.preSubmit(values, () => {
            })) {
                return;
            }

            const data = JSON.parse(JSON.stringify(values));
            delete data[KEY_NICKNAME_EXISTS];
            setIsLoading(true);
            try {
                const res = await Axios.post('v1/auth/change-nickname/', data);
                if (res.status < 400) {
                    const user = res.data;
                    userContext.setUser(user);
                    alert('유저네임이 변경되었습니다.');
                    setIsEditMode(false);
                    resetForm();
                } else {
                    if (res.data.display_message) {
                        alert(res.data.display_message);
                    } else {
                        captureMessage(JSON.stringify(res.data));
                        alert('유저네임 변경에 실패했습니다. 잠시 후 다시 시도해주세요.');
                    }
                }
            } catch (e) {
                captureException(e);
                alert('유저네임 변경에 실패했습니다. 잠시 후 다시 시도해주세요.');
            } finally {
                setIsLoading(false);
            }
        }
    });

    const onChangeTextClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoNicknameChangeClick',
        );
        setIsEditMode(true);
    };

    const onCancelClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoNicknameChangeCancelClick',
        );
        setIsEditMode(false);
        resetForm();
    };

    const onSubmitClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoNicknameChangeSubmitClick',
        );
        handleSubmit();
    }

    const {resetForm, handleSubmit} = formik;

    return (
        <div className={classNames(styles.editorContainer, isEditMode ? styles.editorContainerActive : undefined)}>
            <div>
                <span className={styles.sectionSubtitle}>유저네임</span>
                {
                    !isEditMode &&
                    <span className={styles.content}>{user.nickname}</span>
                }
            </div>
            {
                !isEditMode ?
                    <div className={styles.editorButtonWrapper}>
                        <FullButton white height={36} title="변경" fontSize={15} nonBold onClick={onChangeTextClick} />
                    </div>
                    :
                    <div style={{marginTop: 4, width: '100%'}}>
                        <NicknameForm formik={formik} isLoading={isLoading} />
                        <div style={{height: 24}}/>
                        <div className={styles.buttonsContainer}>
                            <div className={styles.submitButtonWrapper}>
                                <FullButton disabled={isLoading} height={48} fontSize={16} light title="변경 취소"
                                            onClick={onCancelClick} />
                            </div>
                            <div className={styles.submitButtonWrapper}>
                                <FullButton disabled={isLoading} height={48} fontSize={16} title="저장"
                                            onClick={onSubmitClick} />
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
};

export const getSNSIcon = (auth_type) => {
    if (auth_type === AUTH_TYPE_NAVER) {
        return <NaverLogo viewBox="0 0 48 48" className={styles.snsLogo} />;
    } else if (auth_type === AUTH_TYPE_KAKAO) {
        return <KakaoLogo viewBox="0 0 48 48" className={styles.snsLogo} />;
    } else if (auth_type === AUTH_TYPE_APPLE) {
        return <AppleLogo viewBox="0 0 48 48" className={styles.snsLogo} />;
    } else {
        return <GoogleLogo viewBox="0 0 48 48" className={styles.snsLogo} />;
    }
}


export const getSNSName = (auth_type) => {
    if (auth_type === AUTH_TYPE_NAVER) {
        return "네이버";
    } else if (auth_type === AUTH_TYPE_KAKAO) {
        return "카카오";
    } else if (auth_type === AUTH_TYPE_APPLE) {
        return "애플";
    } else {
        return "구글";
    }
}


export const useSelfVerification = ({confirmMessage, direct, redirect}) => {
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const router = useRouter();

    const iamPortVerify = useCallback(() => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoEditSelfVerificationInfoClick',
            {
                isVerified: Boolean(user?.rrn),
            }
        );
        if (confirmMessage) {
            customConfirm(
                confirmMessage,
                '확인',
                '취소',
                () => {
                    iamPortVerifyConfirm();
                },
                () => {
                },
            );
        } else {
            iamPortVerifyConfirm();
        }
    }, [user, router, redirect]);

    const iamPortVerifyConfirm = () => {
        let redirectURL = redirect || `${SITE_URL}${router.asPath}`;
        const redirectHost = redirectURL.split('?')[0];
        let redirectQuery = redirectURL.split('?')[1] || '';
        redirectQuery = Object.fromEntries(new URLSearchParams(redirectQuery));

        try { window.IMP.certification } catch { return }  // Make sure window.IMP is set
        window.IMP.init(IAMPORT_STORE_ID);
        window.IMP.certification({
                merchant_uid: `${user?.email || v4()}_${Date.now()}`,
                popup: false,
                m_redirect_url : direct ? redirectURL :`${SITE_URL}${certCheckRoute}?redirect=${encodeURIComponent(redirectURL)}`,
            },
            async (result) => {
                if (result.success) {
                    if (direct) {
                        router.push({
                            pathname: redirectHost,
                            query: Object.assign({}, redirectQuery, {imp_uid: result.imp_uid}),
                        }, undefined, {shallow: false});
                    } else {
                        router.push({
                            pathname: `${SITE_URL}${certCheckRoute}`,
                            query: {
                                redirect: encodeURIComponent(redirectURL),
                                imp_uid: result.imp_uid,
                            },
                        }, undefined, {shallow: false});
                    }
                } else {
                    alert(`본인인증에 실패했습니다: ${result.error_msg}`);
                    router.push(`${SITE_URL}${router.asPath}`);
                }
            });
    };
    return iamPortVerify;
}


export const SelfVerificationButton = (props) => {
    const {setIsLoading, isLoading, children, redirect, confirmMessage, direct} = props;
    const onSuccess = props.onSuccess || function () {};
    const userContext = useContext(UserContext);

    const verify = useSelfVerification({confirmMessage, direct, redirect});

    return (
        <div onClick={!isLoading ? verify : () => {}} style={props.style}>
            <IamportScript />
            {children}
        </div>
    )
}

const SelfVerificationEditor = () => {
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            {
                !!user.name &&
                <>
                    <div className={styles.selfVerificationSection}>
                        <span className={styles.sectionSubtitle}>이름</span>
                        <span className={styles.content}>{user.name}</span>
                    </div>
                    <div className={styles.selfVerificationSection}>
                        <span className={styles.sectionSubtitle}>휴대폰 번호</span>
                        <span className={styles.content}>
                            {
                                user.phone_number ?
                                    dashedPhoneNumber(user.phone_number)
                                    :
                                    <span className={classNames(utilStyles.errorText, utilStyles.medium)}>
                                        휴대폰 번호가 변경되었어요. 본인인증을 통해 다시 등록해주세요.
                                    </span>
                            }
                        </span>
                    </div>
                </>
            }
            <SelfVerificationButton isLoading={isLoading} setIsLoading={setIsLoading} >
                <FullButton
                    disabled={isLoading}
                    height={44} fontSize={16} title={`본인인증으로 정보 ${!!user.name ? '수정' : '등록'}하기`} />
            </SelfVerificationButton>

            <div className={styles.bulletTextWrapper}>
                <BulletText>본인인증을 통해 기본정보를 등록 및 수정할 수 있습니다.</BulletText>
                <BulletText>개명하신 경우 본인인증을 통해 자동으로 이름이 변경됩니다.</BulletText>
            </div>

        </>
    )
}



const KEY_SMS_MARKETING_AGREEMENT = 'is_sms_marketing_agreed';
const KEY_EMAIL_MARKETING_AGREEMENT = 'is_email_marketing_agreed';
export const KEY_PUSH_MARKETING_AGREEMENT = 'is_push_marketing_agreed';

const MarketingAgreementEditor = () => {
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);
    const pushContext = useContext(PushContext);
    const user = userContext.user;

    const onChangeAgreement = async (key) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoMarketingAgreementEditorAgreeClick',
            {
                key
            }
        );
        const currVal = user[key];

        try {
            setIsLoading(true);
            const res = await Axios.patch('v1/user/marketing-agreement/', {[key]: !currVal});
            if (res.status < 400) {
                userContext.setUser(res.data);
                console.log(res.data);
            } else {
                alert('일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            captureException(e);
            alert('일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }
    };

    const showDetailClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoMarketingAgreementEditorShowDetailClick',
        );
    };

    const isPushOn = pushContext.isDevicePushOn && pushContext.isMarketingAgreed;

    return (
        <div>
            <div className={styles.agreementCheckboxWrapper}>
                <CheckboxText checked={user[KEY_SMS_MARKETING_AGREEMENT]} isLoading={isLoading} onClick={() => {
                    onChangeAgreement(KEY_SMS_MARKETING_AGREEMENT);
                }}>
                    <span className={styles.agreementCheckboxText}>문자메시지</span>
                </CheckboxText>
            </div>
            <div className={styles.agreementCheckboxWrapper}>
                <CheckboxText checked={user[KEY_EMAIL_MARKETING_AGREEMENT]} isLoading={isLoading} onClick={() => {
                    onChangeAgreement(KEY_EMAIL_MARKETING_AGREEMENT);
                }}>
                    <span className={styles.agreementCheckboxText}>이메일</span>
                </CheckboxText>
            </div>
            {
                isApp() &&
                <div className={styles.agreementCheckboxWrapper}>
                    <CheckboxText checked={isPushOn} onClick={() => {
                        if (pushContext.isLoading) return;
                        typeof mixpanel !== 'undefined' && mixpanel.track(
                            'MyPageMyInfoMarketingAgreementEditorAgreeClick',
                            {
                                key: KEY_PUSH_MARKETING_AGREEMENT,
                            }
                        );
                        pushContext.sendPushAgreementRequest(!isPushOn);
                    }}>
                        <span className={styles.agreementCheckboxText}>푸시 알림</span>
                    </CheckboxText>
                </div>
            }
            <span className={styles.agreementWarning}>서비스의 중요 안내사항 및 주문/배송에 대한 정보는 위 수신 동의 여부와 관계없이 발송됩니다.</span>
            <Link href={marketingAgreementRoute}>
                <a target="_blank" className={styles.agreementSeeMore} onClick={showDetailClick}>
                    내용보기
                </a>
            </Link>
        </div>
    );
}


const MyInfo = () => {
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const router = useRouter();

    const [isInfoLoading, setIsInfoLoading] = useState(true);

    const userContext = useContext(UserContext);
    const user = userContext.user;

    useEffect(async () => {
       try {
           await userContext.fetchUser();
       } catch(e) {} finally {
           setIsInfoLoading(false);
       }
    }, []);

    const withdraw = async () => {
        // if (!confirm('')) {
        //     return;
        // }
        //
        // try {
        //     const res = await Axios.post ('v1/auth/withdraw');
        //     if (res.status < 400) {
        //         userContext.setUser(null);
        //         await router.replace(storeRoute);
        //     } else {
        //         alert('일시적인 오류가 발생했습니다. 고객센터로 문의해주세요.');
        //     }
        // } catch (e) {
        //     alert('일시적인 오류가 발생했습니다. 고객센터로 문의해주세요.');
        // } finally {
        //
        // }
        // TODO: 회워탈퇴 페이지 완성 후 작성
    };

    const onWithdrawClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MyPageMyInfoWithdrawClick',
        );
    };

    return (
        <>
            <div className={classNames(styles.container, utilStyles.pageContainer)}>
                <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                    <TitleBar title="회원정보 수정" isBack close={() => router.back()} />
                </div>
                {
                    !isMobile &&
                    <>
                        {/*<MyInfoCard />*/}
                        <div style={{height: isLarge ? 20 : 20}} />
                    </>
                }
                {
                    isInfoLoading ?
                        <Loading />
                        :
                        (
                            !!userContext.user
                            ?
                                <>
                                    <div className={styles.section}>
                                        <ProfileImageEditor />
                                    </div>
                                    { isMobile && <div className={utilStyles.borderUnderLine}/> }
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>로그인 정보</span>
                                        {
                                            user.auth_type !== AUTH_TYPE_APPLE &&
                                            <>
                                                <span className={styles.sectionSubtitle}>아이디(이메일)</span>
                                                <span className={styles.content}>{user.email}</span>
                                            </>
                                        }
                                        {
                                            user.auth_type === AUTH_TYPE_EMAIL ?
                                                <PasswordEditor isEditModeEnabled isOldPasswordRequired onSuccess={() => alert('비밀번호가 변경되었습니다.')} />
                                                :
                                                <div className={styles.snsInfoContainer}>
                                                    <div className={utilStyles.noLineHeight}>
                                                        {getSNSIcon(user.auth_type)}
                                                        <span className={styles.snsType}>{getSNSName(user.auth_type)} sns 로그인</span>
                                                    </div>
                                                    <span className={styles.snsDescription}>sns 로그인을 사용중인 경우, 비밀번호 변경이 불가능합니다.</span>
                                                </div>
                                        }
                                        <NicknameEditor />
                                    </div>
                                    {
                                        isMobile && <div className={utilStyles.mobileBorder} />
                                    }
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>기본 정보</span>
                                        <SelfVerificationEditor />
                                    </div>
                                    {
                                        isMobile && <div className={utilStyles.mobileBorder} />
                                    }
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>마케팅 정보 수신 및 활용 동의</span>
                                        <MarketingAgreementEditor />
                                    </div>
                                    {
                                        isMobile && <div className={utilStyles.mobileBorder} />
                                    }
                                    <div className={styles.withdrawContainer}>
                                        <span className={styles.withdrawQuestion}>헤메코 서비스를 탈퇴하시겠습니까?</span>
                                        <Link href={withdrawRoute}>
                                            <a className={styles.withdrawLink} onClick={onWithdrawClick}>회원탈퇴</a>
                                        </Link>
                                    </div>
                                </>
                            :
                                <div>회원정보를 불러오는데 실패헀습니다.<br />잠시 후 다시 시도해주세요.</div>
                        )
                }
            </div>
        </>
    )
};

MyInfo.getLayout = IamportLayout;
export async function getStaticProps(context) {
    return {
        props: {
            protected: true,
        },
    }
}
export default MyInfo;
