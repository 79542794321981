import styles from "components/cards/checkoutCouponCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import RadioInactiveIcon from "images/icons/radio_inactive.svg";
import RadioActiveIcon from "images/icons/radio_active.svg";
import {formatDate, getExpiresAtDateFromDMY, numberWithComma} from "common/utils";
import classNames from "classnames";
import useTimer from "hooks/useTimer";


export const COUPON_TYPE_PERCENTAGE = 'PTG';
export const COUPON_TYPE_FIXED = 'FIX';

export default function CheckoutCouponCard(props) {
    const {coupon, selectedCoupons, isLoading, withBorder, unselectable, relative, expired, showTotal} = props;
    const onSelect = props.onSelect || function () {
    };

    const amount = relative ? coupon.amount : coupon.discount;
    const unit = relative ? (coupon.type === COUPON_TYPE_PERCENTAGE ? '%' : '원') : '원';

    const {timeString, seconds} = useTimer(coupon.usable_after);

    const condition = (
        coupon.min_amount ?
            (coupon.max_amount ?
                `${numberWithComma(coupon.min_amount)}원 이상 구매 시 (최대 ${numberWithComma(coupon.max_amount)}원 할인)` :
                `${numberWithComma(coupon.min_amount)}원 이상 구매 시`
            ) :
            coupon.max_amount ?
                `최대 ${numberWithComma(coupon.max_amount)}원 할인` :
                null
    );

    return (
        <div className={classNames(styles.couponContainer, withBorder ? styles.borderedContainer : undefined)}
             onClick={() => {
                 !isLoading && !unselectable && onSelect(coupon.id)
             }}>
            <div>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, (
                    coupon.is_app_only || coupon.is_gift_only || (!unselectable && timeString) || !!coupon.is_over_people_count
                ) ? styles.tagsContainer : undefined)}>
                    {
                        coupon.is_app_only &&
                        <div className={styles.appOnlyLabel}>
                            앱전용
                        </div>
                    }
                    {
                        coupon.is_gift_only &&
                        <div className={styles.giftOnlyLabel}>
                            선물전용
                        </div>
                    }
                    {
                        !unselectable && timeString &&
                        <div className={styles.timerLabel}>
                            {timeString} 후 사용가능
                        </div>
                    }
                    {
                        !!coupon.is_over_people_count &&
                        <div className={styles.fifoOver}>
                            쿠폰 수량 소진
                        </div>
                    }
                </div>
                <div className={styles.discountAmountWrapper}>
                    <span className={classNames(styles.discount, expired ? styles.expiredDiscount : undefined)}>
                        {numberWithComma(amount)}{unit}
                        {
                            coupon.type === COUPON_TYPE_PERCENTAGE && showTotal &&
                            <span className={styles.totalDiscount}> ({numberWithComma(coupon.discount)}원 할인)</span>
                        }
                    </span>
                </div>
                <span
                    className={classNames(styles.name, expired ? styles.expiredName : undefined)}>{coupon.name}</span>
                {
                    condition &&
                    <span className={classNames(styles.conditionText, expired ? styles.expiredName : undefined)}>
                        {condition}
                    </span>
                }
                <span className={classNames(styles.period, expired ? styles.expiredPeriod : undefined)}>
                    {
                        !!coupon.used_at ?
                            <span>사용일 : {formatDate(coupon.used_at)}
                                {
                                    !!coupon.order_number &&
                                    <>
                                        <br/>
                                        <div style={{marginTop: 4}}>주문번호: {coupon.order_number}</div>
                                    </>
                                }
                            </span>
                            :
                            (
                                Math.floor(new Date() / 1000) > coupon.expires_at ?
                                    '기간만료'
                                    :
                                    `${formatDate(coupon.created_at)}-${formatDate(coupon.expires_at)}`
                            )
                    }
                </span>
            </div>
            {
                !unselectable &&
                (
                    selectedCoupons.filter(e => e.id === coupon.id).length > 0
                        ?
                        <RadioActiveIcon viewBox="0 0 24 24" className={styles.radioButton}/>
                        :
                        <RadioInactiveIcon viewBox="0 0 24 24" className={styles.radioButton}/>
                )
            }
        </div>
    )
}
