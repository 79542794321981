import utilStyles from 'styles/utils.module.scss';
import styles from 'components/layout/topBanner.module.scss';
import classNames from "classnames";
import CloseIcon from "images/icons/close.svg";
import {useContext, useEffect, useState} from "react";
import {loginRoute, reviewRoute} from "common/const";
import {getPixelValueFromString, isApp} from "common/utils";
import UserContext from "context/AuthContext";
import Link from "next/link";
import ModalContext from "context/ModalContext";


let navBar = null;
const topBarHeight = getPixelValueFromString(utilStyles.topBarHeight);
const topPadding = getPixelValueFromString(utilStyles.topPadding);


const getBannerRefusedAtKey = (_id) => {
    return `banner_${_id}`;
}


export default function TopBanner () {
    const [bannerIdToShow, setBannerIdToShow] = useState({});

    const [localIsApp, setLocalIsApp] = useState(true);

    useEffect(() => {
        if (isApp()) {
            setLocalIsApp(true);
        } else {
            setLocalIsApp(false);
        }
    }, []);

    useEffect(() => {
        const targetDate = new Date();
        targetDate.setDate(targetDate.getDate() - 1);
        const refused_at_list = [
            window.localStorage.getItem(getBannerRefusedAtKey(1)),
            window.localStorage.getItem(getBannerRefusedAtKey(2)),
            window.localStorage.getItem(getBannerRefusedAtKey(3)),
        ];
        setBannerIdToShow((values) => {
            const newValues = JSON.parse(JSON.stringify(values));
            newValues[1] = !refused_at_list[0] || refused_at_list[0] <= targetDate.getTime().toString();
            newValues[2] = !refused_at_list[1] || refused_at_list[1] <= targetDate.getTime().toString();
            newValues[3] = !refused_at_list[2] || refused_at_list[2] <= targetDate.getTime().toString();
            return newValues;
        });
    }, []);

    const user = useContext(UserContext).user;
    const {openAppInstallModal, openCouponModalByIdentifier, couponModalInfo, receivedCouponMoldIdsRef} = useContext(ModalContext);


    let banner_id;
    let text;
    let link;
    let textOnly;

    if (user) {
        if (user?.writable_reviews?.max_points > 0) {
            banner_id = 1;
            text = (
                <span className={styles.bannerText}>
                    <span className={utilStyles.verticalMiddle}>리뷰 쓰고</span>
                    <span className={classNames(styles.bannerTextHighlight, utilStyles.verticalMiddle)}>{user.writable_reviews.max_points} P 적립 받기!</span>
                </span>
            );
            textOnly = '리뷰 쓰고 P 적립 받기!';
            link = reviewRoute;
        } else {
            banner_id = 2;
            text = (
                <span className={styles.bannerText}>
                    <span className={utilStyles.verticalMiddle}>300자/포토 리뷰 쓰면</span>
                    <span className={classNames(styles.bannerTextHighlight, utilStyles.verticalMiddle)}>1000 P 적립!</span>
                </span>
            );
            textOnly = '300자/포토 리뷰 쓰면 1000 P 적립!';
            link = null;
        }
    } else {
        banner_id = 3;
        text = (
            <span className={styles.bannerText}>
                <span className={utilStyles.verticalMiddle}>신규 가입하고</span>
                <span className={classNames(styles.bannerTextHighlight, utilStyles.verticalMiddle)}>즉시 25,000원 쿠폰팩 받기!</span>
            </span>
        );
        textOnly = '신규 가입하고 즉시 25,000원 쿠폰팩 받기!';
        link = loginRoute;
    }

    const onBannerClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('TopBannerClick', {
            bannerId: banner_id,
            text: textOnly,
        });
    };

    const getBodyTag = () => {
        return (
            <a className={classNames(utilStyles.fullWidth, styles.banner)}>
                <div className={classNames(utilStyles.justifyContentSpaceBetween, utilStyles.flexRow, styles.content)}
                     onClick={onBannerClick}
                >
                    {/*<Image src={!isMobile ? bannerImg : bannerImgMobile}></Image>*/}
                    {text}
                    <CloseIcon viewBox="0 0 20 20" width={20} height={20} className={styles.closeIcon} onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.localStorage.setItem(getBannerRefusedAtKey(banner_id), (new Date()).getTime().toString());
                        setBannerIdToShow((values) => {
                            const newValues = JSON.parse(JSON.stringify(values));
                            newValues[banner_id] = false;
                            return newValues;
                        });
                        typeof mixpanel !== 'undefined' && mixpanel.track('TopBannerClose', {bannerId: banner_id, text: textOnly});
                    }}/>
                </div>
            </a>
        )
    };

    if (!localIsApp) {
        return (
            <div className={styles.webBanner} onClick={() => {
                typeof mixpanel !== 'undefined' && mixpanel.track('TopBannerAppInstallClick');
                openAppInstallModal();
            }}>
                <div className={styles.leftAppContainer}>
                    <div className={styles.appIcon} />
                    <div className={styles.appText}>
                        <div className={styles.appTextContent}>
                            앱에서 바로 쓸 수 있는
                        </div>
                        <div className={styles.appTextContent}>
                            <span className={utilStyles.brandColorText}>1,000P</span>와 <span className={utilStyles.brandColorText}>쿠폰</span> 받아보세요
                        </div>
                    </div>
                </div>
                <div className={styles.openApp}>앱에서 보기</div>
            </div>
        );
    }

    return (
        user === undefined ?
            <div></div>
            :
            bannerIdToShow[banner_id] ?
                (
                    link ?
                        <Link href={link}>
                            {getBodyTag()}
                        </Link>
                        :
                        getBodyTag()
                )
                : <div></div>
    )
}
