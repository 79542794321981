import Script from "next/script";

export default function IamportScript () {
    return (
        <>
            <Script src="https://cdn.iamport.kr/js/iamport.payment-1.2.0.js" strategy="lazyOnload" />
            <Script src="https://code.jquery.com/jquery-3.5.1.min.js" strategy="lazyOnload" />
        </>
    );
}
