import OrderExchangeCard from "components/cards/order/OrderExchangeCard";
import OrderRefundCard from "components/cards/order/OrderRefundCard";
import OrderOptionCard from "components/cards/order/OrderOptionCard";


export default function OrderOptionListCard (props) {
    const {orderNumber, orderOption, onPurchaseConfirmSuccess, onReviewPostSuccess} = props;

    switch (orderOption.status) {
        case '교환접수':
        case '교환상품 수거중':
        case '교환상품 처리중':
        case '교환상품 배송중':
        case '교환완료':
            return (
                <OrderExchangeCard
                    orderNumber={orderNumber}
                    orderOption={orderOption}
                    disableLink={props.disableLink}
                />
            )
        case '반품접수':
        case '반품상품 수거중':
        case '반품처리중':
        case '반품완료':
            return (
                <OrderRefundCard
                    orderNumber={orderNumber}
                    orderOption={orderOption}
                    disableLink={props.disableLink}
                />
            )
        default:
            return (
                <OrderOptionCard
                    onReviewPostSuccess={onReviewPostSuccess}
                    onPurchaseConfirmSuccess={onPurchaseConfirmSuccess}
                    orderNumber={orderNumber}
                    orderOption={orderOption}
                    disableLink={props.disableLink}
                />
            )
    }
}
