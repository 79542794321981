import utilStyles from 'styles/utils.module.scss'
import GoogleLogoSmall from 'images/icons/google_logo.svg';
import GoogleLogo from 'images/icons/google.svg';
import {AUTH_TYPE_GOOGLE, GOOGLE_CLIENT_ID, SITE_URL} from "common/const";
import {v4 as uuidv4} from 'uuid';
import {useRouter} from "next/router";
import {REDIRECT_URI} from "components/buttons/NaverLoginButton";
import authStyles from "pages/auth/index.module.scss";
import classNames from "classnames";
import SpeechBubble from "components/SpeechBubble";


const getRedirectUri = (isSignUp) => {
    let uri = `${SITE_URL}/auth/sign-in?auth_type=${AUTH_TYPE_GOOGLE}`;
    if (isSignUp) {
        uri += '&is_sign_up=true'
    }
    return encodeURIComponent(uri);
}

export const promptGoogleLogin = (redirect, isSignUp) => {
    const state = uuidv4();
    window.localStorage.setItem(AUTH_TYPE_GOOGLE, state);
    window.localStorage.setItem(REDIRECT_URI, redirect);
    window.location.href = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?redirect_uri=${getRedirectUri(isSignUp)}&response_type=permission%20id_token&scope=email%20profile%20openid&openid.realm&include_granted_scopes=true&client_id=${GOOGLE_CLIENT_ID}&state=${state}&prompt&fetch_basic_profile=true&gsiwebsdk=2&flowName=GeneralOAuthFlow`;
};

const GoogleLoginButton = (props) => {
    const router = useRouter();
    const {disabled, signUp, full, lastLoginType} = props;

    return (
        <a onClick={!disabled ? () => { !!signUp ? promptGoogleLogin(router.query.redirect, true) : promptGoogleLogin(router.query.redirect) } : () => {}}>
            {
                full ?
                    <div className={authStyles.wideSNSLoginButton} style={{backgroundColor: '#F5F6F9'}}>
                        {
                            lastLoginType === AUTH_TYPE_GOOGLE &&
                            <div className={authStyles.lastLoginBubble} style={{bottom: -20}}>
                                <SpeechBubble
                                    sidePadding={12} verticalPadding={6}
                                    pointLength={5} borderRadius={9999}
                                    top
                                    backgroundColor={utilStyles.brand}
                                >
                                <span className={authStyles.lastLoginBubbleText}>
                                    최근 로그인
                                </span>
                                </SpeechBubble>
                            </div>
                        }
                        <div
                            className={classNames(utilStyles.fullFlex, utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter, utilStyles.textAlignCenter, authStyles.wideButtonText)}
                            style={{color: 'black'}}
                        >
                            <div className={utilStyles.socialIcon}>
                                <GoogleLogoSmall />
                            </div>
                            구글로 계속하기
                        </div>
                    </div>
                    :
                    <GoogleLogo
                        id="google-log-in-btn"
                        className={utilStyles.cursorPointer}
                        width={48}
                        height={48}/>
            }
        </a>
    );
};

export default GoogleLoginButton;
