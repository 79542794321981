import styles from "components/sections/product/RelatedProductsSection.module.scss";
import ProductListCard from "components/cards/ProductListCard";
import {getDaysLeft} from "common/utils";


const RelatedProductsSection = ({ title, products }) => {
    const onProductClick = (product, itemIdx) => {
        const promotionDaysLeft = product.promotion_ends_at
            ? getDaysLeft(product.promotion_ends_at)
            : null;

        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailRelatedProductClick', {
            title,
            productId: product.id,
            productName: product.name,
            productBrand: product.brand.name,
            productCategory: product.category,
            isDiscounted: product.price !== product.discounted_price,
            promotionDaysLeft,
            itemIdx,
        });
    };

    return (
        <div className={styles.root}>
            <div className={styles.sectionTitleWrapper}>
                <span className={styles.sectionTitle}>{title}</span>
            </div>
            <div className={styles.productsWrapper}>
                <div className={styles.horizontalScrollPaddingLeft} />
                {
                    products.map((e, index) => {
                        return (
                            <div className={styles.cardWrapper} key={index}>
                                <ProductListCard
                                    product={e} showPromotionDaysLeft
                                    onClick={() => onProductClick(e, index)}
                                />
                            </div>
                        )
                    })
                }
                <div className={styles.horizontalScrollPaddingRight} />
            </div>
        </div>
    )
}

export default RelatedProductsSection;
