import styles from "components/modal/reviewModal.module.scss";
import utilStyles from 'styles/utils.module.scss';
import TitleBar from "components/layout/TitleBar";
import Image from "next/image";
import PipeIcon from "images/icons/pipe.svg";
import {useFormik} from "formik";
import * as yup from "yup";
import classNames from "classnames";

import StarIcon from 'images/icons/star_filled.svg';
import UnfilledStarIcon from 'images/icons/star_unfilled.svg';
import TextInput from "components/input/TextInput";
import BulletText from "components/BulletText";
import InputError from "components/input/InputError";
import ImageInput from "components/input/ImageInput";
import {useContext, useEffect, useRef, useState} from "react";
import {Axios} from "api";
import ImageUploadPreview from "components/input/ImageUploadPreview";
import FullButton from "components/buttons/FullButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "components/Modal";
import ImageUploader from "components/images/ImageUploader";
import {captureException} from "@sentry/nextjs";
import UserContext from "context/AuthContext";
import TruncateMarkup from "react-truncate-markup";
import CheckIcon from "images/icons/check_circle.svg";
import {customConfirm, numberWithComma} from "common/utils";
import {getHomeRoute} from "common/const";
import {useRouter} from "next/router";


const KEY_SCORE = 'score';
const KEY_CONTENT = 'content';
const KEY_IMAGES = 'images';
const KEY_ORDER_OPTION_ID = 'order_option_id';
const KEY_GIVEAWAY_ID = 'giveaway_id';
const KEY_PERMISSION_ID = 'permission_id';
const KEY_OPTION_ID = 'option_id';


const scoreToExpression = {
    1: '별로에요 :(',
    2: '아쉬워요',
    3: '괜찮아요',
    4: '좋아요',
    5: '완전 추천 :)',
}

export const StarSelector = (props) => {
    const {value, error, withoutText} = props;
    const onChange = props.onChange || function () {};
    const size = props.size || 36;
    const margin = props.margin ?? 8;

    const starCount = value || 0;
    const unfilledStarCount = 5 - value;

    const stars = [];

    for (let i = 0; i < starCount; i++) {
        stars.push(1);
    }

    for (let i = 0; i < unfilledStarCount; i++) {
        stars.push(0);
    }

    return (
        <div>
            <div>
                {
                    stars.map((e, idx) => {
                        return (
                            e ?
                                <StarIcon
                                    style={{marginRight: margin}}
                                    key={idx} width={size} height={size} className={styles.selectorStarIcon} viewBox="0 0 24 24"
                                    onClick={() => onChange(idx + 1)}
                                />
                                :
                                <UnfilledStarIcon
                                    style={{marginRight: margin}}
                                    key={idx} width={size} height={size} className={styles.selectorStarIcon} viewBox="0 0 24 24"
                                    onClick={() => onChange(idx + 1)}
                                />
                        )
                    })
                }
            </div>
            {
                !withoutText &&
                <span className={classNames(styles.expression, error ? styles.scoreError : undefined)}>
                    {error || scoreToExpression[value] || ' '}&nbsp;
                </span>
            }
        </div>
    )
}


export default function ReviewModal (props) {
    const close = props.close || function () {};
    const {
        optionId, productName, review, brandName, isOpen, orderOptionId,
        giveawayId, permissionId, imageUrl, optionName, resetVariable, hideWarning, isPhotoRequired,
        merchantUid
    } = props;

    useEffect(() => {
        if (isOpen) {
            console.log('props', props);
        }
    }, [isOpen]);

    const onSuccess = props.onSuccess || function () {};

    const [isLoading, setIsLoading] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);

    const modalRef = useRef(null);
    const userContext = useContext(UserContext);

    const router = useRouter();

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [KEY_SCORE]: review ? review.score || 0 : 0,
            [KEY_CONTENT]: review ? review.content || '' : '',
            [KEY_IMAGES]: review ? review.images || [] : [],
            [KEY_ORDER_OPTION_ID]: orderOptionId,
            [KEY_GIVEAWAY_ID]: giveawayId,
            [KEY_PERMISSION_ID]: permissionId,
            [KEY_OPTION_ID]: optionId,
        },
        validationSchema: yup.object({
            [KEY_SCORE]: yup.number().integer().required('필수 입력 항목입니다.').min(1, '필수 입력 항목입니다.').max(5),
            [KEY_CONTENT]: yup.string().required('필수 입력 항목입니다.').min(20, '리뷰를 최소 20자 이상 작성해주세요.').max(1000, '리뷰는 최대 1000자까지 작성 가능합니다.'),
            [KEY_IMAGES]: isPhotoRequired ? yup.array().min(1, '최소 1장의 사진을 업로드해주세요.') : yup.array(),
        }),
        onSubmit: async values => {
            const data = JSON.parse(JSON.stringify(values));
            data['image_ids'] = data[KEY_IMAGES].map(e => e.id);
            if (merchantUid) {
                data['merchant_uid'] = merchantUid;
            }
            delete data[KEY_IMAGES];

            try {
                setIsLoading(true);
                const isCreate = !review;
                const res = isCreate ? await Axios.post(`v2/store/reviews/`, data) : await Axios.patch(`v1/store/reviews/${review.id}/`, data);
                if (res.status < 400) {
                    console.log('res.data', res.data);
                    if (isCreate && ('nickname' in res.data.user)) {
                        userContext.setUser(res.data.user);
                    }
                    setIsLoading(false);
                    if (merchantUid) {
                        if (isCreate) {
                            onCreateSuccess(res.data.point);
                        } else {
                            customConfirm('리뷰가 수정되었습니다!', '리뷰함으로 가기', '홈으로 가기', () => {
                                onSuccess();
                                close();
                            }, () => {
                                router.push(getHomeRoute());
                            });
                        }
                    } else {
                        if (isCreate) {
                            onCreateSuccess(res.data.point);
                        } else {
                            onSuccess();
                        }
                    }
                } else {
                    alert(res.data.display_message || '리뷰 작성에 실패했습니다. 잠시 후 다시 시도해주세요.');
                }
            } catch (e) {
                alert('리뷰 작성에 실패했습니다. 잠시 후 다시 시도해주세요.');
                captureException(e);
            } finally {
                setIsLoading(false);
            }
        }
    });
    const {values, setFieldValue, initialValues, errors, setValues, touched, setFieldTouched, handleSubmit, resetForm, setFieldError, setErrors} = formik;

    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    useEffect(() => {
        if (resetVariable) {
            if (modalRef.current) {
                modalRef.current.scrollTop = 0;
            }
            resetForm();
        }
    }, [resetVariable])

    /*
    * 2024.07.31 ImageUploader 사용으로 Deprecated
    * */
    const uploadReviewImage = async (file) => {
        if (values[KEY_IMAGES].length >= 9) {
            alert('리뷰 이미지는 최대 9장까지 등록이 가능합니다.');
            return;
        }
        const formData = new FormData();
        formData.append('image', file);

        try {
            setIsImageLoading(true);
            const res = await Axios.post('v1/product-review-images/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    merchant_uid: merchantUid,
                }
            });
            if (res.status < 400) {
                await setValues(oldValues => {
                    const newValue = JSON.parse(JSON.stringify(oldValues));
                    newValue[KEY_IMAGES].push(res.data);
                    return newValue;
                })
            } else {
                alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            captureException(e);
        } finally {
            setIsImageLoading(false);
        }
    }

    /*
    * 2024.07.31 ImageUploader 사용으로 Deprecated
    * */
    const onDeleteReviewImage = async (imageId) => {
        try {
            setIsImageLoading(true);
            await Axios.delete(`v1/product-review-images/${imageId}/`);
        } catch (e) {
            captureException(e);
        } finally {
            await setValues(oldValues => {
                const newValue = JSON.parse(JSON.stringify(oldValues));
                newValue[KEY_IMAGES] = newValue[KEY_IMAGES].filter(e => e.id !== imageId);
                return newValue;
            })
            setIsImageLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const userAgent = typeof navigator !== 'undefined' && navigator.userAgent ? navigator.userAgent : '';
    const hideImageInput = userAgent.includes("Instagram");

    const onCloseClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalCloseClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(false);
        close();
    };

    const onContinueClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalContinueClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(false);
    };

    const onCloseButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalCloseButtonClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(true);
    };

    const onCancelClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalCancelClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(true);
    };

    const onSubmitClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalSubmitClick',
            {
                isEdit: Boolean(review),
            },
        );
        handleSubmit();
    };
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [successResult, setSuccessResult] = useState({point: 0});

    const handleSuccessModalClose = props.handleSuccessModalClose || (() => {});

    const onCreateSuccess = (point) => {
        onSuccess();
        setSuccessResult({point});
        setSuccessModalOpen(true);
    };

    const onSuccessModalClose = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalSuccessModalClose',
            {
                isEdit: Boolean(review),
            },
        );
        setSuccessModalOpen(false);
        handleSuccessModalClose();
        close();
    };

    return (
        <>
            {
                successModalOpen &&
                <Modal isOpen={successModalOpen} unclosable={true} width={310} round close={onSuccessModalClose} zIndex={100000001}>
                    <div className={styles.successModalRoot}>
                        <CheckIcon viewBox="0 0 32 32" width={28} height={28} />
                        <span className={styles.modalGrantPoint}>+{numberWithComma(successResult.point)}p</span>
                        <span className={styles.reviewDoneText}>리뷰 작성을 완료했습니다.</span>
                        <div className={styles.successModalButtonWrapper}>
                            <FullButton fontSize={16} title="확인" height={44} onClick={onSuccessModalClose} />
                        </div>
                    </div>
                </Modal>
            }
            <Modal bodyRef={modalRef} isOpen={isOpen} width={isMobile ? '100%' : undefined} close={() => close()} height={isMobile ? '100%' : undefined}>
                <div className={styles.container}>
                    <Modal isOpen={isModalOpen} unclosable={true} width={isMobile ? 300 : 416} round close={() => setIsModalOpen(false)}>
                        <div className={styles.confirmModalContainer}>
                            <span className={styles.confirmModalText}>리뷰를 작성하면 포인트가 지급됩니다.<br/>리뷰 작성을 그만하시곘습니까?</span>
                            <div className={utilStyles.flexRow}>
                                <div className={utilStyles.fullFlex}>
                                    <FullButton fontSize={16} title="작성 그만하기" white height={isMobile ? 44 :48}
                                                onClick={onCloseClick} />
                                </div>
                                <div style={{width: 12}} />
                                <div className={utilStyles.fullFlex}>
                                    <FullButton fontSize={16} title="작성 계속하기" onClick={onContinueClick} height={isMobile ? 44 : 48} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <div className={classNames(utilStyles.topSticky, utilStyles.whiteBackground)}>
                        <TitleBar fontSize={isMobile ? 18 : 20} title={review ? "리뷰 수정하기" : "리뷰 쓰기"} isClose close={onCloseButtonClick} />
                    </div>
                    {
                        !review && !hideWarning &&
                        <div className={styles.adContainer}>
                            {
                                !isMobile &&
                                <span className={styles.adText}>리뷰 쓰고 포인트 받기!</span>
                            }
                            <div className={styles.reviewPointContainer}>
                                <span className={styles.reviewPointText}>&nbsp;&nbsp;•&nbsp;&nbsp;일반 리뷰<span className={styles.reviewPoint}>500P</span></span>
                                <span className={styles.reviewPointText}>&nbsp;&nbsp;•&nbsp;&nbsp;300자 이상 리뷰/포토리뷰<span className={styles.reviewPoint}>1000P</span></span>
                                <span className={styles.reviewPointText}>
                                    &nbsp;&nbsp;•&nbsp;&nbsp;이 제품의 첫 리뷰라면<span className={styles.reviewPoint}>포인트 2배!</span> (일부 제품 제외)
                                </span>
                            </div>
                        </div>
                    }
                    <div className={styles.optionSection}>
                        <div className={styles.imageWrapper}>
                            <Image unoptimized src={imageUrl} objectFit="cover" layout="fill" />
                        </div>
                        <div>
                            <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                <span className={styles.brand}>
                                    <span>{brandName}</span>
                                </span>
                            </TruncateMarkup>
                            <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                <span className={styles.productName}>
                                    <span>{productName}</span>
                                </span>
                            </TruncateMarkup>
                            <div className={styles.optionNameWrapper}>
                                <span className={styles.optionText}>옵션</span>
                                <PipeIcon className={styles.pipe} viewBox="0 0 16 16" />
                                <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                    <span className={styles.optionText}>{optionName}</span>
                                </TruncateMarkup>
                            </div>

                        </div>
                    </div>
                    <div className={styles.scoreSection}>
                        <span className={styles.title}>별점 평가</span>
                        <span className={styles.scoreText}>이 제품에 대해 얼마나 만족하시나요?</span>
                        <StarSelector value={values[KEY_SCORE]} error={touched[KEY_SCORE] && errors[KEY_SCORE]} size={32} onChange={e => setFieldValue(KEY_SCORE, e)} />
                    </div>
                    <div className={styles.contentSection}>
                        <span className={styles.title}>리뷰를 작성해주세요.</span>
                        <TextInput
                            readonly={isLoading} onBlur={() => setFieldTouched(KEY_CONTENT, true)} value={values[KEY_CONTENT]}
                            onChange={e => setFieldValue(KEY_CONTENT, e)}
                            error={errors[KEY_CONTENT] && touched[KEY_CONTENT]}
                            multiLine height={240} maxLength={1000} redCounter={errors[KEY_CONTENT]}
                            placeholder="다른분들을 위해 제품에 대한 솔직한 리뷰를 남겨주세요! (최소 20자)"
                            trimOnCount
                        />
                        {
                            errors[KEY_CONTENT] && touched[KEY_CONTENT] && <InputError message={errors[KEY_CONTENT]} />
                        }
                        <div style={{height: 12}} />
                        <BulletText>해당 제품과 무관한 내용이나 동일 문자의 반복 등 부적합한 내용은 삭제 및 포인트 회수 될 수 있습니다.</BulletText>
                        {
                            (!review || (!!review && values[KEY_IMAGES].length !== 0))?
                            <div className={styles.photoSection}>
                                <div className={classNames(styles.title, utilStyles.noLineHeight)}>
                                    <span className={utilStyles.verticalMiddle}>사진 첨부</span>
                                    <span className={styles.subTitle}>
                                    {isPhotoRequired ? <span className={styles.subTitleRed}>(필수)</span> : '(선택)'}
                                </span>
                                    {
                                        errors[KEY_IMAGES] && touched[KEY_IMAGES] &&
                                        <InputError message={errors[KEY_IMAGES]}/>
                                    }
                                </div>
                                <div className={styles.imageRow}>
                                    <ImageUploader
                                        post={props.post}
                                        disabled={isLoading}
                                        images={values[KEY_IMAGES]}
                                        setImages={(val) => setFieldValue(KEY_IMAGES, val)}
                                        uploadUrl='v1/product-review-images/'
                                        isEditMode={!!review} //리뷰 수정인 경우 이미지 업로드 불가능
                                        size={9}
                                        //onDelete={(imageId) => onDeleteReviewImage(imageId)}
                                    />
                                </div>
                                <div className={styles.photoSectionDescriptionText}>
                                    {
                                        hideImageInput ?
                                            <BulletText>사진첨부를 지원하지 않는 브라우저입니다. 다른 브라우저를 이용해 주세요.</BulletText>
                                            :
                                            <BulletText>최대 9장 등록 가능합니다.</BulletText>
                                    }
                                </div>
                            </div>
                                :
                                <></>
                        }
                    </div>

                    {/*<div className={classNames(styles.title, utilStyles.noLineHeight)}>
                            <span className={utilStyles.verticalMiddle}>사진 첨부</span>
                            <span className={styles.subTitle}>
                                {isPhotoRequired ? <span className={styles.subTitleRed}>(필수)</span> : '(선택)'}
                            </span>
                            {
                                errors[KEY_IMAGES] && touched[KEY_IMAGES] && <InputError message={errors[KEY_IMAGES]} />
                            }
                        </div>
                        <div className={styles.imageRow}>
                            <div className={styles.imageCol}>
                                <div className={styles.colWrapper}>
                                    <div className={styles.colContent}>
                                        <ImageInput disabled={hideImageInput} isLoading={isImageLoading} onSelect={e => uploadReviewImage(e)} />
                                    </div>
                                </div>
                            </div>
                            {
                                values[KEY_IMAGES].map(e => {
                                    return (
                                        <div key={e.id} className={styles.imageCol}>
                                            <div className={styles.colWrapper}>
                                                <div className={styles.colContent}>
                                                    <ImageUploadPreview src={e.thumbnail_url} onDelete={() => {
                                                        onDeleteReviewImage(e.id);
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>*/}
                    <div className={styles.flexRow}>
                        {
                            isMobile &&
                            <>
                                <div className={utilStyles.fullFlex}>
                                    <FullButton light title="취소" height={48} fontSize={isMobile ? 16 : 18}
                                                onClick={onCancelClick}>
                                        취소
                                    </FullButton>
                                </div>
                                <div style={{width: 9}} />
                            </>
                        }
                        <div className={utilStyles.fullFlex}>
                            <FullButton disabled={isLoading || isImageLoading} title={`리뷰 ${review ? '수정': '작성'}하기`} height={48} white={!isMobile}  fontSize={isMobile ? 16 : 18} onClick={onSubmitClick} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
