import {useRouter} from 'next/router'
import {useContext, useEffect, useRef, useState} from "react";

import utilStyles from 'styles/utils.module.scss';
import commonStyles from "components/sections/product/index.module.scss";
import styles from "components/pages/ProductDetail.module.scss";
import ProductDetailCard from "components/cards/ProductDetailCard";
import TopTabBar from "components/TopTabBar";
import classNames from "classnames";
import ProductInfoSection from "components/sections/product/ProductInfoSection";
import ProductReviewSection from "components/sections/product/ProductReviewSection";
import ProductInquirySection from "components/sections/product/ProductInquirySection";
import ProductAdditionalInfoSection from "components/sections/product/ProductAdditionalInfoSection";
import {Axios, defaultCacheOption} from "api";
import {
    customAlert,
    getOffsetTop,
    getPixelStringFromInt,
    getPixelValueFromString,
    numberWithComma,
    shuffle
} from "common/utils";
import PurchaseButton from "components/buttons/PurchaseButton";
import {getReferralInfo} from "common/referral";
import Head from "next/head";
import {getHomeRoute, SITE_URL} from "common/const";
import {trackPixel} from "api/pixel";
import TitleBar from "components/layout/TitleBar";
import TopBar from "components/layout/TopBar/TopBar";
import SpeechBubble from "components/SpeechBubble";
import {captureException, captureMessage} from "@sentry/nextjs";
import ReviewModal from "components/modal/ReviewModal";
import UserContext from "context/AuthContext";
import HomeIcon from "images/icons/home.svg";
import Link from "next/link";
import {hasValidDirectPurchaseInfo} from "common/product";


const ROUTE_INFO = 'info';
const ROUTE_REVIEW = 'review';
const ROUTE_INQUIRY = 'inquiry';
const ROUTE_ADDITIONAL = 'additional';

export const addOptionCountImpl = (optionId, amount, setSelectedOptions) => {
    setSelectedOptions(val => {
        const newVal = val[optionId] ? val[optionId] + amount : amount;
        const newDict = Object.assign(JSON.parse(JSON.stringify(val)), {[optionId]: newVal});
        if (newVal === 0) {
            delete newDict[optionId];
        }
        return newDict;
    })
}

export const setOptionCountImpl = (optionId, amount, setSelectedOptions) => {
    setSelectedOptions(val => {
        const newVal = amount;
        const newDict = Object.assign(JSON.parse(JSON.stringify(val)), {[optionId]: newVal});
        if (newVal === 0) {
            delete newDict[optionId];
        }
        return newDict;
    })
}

export const deleteOptionCountImpl = (optionId, setSelectedOptions) => {
    setSelectedOptions(val => {
        const newDict = JSON.parse(JSON.stringify(val));
        if (newDict.hasOwnProperty(optionId)) {
            delete newDict[optionId];
        }
        return newDict;
    })
}

// TODO: render error page
const ProductDetail = (props) => {
    const {ssrError, notFound, productData, creator} = props;
    const product = productData ? productData.product : null;
    const [productInfo, setProductInfo] = useState(product);
    const [myPriceInfo, setMyPriceInfo] = useState(productData ? productData.my_price_info : null);
    const [productDetail, setProductDetail] = useState(productData);
    const [relatedProductInfo, setRelatedProductInfo] = useState(null);
    const bestReviews = productData ? productData.photo_reviews : [];
    const defaultSelectedOption = product && product.options.length === 1 ? {[product.options[0].id]: 1} : {};
    const [imageExpanded, setImageExpanded] = useState(false);
    const [currentTab, setCurrentTab] = useState(ROUTE_INFO);
    const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOption);
    const [totalInquiryCount, setTotalInquiryCount] = useState(0);

    const topBarId = 'stickyTopBar';

    const infoSectionRef = useRef(null);
    const reviewSectionRef = useRef(null);
    const inquirySectionRef = useRef(null);
    const additionalSectionRef = useRef(null);

    const router = useRouter();

    const scrollMargin = getPixelValueFromString(commonStyles.scrollMarginMobile);

    const isScrollBelowElement = e => {
        // +1 at the end is a safe buffer since scrollY sometimes gives float value
        return getOffsetTop(e) <= Math.round(window.scrollY + scrollMargin + 1);
    }

    const getCurrentRoute = (target) => {
        let route;
        if (additionalSectionRef.current && isScrollBelowElement(additionalSectionRef.current)) {
            route = ROUTE_ADDITIONAL;
        } else if (inquirySectionRef.current && isScrollBelowElement(inquirySectionRef.current)) {
            route = ROUTE_INQUIRY;
        } else if (reviewSectionRef.current && isScrollBelowElement(reviewSectionRef.current)) {
            route = ROUTE_REVIEW;
        } else if (infoSectionRef.current && isScrollBelowElement(infoSectionRef.current)) {
            route = ROUTE_INFO;
        } else {
            route = ROUTE_INFO;
        }
        return route;
    }

    const addOptionCount = (optionId, amount) => {
        addOptionCountImpl(optionId, amount, setSelectedOptions);
    }

    const deleteOptionCount = (optionId) => {
        deleteOptionCountImpl(optionId, setSelectedOptions);
    }

    const setOptionCount = (optionId, amount) => {
        setOptionCountImpl(optionId, amount, setSelectedOptions);
    }

    const resetSelectedOptions = () => {
        setSelectedOptions(defaultSelectedOption);
    }

    const pid = router.query.pid;
    const oid = router.query.oid ? parseInt(router.query.oid) : null;
    const isBot = router.query.is_bot;

    const ccode = router.query.ccode;
    const [referralId, referralPostId] = getReferralInfo(product?.id || null);
    const referralInfo = !!ccode ? [ccode, referralPostId] : (product ? [referralId, referralPostId] : [null, null]);

    useEffect(async () => {
        if (product) {
            const categoryString = product.category.query_string;
            const data = {
                content_ids: router.query.pid || 0,
                content_name: product.name,
                content_category: categoryString,
                content_type: 'ProductDetail',
                currency: 'KRW',
                value: product.discounted_price,
            };
            fetchProduct();
            trackPixel('ViewContent', data);
            typeof mixpanel !== 'undefined' && mixpanel.track('ViewProductDetail', data);
            try {
                typeof window !== 'undefined' && Boolean(window.karrotPixel) && window.karrotPixel.track('ViewContent', {'id': product.id});
            } catch {}
        }
    }, []);

    useEffect(() => {
        if (!!product?.migrated_product_option_id) {
            router.replace({
                query: Object.assign({}, router.query, {tc_option_id: product.migrated_product_option_id}),
            }, undefined, {shallow: true});
        }
    }, []);


    useEffect(async () => {
            const [referralId, referralPostId] = referralInfo;
            const params = isBot ? { is_bot: true} : {referral_id:referralId, referral_post_id: referralPostId}
            if (oid) {
                params['option_id'] = oid
            }

            try {
                const res = await Axios.get(`v2/store/products/${pid}/related-products`)

                if (res.status < 400) {
                    const relatedProductInfo = res.data.related_product_info
                    const shouldShuffle = relatedProductInfo.related_products_should_shuffle;
                    setRelatedProductInfo({
                        ...relatedProductInfo,
                        related_products: (
                            shouldShuffle ? shuffle(relatedProductInfo.related_products) : relatedProductInfo.related_products
                        ),
                    });
                } else {
                    captureMessage(JSON.stringify(res.data));
                }
            } catch (e) {
                captureException(e);
        }
    }, [])

    const fetchProduct = async () => {
        // To record product view
        const [referralId, referralPostId] = referralInfo;
        const params = isBot ? {
            is_bot: true,
        } : {
            referral_id: referralId,
            referral_post_id: referralPostId,
        };
        if (oid) {
            params['option_id'] = oid;
        }
        try {
            const res = await Axios.get(`v2/store/products/${pid}`, {
                params: params,
                cache: defaultCacheOption,
            });
            if (res.status < 400) {
                setProductInfo(res.data.product);
                setMyPriceInfo(res.data.my_price_info);
                setProductDetail(res.data);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        }
    };

    const sideBarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (sideBarRef.current && window.innerWidth >= 1024) {
                const sectionHeight = window.innerHeight - getPixelValueFromString(utilStyles.topBarHeight) - getPixelValueFromString(utilStyles.topTabBarHeight);
                const sideBarHeight = sectionHeight - 160;
                sideBarRef.current.style.maxHeight = getPixelStringFromInt(sideBarHeight);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [sideBarRef.current]);

    useEffect(() => {
        const elem = document.getElementById(topBarId);

        const handleScroll = (e) => {
            const route = getCurrentRoute(e.target);
            if (route !== currentTab) {
                setCurrentTab(route);
            }
        };

        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        }
    }, [currentTab]);


    const basePrice = product ? product.discounted_price : 0;

    const brandNames = product ? [
        product.brand_name
    ] : [];

    if (
        product && product.ownership_brands &&
        product.ownership_brands.some((x) => x.english_name && x.name !== x.english_name)
    ) {
        brandNames.push(
            product.ownership_brands
                ? product.ownership_brands.map((x, i) => x.english_name).join(" X ")
                : ""
        );
    }

    const brandName = brandNames.join(' / ');
    let metaTitle = product ? `[${brandName}] ${product.name}` : '';
    if (product && product.migrated_product_option_id && product.options.filter(e => e.id === product.migrated_product_option_id).length > 0) {
        const migratedOption = product.options.filter(e => e.id === product.migrated_product_option_id)[0];
        metaTitle = `[${brandName}] ${product.name} ${migratedOption.name}`
    }
    metaTitle = `${metaTitle} - 하나만 사도 어디로든 무료배송`;

    const isDirectPurchase = productInfo?.is_direct_web_purchase_product || hasValidDirectPurchaseInfo(product?.id);

    const [isSellerShopAllowed, setIsSellerShopAllowed] = useState(false);

    const onProductDetailTabClick = (tab) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailTabClick', {
            tab,
        });
    };

    const [reviewPermission, setReviewPermission] = useState(null);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [fetchReviewSignal, setFetchReviewSignal] = useState(0);
    const user = useContext(UserContext).user;

    useEffect(() => {
        if (!!user) {
            fetchReviewPermission();
        }
    }, [user]);

    const fetchReviewPermission = async () => {
        try {
            const res = await Axios.get(`v1/store/products/${product.id}/review-permission/`);
            if (res.status < 400) {
                setReviewPermission(res.data.review_permission);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        }
    };

    const handleReviewWriteSuccessConfirm = () => {
        setIsReviewModalOpen(false);
        fetchReviewPermission();
        fetchProduct();
        setFetchReviewSignal(prev => prev + 1);
    };

    const openReviewModal = () => {
        if (reviewPermission) {
            setIsReviewModalOpen(true);
        } else {
            customAlert('구매 후 리뷰를 작성할 수 있습니다!', '확인', () => {});
        }
    };

    return (
        <>
            <Head>
                <link rel="canonical" href={`${SITE_URL}/store/products/${router.query.pid || 0}`} />
                <meta name="description" content="하나만 사도 어디로든 무료배송" key="description" />
                <title>{metaTitle}</title>
                <meta property="og:type" content="product" key="ogType" />
                <meta property="og:title" content={metaTitle} key="ogTitle" />
                <meta property="og:description" content="하나만 사도 어디로든 무료배송" key="ogDescription" />
                {
                    product &&
                    <meta property="og:image" content={product.image_url} key="ogImage" />
                }

                <link rel="icon" href="/favicon.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
            </Head>
            <ul itemScope itemType="http://schema.org/ItemList">
                {
                    product && <meta itemProp="numberOfItems" content={product.options.length} />
                }
                {
                    product && product.options.map(option => {
                        return (
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/Product" key={option.id}>
                                <meta itemProp="brand" content={brandName} />
                                <meta itemProp="name" content={option.name} />
                                <meta itemProp="description" content={option.name} />
                                <meta itemProp="productID" content={option.id} />
                                <meta itemProp="url" content={`https://www.hemekolab.com/store/products/${router.query.pid || 0}`} />
                                <meta itemProp="image" content={product.image_url} />
                                <div itemProp="value" itemScope itemType="http://schema.org/PropertyValue">
                                    <span itemProp="propertyID" content="item_group_id"></span>
                                    <meta itemProp="value" content={product.id}></meta>
                                </div>
                                {
                                    ![383, 389, 387, 2849, 2847, 2848, 2850, 386, 390].includes(option.id) &&
                                    <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                        <link itemProp="availability" href="http://schema.org/InStock" />
                                        <link itemProp="itemCondition" href="http://schema.org/NewCondition" />
                                        <meta itemProp="price" content={`${option.discounted_price}`} />
                                        <meta itemProp="priceCurrency" content="KRW" />
                                    </div>
                                }
                            </li>
                        )
                    })
                }
            </ul>
            {
                (notFound || ssrError) &&
                <div style={{position: 'relative', paddingTop: 16}} className={utilStyles.pageContainer}>
                    <div className={styles.errorContainer}>
                        <span className={styles.errorText}>
                        {
                            notFound
                                ? "존재하지 않는 제품입니다."
                                : "제품 정보를 불러오는데 실패하였습니다."
                        }
                        </span>
                    </div>
                </div>
            }
            {
                reviewPermission &&
                <ReviewModal
                    isOpen={isReviewModalOpen}
                    close={() => {
                        setIsReviewModalOpen(false);
                    }}
                    imageUrl={product.image_url}
                    orderOptionId={null}
                    optionId={reviewPermission.option_id}
                    permissionId={reviewPermission.permission_id}
                    brandName={reviewPermission.brand_name}
                    optionName={reviewPermission.option_name}
                    productName={reviewPermission.product_name}
                    resetVariable={isReviewModalOpen}
                    onSuccess={() => {
                        setIsReviewModalOpen(false);
                    }}
                    handleSuccessModalClose={handleReviewWriteSuccessConfirm}
                />
            }
            {
                product &&
                <div style={{position: 'relative',}}>
                    {
                        creator?.nickname ?
                            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                                <TitleBar isBack close={() => router.back()} renderRightButton={() => {
                                    return (
                                        <Link href={getHomeRoute()}>
                                            <a>
                                                <HomeIcon viewBox="0 0 24 24" className={classNames(styles.homeIcon)} />
                                            </a>
                                        </Link>
                                    );
                                }}>
                                    {
                                        creator.nickname.toUpperCase().split(/(_+)/).map((e, idx) => {
                                            if (e.includes('_')) {
                                                return <span style={{
                                                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,\n' +
                                                        '  Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                                                }} key={idx}>{e}</span>
                                            } else {
                                                return e;
                                            }
                                        })
                                    }
                                </TitleBar>
                            </div>
                            :
                            <TopBar isBack home search pouch />
                    }
                    {/*<div style={{height: 16}} />*/}
                    {/*<BreadCrumb />*/}
                    <ProductDetailCard
                        setIsSellerShopAllowed={setIsSellerShopAllowed}
                        optionSelectorType={1}
                        resetSelectedOptions={resetSelectedOptions}
                        selectedOptions={selectedOptions}
                        addOptionCount={addOptionCount}
                        deleteOptionCount={deleteOptionCount}
                        setOptionCount={setOptionCount}
                        product={productInfo}
                        promotionEndsAt={productData.promotion_ends_at}
                        bestReviews={bestReviews}
                        myPriceInfo={isDirectPurchase ? null : myPriceInfo}
                        onReviewClick={() => {
                            if (!!reviewSectionRef.current) {
                                reviewSectionRef.current.scrollIntoView();
                            }
                        }}
                        referralInfo={referralInfo}
                        relatedProductInfo={relatedProductInfo}
                        openReviewModal={openReviewModal}
                        downloadableCouponMolds={productDetail.downloadable_coupon_molds}
                        refetchProductDetail={fetchProduct}
                        optionId={oid}
                        isOpenNotiSubscribed={productData.is_open_noti_subscribed}
                    />
                    <div className={styles.productDetailBorder} />
                    <div className={utilStyles.mobileBorder} />
                    <div id={topBarId} className={classNames(utilStyles.flexRow, styles.topBarContainer)}>
                        <div className={classNames(styles.topBarWrapper)}>
                            <TopTabBar
                                activeRoute={currentTab}
                                tabs={[
                                    {route: ROUTE_INFO, title: '제품정보', onClick: () => {
                                            onProductDetailTabClick('상품정보');
                                            if (!!infoSectionRef.current) {
                                                infoSectionRef.current.scrollIntoView();
                                            }
                                        }},
                                    {route: ROUTE_REVIEW, title: '리뷰', subTitle: `(${numberWithComma(productInfo ? productInfo.review_count + productInfo.mall_review_count : 0)})`, onClick: () => {
                                            if (!!reviewSectionRef.current) {
                                                reviewSectionRef.current.scrollIntoView();
                                            }
                                        },
                                        renderChild: () => {
                                            return <div className={styles.firstReviewPointBubbleContainer}>
                                                {
                                                    productInfo.review_count + productInfo.mall_review_count === 0 &&
                                                    <SpeechBubble
                                                        backgroundColor={'#191919'}
                                                        sidePadding={12} verticalPadding={6}
                                                        pointLength={5}
                                                        top
                                                    >
                                                        <span className={styles.firstReviewPointBubbleText}>첫 리뷰 쓰면 포인트 2배</span>
                                                    </SpeechBubble>
                                                }
                                            </div>
                                        }},
                                    {route: ROUTE_INQUIRY, title: '문의', subTitle: `(${totalInquiryCount})`, onClick: () => {
                                            onProductDetailTabClick('문의');
                                            if (!!inquirySectionRef.current) {
                                                inquirySectionRef.current.scrollIntoView();
                                            }
                                        }},
                                    {route: ROUTE_ADDITIONAL, title: '구매정보', onClick: () => {
                                            onProductDetailTabClick('구매정보');
                                            if (!!additionalSectionRef.current) {
                                                additionalSectionRef.current.scrollIntoView();
                                            }
                                        }}]}
                            />
                        </div>
                        <div className={styles.topBarMargin}></div>
                    </div>
                    <ProductInfoSection
                        product={productInfo}
                        ref={infoSectionRef} isExpanded={imageExpanded}
                        toggleExpanded={() => setImageExpanded(val => !val)}/>
                    <div className={utilStyles.mobileBorder}/>
                    <ProductReviewSection
                        ref={reviewSectionRef} product={productInfo} openReviewModal={openReviewModal}
                        fetchReviewSignal={fetchReviewSignal}/>
                    <div className={styles.sectionBorder}/>
                    <div className={utilStyles.mobileBorder}/>
                    <ProductInquirySection ref={inquirySectionRef} product={productInfo}
                                           totalInquiryCount={totalInquiryCount}
                                           setTotalInquiryCount={setTotalInquiryCount}/>
                    <div className={styles.sectionBorder}/>
                    <div className={utilStyles.mobileBorder}/>
                    <ProductAdditionalInfoSection ref={additionalSectionRef} product={productInfo}/>
                    <div className={styles.sectionBorder}/>
                    <PurchaseButton
                        isSellerShopAllowed={isSellerShopAllowed}
                        resetSelectedOptions={resetSelectedOptions}
                        basePrice={basePrice}
                        product={productInfo}
                        selectedOptions={selectedOptions}
                        addOptionCount={addOptionCount}
                        setOptionCount={setOptionCount}
                        deleteOptionCount={deleteOptionCount}
                        referralInfo={referralInfo}
                    />
                </div>
            }
        </>
    )
};

export default ProductDetail;
