import styles from "components/review/scoreStatistics.module.scss";
import {numberWithComma} from "common/utils";
import {Axios} from "api";
import {useEffect, useState} from "react";
import {captureMessage, captureException} from "@sentry/nextjs";


const ScoreStatistics = ({pid}) => {
    const [reviewStats, setReviewStats] = useState({
        score_to_count: {1: 0, 2: 0, 3: 0, 4: 0, 5: 0},
        total_count: 0,
    });

    const fetchReviewStatistics = async () => {
        try {
            const res = await Axios.get(`v1/store/products/${pid}/review-statistics/`);
            if (res.status < 400) {
                setReviewStats(res.data);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        } finally {
        }
    };

    useEffect(async () => {
        fetchReviewStatistics();
    }, []);

    return (
        <div>
            {
                Array.from({length: 5}, (_, i) => i + 1).reverse().map(x => {
                    return <div className={styles.scoreCountRow} key={`review-score-${pid}-${x}`}>
                        <div className={styles.scoreCountRowScoreContainer}>
                            <span className={styles.scoreCountRowScore}>
                                {x}
                            </span>
                            <span className={styles.scoreCountRowScore}>
                                점
                            </span>
                        </div>
                        <div className={styles.backgroundRow}>
                            <div className={styles.scoreCountPercentage}
                                 style={{width: 100 * reviewStats.score_to_count[x] / (reviewStats.total_count > 0 ? reviewStats.total_count : 1)}}/>
                        </div>
                        <span
                            className={styles.scoreCountRowCount}>{numberWithComma(reviewStats.score_to_count[x])}</span>
                    </div>
                })
            }
        </div>
    )
};

export default ScoreStatistics;
