import BaseOrderOptionCard, {ActionButton, AddToPouchButton} from "components/cards/order/BaseOrderOptionCard";
import {CancelDetailButton, CancelOrderButton} from "components/cards/order/OrderOptionCard";
import Link from "next/link";
import {getCancelOrderRoute, storeRoute} from "common/const";
import styles from "components/cards/order/baseOrderOptionCard.module.scss";
import {useContext} from "react";
import ModalContext from "context/ModalContext";
import ShareGiftLinkModal from "components/sections/checkout/ShareGiftLinkModal";


export const ShareGiftLinkButton = ({openShareModal}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderGiftCardShareGiftLinkButtonClick',
        );
        openShareModal();
    };

    return (
        <a className={styles.buttonWrapper} onClick={onClick}>
            <ActionButton>링크 공유하기</ActionButton>
        </a>
    )
};


export const GiftOtherProductButton = ({orderNumber, optionId}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderGiftCardShareGiftLinkButtonClick',
        );
    };
    return (
        <Link href={storeRoute}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>다른 제품 선물하기</ActionButton>
            </a>
        </Link>
    )
};


const GetButtons = (orderNumber, gift, openShareModal) => {

    switch (gift.status) {
        case '입금대기':
            return (
                <CancelOrderButton orderNumber={orderNumber} optionId={gift.order_option_id}/>
            );
        case '선물대기':
            return (
                <>
                    <CancelOrderButton
                        orderNumber={orderNumber}
                        optionId={gift.order_option_id}
                    />
                    <ShareGiftLinkButton
                        orderNumber={orderNumber}
                        optionId={gift.order_option_id}
                        openShareModal={openShareModal}
                    />
                </>
            );
        case '선물거절':
            return (
                <>
                    <GiftOtherProductButton orderNumber={orderNumber}/>
                </>
            );
        case '선물취소':
        case '선물 배송지 입력 기한 만료':
            return (
                <>
                    <CancelDetailButton orderNumber={orderNumber} optionId={gift.order_option_id} />
                    <AddToPouchButton orderOption={gift}/>
                </>
            )
        default:
            return <></>;
    }
}


export default function OrderGiftCard(props) {
    const {orderNumber, gift, noButton} = props;

    const {openModal, closeModal} = useContext(ModalContext);

    const openShareModal = () => {
        openModal(() => {
            return <ShareGiftLinkModal
                isOpen={true}
                close={closeModal}
                order_number={orderNumber}
                orderer_name={gift.orderer_name}
                imageUrl={gift.product_image_url}
                expiresAt={gift.expires_at}
            />
        });
    };

    return (
        <BaseOrderOptionCard
            productOverrideLink={gift.product_override_link}
            brandName={gift.brand_name}
            count={gift.count}
            productId={gift.product_id}
            productName={gift.product_name}
            status={gift.status}
            imageUrl={gift.product_image_url}
            optionName={gift.option_name}
            price={gift.unit_sell_price}
            disableLink={props.disableLink}
            giftReceiverName={gift.gift_receiver_name}
        >
            {
                gift.status !== '선물완료' &&
                <>
                    {
                        GetButtons(orderNumber, gift, openShareModal)
                    }
                </>
            }
        </BaseOrderOptionCard>
    )
}
