import NotificationIcon from "images/icons/notification.svg";
import CheckIcon from "images/icons/check_grey.svg";
import classNames from "classnames";
import styles from "components/buttons/SubscriptionButton.module.scss";


export const SubscriptionButton = (props) => {
    const {isSubscribed, onClick} = props;

    const handleOnClick = (e) => {
        if (onClick) {
            onClick();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <button
            className={classNames(styles.buttonContainer, isSubscribed && styles.subscribedButtonContainer)}
            onClick={handleOnClick}
        >
            {
                isSubscribed ?
                    <CheckIcon/> :
                    <NotificationIcon viewBox="0 0 24 24" className={classNames(styles.icon)}/>
            }
            <span className={classNames(styles.text, isSubscribed && styles.subscribedText)}>
                {isSubscribed ? "신청완료" : "알림신청"}
            </span>
        </button>
    )
};


export const ProductOpenSubscriptionButton = (props) => {
    const {isSubscribed, onClick} = props;

    const handleOnClick = (e) => {
        if (onClick) {
            onClick();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <button
            className={classNames(styles.productOpenButtonContainer, isSubscribed && styles.subscribedButtonContainer)}
            onClick={handleOnClick}
        >
            {
                isSubscribed ?
                    <CheckIcon/> :
                    <NotificationIcon viewBox="0 0 24 24" className={classNames(styles.icon)}/>
            }
            <span className={classNames(styles.productOpenText, isSubscribed && styles.subscribedText)}>
                {isSubscribed ? "알림 신청 완료" : "오픈 알림 신청"}
            </span>
        </button>
    )
};
