import ErrorIcon from "images/icons/error.svg";
import styles from "pages/404.module.scss";
import NavigationLayout from "components/layout/NavigationLayout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import utilStyles from "styles/utils.module.scss";

export const ErrorMessage = (props) => {
    const {padding, titleFontSize, descriptionFontSize, titleMargin, descriptionMargin} = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const iconSize = props.iconSize || (isLarge ? 80 : 60);
    return (
        <div className={styles.container} style={{padding: padding}}>
            <ErrorIcon width={iconSize} height={iconSize} viewBox="0 0 80 80" className={styles.icon}/>
            <span className={styles.title} style={{fontSize: titleFontSize, marginTop: titleMargin}}>불러오는 데 실패했습니다!</span>
            <span className={styles.description} style={{fontSize: descriptionFontSize, marginTop: descriptionMargin}}>일시적인 장애 혹은 네트워크 문제일 수 있습니다.<br/>잠시 후 다시 이용해 주세요.</span>
            { !isLarge && <div style={{height: 70}} /> }
        </div>
    )
}

const Custom500 = () => {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    return (
            <ErrorMessage padding={100}/>
    )
}


Custom500.getLayout = NavigationLayout;
export default Custom500;