import {useContext, useEffect, useState} from "react";

import styles from 'pages/auth/sign-in.module.scss';
import Link from 'next/link'
import utilStyles from 'styles/utils.module.scss'
import KakaoLoginButton, {promptKakaoLogin} from "components/buttons/KakaoLoginButton";
import NaverLoginButton, {promptNaverLogin, REDIRECT_URI} from "components/buttons/NaverLoginButton";
import GoogleLoginButton, {promptGoogleLogin} from "components/buttons/GoogleLoginButton";
import {
    AUTH_TYPE_APPLE,
    AUTH_TYPE_EMAIL,
    AUTH_TYPE_GOOGLE,
    AUTH_TYPE_KAKAO,
    AUTH_TYPE_NAVER,
    findEmailRoute,
    findPasswordRoute, getHomeRoute,
    nonMemberOrderSearchRoute,
    signUpRoute,
    snsSignUpRoute,
    storeRoute
} from "common/const";
import {useRouter} from "next/router";

import {Axios} from "api";
import UserContext from "context/AuthContext";
import Logo from "images/logo.svg";
import TextInput from "components/input/TextInput";
import FullButton from "components/buttons/FullButton";
import classNames from "classnames";
import {useFormik} from "formik";
import * as yup from "yup";
import InputError from "components/input/InputError";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AuthLayout from "components/layout/AuthLayout";
import {captureException} from "@sentry/nextjs";
import {customConfirm, isApp, isIOS} from "common/utils";
import HemekolabLogo from "images/icons/hemekolab_logo.svg";
import SpeechBubble from "components/SpeechBubble";
import authStyles from "pages/auth/index.module.scss";
import AppleLoginButton from "components/buttons/AppleLoginButton";


import KakaoLogo from "images/icons/kakao.svg";
import NaverLogo from "images/icons/naver.svg";
import GoogleLogo from "images/icons/google.svg";
import EmailLogo from "images/icons/email_circle.svg";
import Modal from "components/Modal";


export const authTypeToPrompt = {
    [AUTH_TYPE_NAVER]: promptNaverLogin,
    [AUTH_TYPE_KAKAO]: promptKakaoLogin,
    [AUTH_TYPE_GOOGLE]: promptGoogleLogin,
}


export const authTypeToLogo = {
    [AUTH_TYPE_NAVER]: (props) => <NaverLogo {...props} viewBox="0 0 48 48" />,
    [AUTH_TYPE_KAKAO]: (props) => <KakaoLogo {...props} viewBox="0 0 48 48" />,
    [AUTH_TYPE_GOOGLE]: (props) => <GoogleLogo {...props} viewBox="0 0 48 48" />,
    [AUTH_TYPE_EMAIL]: (props) => <EmailLogo {...props} viewBox="0 0 24 24" />,
}

export const authTypeToKorean = {
    [AUTH_TYPE_NAVER]: '네이버',
    [AUTH_TYPE_EMAIL]: '이메일',
    [AUTH_TYPE_GOOGLE]: '구글',
    [AUTH_TYPE_KAKAO]: '카카오',
    [AUTH_TYPE_APPLE]: '애플',
}

const SeparatorText = ({children, barLength}) => {
    return (
        <div className={classNames(utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter)}>
            <div className={styles.separator} style={{width: barLength}} />
                <span className={styles.separatorContent}>{children}</span>
            <div className={styles.separator} style={{width: barLength}} />
        </div>
    );
}

export const LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE = 'last_login_type';

const SignIn = (props) => {
    const router = useRouter();
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const [isLoading, setIsLoading] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

    useEffect(() => {
        if (user) {
            const redirect = router.query.redirect;
            if (redirect) {
                router.replace(decodeURIComponent(redirect));
            } else {
                router.replace(getHomeRoute());
            }
        }
    }, [user]);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            email: '',
            password: '',
            auto_login: true,
        },
        validationSchema: yup.object({
            email: yup.string().required('이메일을 입력해주세요.').email('이메일을 정확하게 입력해주세요.'),
            password: yup.string().required('비밀번호를 입력해주세요.').min(7, '비밀번호는 8자 이상입니다.').max(16, '비밀번호는 16자 이하입니다.'), //TODO: 8자로 변경
            auto_login: yup.boolean().required(),
        }),
        onSubmit: values => {
            const data = {email: values.email, password: values.password, auto_login: values.auto_login, auth_type: AUTH_TYPE_EMAIL};
            signIn(data, router.query.redirect);
        },
    });

    const {values, setFieldValue, errors, touched, handleSubmit, setFieldError, setErrors} = formik;

    const snsLogin = (auth_type, accessToken, redirect) => {
        const data = {auth_type, token: accessToken};
        console.log(data);
        signIn(data, redirect);
    }

    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const withForm = !!router.query.withForm;

    const [lastLoginType, setLastLoginType] = useState(null);

    const signIn = async (data, redirect) => {
        setIsLoading(true);
        try {
            const res = await Axios.post('v1/auth/sign-in/', data);
            if (res.status < 400) {
                try {
                    window.localStorage.setItem(LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE, res.data.auth_type);
                } catch {}
                try {
                    typeof window !== 'undefined' && Boolean(window.karrotPixel) && window.karrotPixel.track('Login');
                } catch {}
                userContext.setUser(res.data);
                if (!res.data.nickname) {
                    router.replace({
                        pathname: snsSignUpRoute,
                        query: router.query,
                    });
                } else if (redirect) {
                    router.replace(decodeURIComponent(redirect));
                } else {
                    router.replace(getHomeRoute());
                }
            } else if (res.status === 403) {
                setErrors({
                    email: '아이디 또는 비밀번호가 일치하지 않습니다.',
                    password: '아이디 또는 비밀번호가 일치하지 않습니다.',
                });
            } else if (res.status === 404) {
                if (data.auth_type === AUTH_TYPE_EMAIL) {
                    setErrors({
                        email: '아이디 또는 비밀번호가 일치하지 않습니다.',
                        password: '아이디 또는 비밀번호가 일치하지 않습니다.',
                    });
                } else {
                    snsRegister(data.auth_type, data.token, redirect);
                }
            } else if (res.status === 409) {
                const existingAuthType = res.data.extra.auth_type;
                if (existingAuthType === AUTH_TYPE_EMAIL) {
                    alert('이메일로 가입된 회원입니다. 이메일 로그인을 이용해주세요.');
                } else {
                    customConfirm(
                        `${authTypeToKorean[existingAuthType]} SNS 계정으로 가입된 회원입니다. ${authTypeToKorean[existingAuthType]} SNS 계정으로 로그인하시겠습니까?`,
                        '확인',
                        '취소',
                        () => {
                            const prompt = authTypeToPrompt[existingAuthType];
                            if (prompt) prompt(redirect, false);
                        },
                        () => {},
                    );
                }
            } else {
                alert('로그인에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            alert('로그인에 실패했습니다. 잠시 후 다시 시도해주세요.');
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const snsRegister = async (authType, token, redirect) => {
        const data = {auth_type: authType, token: token}
        setIsLoading(true);
        try {
            const res = await Axios.get('v1/auth/sns-sign-up/', {params: data});
            if (res.status < 400) {
                userContext.setUser(res.data);
                if (redirect) {
                    router.replace(decodeURIComponent(redirect));
                } else {
                    router.replace(getHomeRoute());
                }
            } else if (res.status === 404) {
                router.replace({
                    pathname: snsSignUpRoute,
                    query: Object.assign({}, router.query,{auth_type: authType, token: token}),
                });
            } else if (res.status === 409) {
                const existingAuthType = res.data.extra.auth_type;
                if (existingAuthType === AUTH_TYPE_EMAIL) {
                    alert('이메일로 이미 가입된 회원입니다. 이메일 로그인을 이용해주세요.');
                } else {
                    customConfirm(
                        `${authTypeToKorean[existingAuthType]} SNS 계정으로 이미 가입된 회원입니다. ${authTypeToKorean[existingAuthType]} SNS 계정으로 로그인하시겠습니까?`,
                        '삭제하기',
                        '취소',
                        () => {
                            const prompt = authTypeToPrompt[existingAuthType];
                            if (prompt) prompt(router.query.redirect, false);
                        },
                        () => {},
                    );
                }
            } else {
                alert('일시적인 오류로 회원가입에 실패했습니다.');
            }
        } catch (e) {
            alert('일시적인 오류로 회원가입에 실패했습니다.');
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const lastLoginType = window.localStorage.getItem(LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE);
        setLastLoginType(lastLoginType);
    }, []);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let hashString =  window.location.hash;

        const authType = urlSearchParams.get('auth_type');
        const isSignUp = urlSearchParams.get('is_sign_up');
        let redirect = urlSearchParams.get('redirect') || window.localStorage.getItem(REDIRECT_URI);
        if (redirect === 'undefined') {
            redirect = null;
        }
        setTimeout(() => {
            const query = {};
            console.log(authType, isSignUp);
            if (authType) {
                query['auth_type'] = authType;
            }
            if (isSignUp) {
                query['is_sign_up'] = isSignUp;
            }
            if (redirect && redirect !== 'undefined') {
                query['redirect'] = redirect;
            }
            if (authType || isSignUp) {
                router.replace({query: query});
            }
        }, 100);
        if (hashString.startsWith('#')) {
            hashString = hashString.substring(1);
        }
        const hashParams = new URLSearchParams(hashString);

        let token = null;
        let oldState = null;
        let state = null;
        if (authType === AUTH_TYPE_NAVER) {
            token = hashParams.get('access_token');
            state = hashParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_NAVER);
        } else if (authType === AUTH_TYPE_KAKAO) {
            token  = urlSearchParams.get('code');
            state = urlSearchParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_KAKAO);
        } else if (authType === AUTH_TYPE_GOOGLE) {
            token = hashParams.get('id_token');
            state = hashParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_GOOGLE);
        } else if (authType === AUTH_TYPE_APPLE) {
            token = hashParams.get('id_token');
            state = hashParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_APPLE);
        }

        if (state && token && oldState && state === oldState) {
            if (isSignUp) {
                snsRegister(authType, token, redirect);
            } else {
                snsLogin(authType, token, redirect);
            }
        }

    }, []);

    let redirectUrl = '';
    if (typeof window !== 'undefined') {
        redirectUrl = decodeURIComponent((new URLSearchParams(window.location.search)).get('redirect') || window.localStorage.getItem(REDIRECT_URI));
    }

    const isFromPurchase = redirectUrl.includes('/checkout');

    const onNonMemberLinkClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SignInPageNonMemberLinkClick', {isFromPurchase});
    };

    const onLogoClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SignInPageLogoClick', {isFromPurchase});
    }

    const preventGoBack = router.query.preventGoBack === 'true';

    const isIOSApp = isApp() && isIOS();

    const signupRoute = typeof window !== 'undefined' ? `${signUpRoute}${window.location.search}` : signUpRoute;

    const [isOtherLoginModalOpen, setIsOtherLoginModalOpen] = useState(false);

    return (
        withForm ?
        <div className={styles.root}>
            <div className={styles.container}>
                <Link href={getHomeRoute()}>
                    <a onClick={onLogoClick}>
                        <Logo className={styles.logo} viewBox="0 0 157 28" width={157} height={28}/>
                    </a>
                </Link>
                <div className={styles.inputWrapper}>
                    <TextInput onEnter={handleSubmit} readonly={isLoading} error={errors.email && touched.email} value={values.email} onChange={e => setFieldValue('email', e)} type="email" placeholder="이메일을 입력해주세요"/>
                </div>
                <div className={styles.inputWrapper}>
                    <TextInput onEnter={handleSubmit} readonly={isLoading} error={errors.password && touched.password} value={values.password} onChange={e => setFieldValue('password', e)} type="password" placeholder="비밀번호를 입력해주세요"/>
                </div>
                { (errors.email || errors.password) && touched.email && <InputError message={errors.email || errors.password} />}
                <div className={styles.buttonWrapper}>
                    <FullButton disabled={isLoading} title="로그인" height={48} fontSize={18} onClick={handleSubmit}/>
                </div>
                <div className={
                    classNames(utilStyles.fullWidth)
                }>
                    {/*<div className={classNames(utilStyles.noLineHeight, utilStyles.cursorPointer)} onClick={() => setFieldValue('auto_login', !values.auto_login)}>*/}
                    {/*    {*/}
                    {/*        values.auto_login ?*/}
                    {/*            <CheckboxIcon viewBox="0 0 24 24" className={styles.checkbox}/>*/}
                    {/*            :*/}
                    {/*            <UncheckedCheckboxIcon viewBox="0 0 24 24" className={styles.checkbox}/>*/}
                    {/*    }*/}
                    {/*    <span className={styles.autoLogin}>자동로그인</span>*/}
                    {/*</div>*/}
                    <div className={classNames(utilStyles.noLineHeight, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}>
                        <Link href={findEmailRoute}>
                            <a className={styles.find}>아이디 찾기</a>
                        </Link>
                        <div className={styles.verticalSeparator} />
                        <Link href={findPasswordRoute}>
                            <a className={styles.find}>비밀번호 찾기</a>
                        </Link>
                        <div className={styles.verticalSeparator} />
                        <Link href={signupRoute}>
                            <a className={styles.find}>이메일 가입</a>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
        :
        <div className={styles.rootWithoutForm}>
            <Modal width={'100%'} isOpen={isOtherLoginModalOpen} bottom close={() => setIsOtherLoginModalOpen(false)}>
                <div className={styles.otherModalContainer}>
                    <div className={styles.snsWrapperWithoutForm}>
                        <NaverLoginButton full lastLoginType={lastLoginType} disabled={isLoading} />
                    </div>
                    <div className={styles.snsWrapperWithoutForm}>
                        <GoogleLoginButton full lastLoginType={lastLoginType} disabled={isLoading} />
                    </div>
                    <div className={styles.emailButtonWithoutForm}>
                        {
                            lastLoginType === AUTH_TYPE_EMAIL &&
                            <div className={authStyles.lastLoginBubble} style={{top: -20}}>
                                <SpeechBubble
                                    sidePadding={12} verticalPadding={6}
                                    pointLength={5} borderRadius={9999}
                                    bottom
                                    backgroundColor={utilStyles.brand}
                                >
                            <span className={authStyles.lastLoginBubbleText}>
                                최근 로그인
                            </span>
                                </SpeechBubble>
                            </div>
                        }
                        <FullButton nonBold borderRadius={9999} onClick={() => {
                            router.push({query: Object.assign({}, router.query, {withForm: true})}, undefined, {shallow: true});
                        }} height={48} fontSize={14} white>이메일로 계속하기</FullButton>
                    </div>
                </div>
            </Modal>
            <div className={styles.containerWithoutForm}>
                {
                    !!preventGoBack &&
                    <Link href={getHomeRoute()}>
                        {/*<Link href={isFromPurchase ? redirectUrl : getHomeRoute()}>*/}
                        <a className={styles.lookAround}>{'둘러보기'}</a>
                    </Link>
                }
                <div className={styles.titleWrapperWithoutForm}>
                    <div className={styles.titleWithoutForm}>헤메코랩은 하나만 사도<br/>국내 어디로든 <span className={styles.titleWithoutFormBold}>언제나 무료배송</span></div>
                    <div className={styles.subTitleWithoutForm}>3초만에 로그인하고 혜택 받으세요</div>
                </div>
                <div className={styles.buttonSectionWithoutForm}>
                    <div className={styles.buttonsContainerWithoutForm}>
                        {
                            !lastLoginType &&
                            <div className={styles.bubbleWrapper}>
                                <SpeechBubble
                                    sidePadding={12} verticalPadding={6}
                                    pointLength={5} borderRadius={9999}
                                    bottom
                                    backgroundColor={utilStyles.brand}
                                >
                            <span className={styles.bubbleText}>
                                3초만에 가입하기
                            </span>
                                </SpeechBubble>
                            </div>
                        }
                        <div className={styles.snsWrapperWithoutForm}>
                            <KakaoLoginButton full lastLoginType={lastLoginType} disabled={isLoading} />
                        </div>
                        {
                            isIOSApp &&
                            <div className={styles.snsWrapperWithoutForm}>
                                <AppleLoginButton full lastLoginType={lastLoginType} disabled={isLoading} />
                            </div>
                        }
                    </div>
                    <div className={styles.otherLoginWithoutForm} onClick={() => setIsOtherLoginModalOpen(true)}>
                        다른 방법으로 로그인할래요
                        {
                            (!!lastLoginType && lastLoginType !== AUTH_TYPE_KAKAO && lastLoginType !== AUTH_TYPE_APPLE) &&
                            <div className={authStyles.lastLoginBubble} style={{bottom: -36}}>
                                <SpeechBubble
                                    sidePadding={12} verticalPadding={6}
                                    pointLength={5} borderRadius={9999}
                                    top
                                    backgroundColor={utilStyles.brand}
                                >
                            <span className={authStyles.lastLoginBubbleText}>
                                최근 로그인
                            </span>
                                </SpeechBubble>
                            </div>
                        }
                    </div>
                    <Link href={isFromPurchase ? redirectUrl : nonMemberOrderSearchRoute}>
                        {/*<Link href={isFromPurchase ? redirectUrl : getHomeRoute()}>*/}
                        <a className={styles.goHomeButton} onClick={onNonMemberLinkClick}>{isFromPurchase ? '비회원 주문하기' : '비회원 주문조회'}</a>
                    </Link>
                </div>
                {/*<div className={classNames(utilStyles.fullFlex, styles.textButtonContainer)}>*/}
                {/*    <div className={utilStyles.flexRow} style={{paddingBottom: 32}}>*/}
                {/*        {*/}
                {/*            preventGoBack &&*/}
                {/*            <>*/}
                {/*                <div className={utilStyles.fullFlex}>*/}
                {/*                    <Link href={getHomeRoute()}>*/}
                {/*                        /!*<Link href={isFromPurchase ? redirectUrl : getHomeRoute()}>*!/*/}
                {/*                        <a className={styles.goHomeButton}>{'둘러보기'}</a>*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*                <div className={styles.separator2} />*/}
                {/*            </>*/}
                {/*        }*/}
                {/*        <div className={utilStyles.fullFlex}>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

SignIn.getLayout = AuthLayout;

export default SignIn;
