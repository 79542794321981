import utilStyles from "styles/utils.module.scss";
import styles from "components/input/optionSelector.module.scss";
import classNames from "classnames";
import CloseIcon from "images/icons/close.svg";
import ScrapIcon from "images/icons/heart_empty.svg";
import ScrapIconActive from "images/icons/heart_fill.svg";
import {checkHasConsonant, numberWithComma, shuffle} from "common/utils";
import {useCallback, useContext, useState, useMemo, useEffect, useRef} from "react";
import {Axios} from "api";
import {useRouter} from "next/router";
import {pouchRoute} from "common/const";
import OptionSelectionDropDown from "components/dropdown/OptionSelectionDropDown";
import UserContext from "context/AuthContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FullButton from "components/buttons/FullButton";
import Modal from "components/Modal";
import ProductScrapButton from "components/buttons/ProductScrapButton";
import addOptionCountsToPouch from "api/addToPouch";
import {captureException, captureMessage} from "@sentry/nextjs";
import {redirectToLogin} from "common/redirect";
import NewQuantitySelector from "components/buttons/NewQuantitySelector";
import PouchContext from "context/PouchContext";
import {toast} from "react-toastify";
import SaleIcon from "images/icons/sale.svg";
import LoadingDot from "components/LoadingDot";
import ABTestContext, {ABTEST_KEY_FORCE_POUCH, ABTEST_KEY_PALETTE_NAME} from "context/ABTestContext";
import OptionSelectorPouchAddedModal from "components/modal/OptionSelectorPouchAddedModal";
import {order} from "api/store/orders";
import {hasValidDirectPurchaseInfo} from "common/product";


const OptionText = (props) => {
    return <span className={styles.optionText}>옵션 : {props.name}</span>
}

export const OptionSelectorModeGift = 'gift';
export const OptionSelectorModePurchase = 'purchase';

export default function OptionSelector (props) {
    const {
        product, resetSelectedOptions, selectedOptions, addOptionCount,
        setOptionCount, deleteOptionCount, basePrice, small, buttonText, onAddToPouchSuccessOverride,
        mode,
    } = props;
    const referralInfo = props.referralInfo || [null, null];
    const options = product.leaf_options;
    const type = props.type || 1;
    const onPouchSuccess = props.onPouchSuccess || function () {};
    const router = useRouter();
    const [isExpanded, setIsExpanded] = useState(false);
    const {user, fetchUser} = useContext(UserContext);
    const pouchContext = useContext(PouchContext);
    const optionIdToOption = {};
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [isPouchModalOpen, setIsPouchModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleCancel = props.handleCancel || function (res) {};

    const isGiftMode = mode === OptionSelectorModeGift;

    const onOptionSelect = props.onOptionSelect || function () {};
    const onOptionDelete = props.onOptionDelete || function () {};
    const onOptionCountChange = props.onOptionCountChange || function () {};
    const onPurchase = props.onPurchase || function () {};

    options.map(e => {
        optionIdToOption[e.id] = e;
    })

    let isSoldOut = true;
    for (const option of options) {
        if (!option.is_sold_out) {
            isSoldOut = false;
        }
    }

    const numSelected = useMemo(() => {
        let num = 0;
        for (let option_id in selectedOptions) {
            num += selectedOptions[option_id];
        }
        return num;
    }, [selectedOptions]);

    const onAddToPouchSuccess = () => {
        onPouchSuccess();
        fetchUser();
        if (onAddToPouchSuccessOverride) {
            onAddToPouchSuccessOverride();
            return;
        }
        resetSelectedOptions();
        setIsPouchModalOpen(true);
    }

    const [isPriceLoading, setIsPriceLoading] = useState(false);

    const [priceInfo, setPriceInfo] = useState({
        total_price: 0,
        total_discounted_price: 0,
        option_id_to_discount_info: {},
    });

    const isCountModifiedRef = useRef(false);
    const lastSelectedOptionsRef = useRef(selectedOptions);

    useEffect(() => {
        if (isCountModifiedRef.current) {
            lastSelectedOptionsRef.current = selectedOptions;
            fetchPriceInfo();
        }
        isCountModifiedRef.current = true;
    }, [selectedOptions]);

    const fetchPriceInfo = async () => {
        const targetSelectedOptions = lastSelectedOptionsRef.current;
        try {
            setIsPriceLoading(true);
            const [referralId, referralPostId] = referralInfo;
            const option_count_list = [];
            for (let option_id in targetSelectedOptions) {
                const count = selectedOptions[option_id];
                option_count_list.push({
                    option_id: parseInt(option_id),
                    count: count,
                    referral_id: referralId,
                });
            }
            const res = await Axios.post('v1/store/price/', {
                light_option_count_with_seller_list: option_count_list
            });
            if (targetSelectedOptions !== lastSelectedOptionsRef.current) {
                return;
            }
            if (res.status < 400) {
                setPriceInfo(res.data);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
            return null;
        } finally {
            setIsPriceLoading(false);
        }
    };

    const addToPouch = async () => {
        const options = [];
        const [referralId, referralPostId] = referralInfo;

        for (let option_id in selectedOptions) {
            const count = selectedOptions[option_id];
            options.push({
                option_id: parseInt(option_id),
                count: count,
                referral_id: referralId,
                referral_post_id: referralPostId,
                price: product.options.find(option=> option.id === parseInt(option_id)).discounted_price
            });
        }
        if (options.length === 0) {
            toast.info('옵션을 선택해주세요.');
            return;
        }
        addOptionCountsToPouch(options, user != null, onAddToPouchSuccess, pouchContext,
            false, product.id, product.name);
    };

    const hasReachedMaxBuyCount = product.max_buy_count > 0 && product.max_buy_count <= product.current_buy_count + numSelected;

    const maxBuyCountReachedOptionIds = useMemo(() => {
        for (let option_id in selectedOptions) {
            const option = optionIdToOption[option_id];
            if (option && option.max_buy_count > 0 && (option.current_buy_count != null) && option.max_buy_count <= option.current_buy_count + selectedOptions[option_id]) {
                return [parseInt(option_id)];
            }
        }
        return [];
    }, [selectedOptions]);

    const isPouchForced = !(product.is_direct_web_purchase_product || hasValidDirectPurchaseInfo(product.id));

    const checkOut = useCallback(async () => {
        onPurchase(isGiftMode);
        if (!isGiftMode && isPouchForced) {
            addToPouch();
            return;
        }
        const [referralId, referralPostId] = referralInfo;
        const data = {
            checkout_items: Object.entries(selectedOptions)
                .map(([key, value]) => {
                    return {
                        option_id: parseInt(key),
                        count: value,
                        referral_id: referralId,
                        referral_post_id: referralPostId,
                        reservation_time: null,
                    }
                })
                .filter(e => e.count > 0),
            is_gift: mode === OptionSelectorModeGift,
        };

        if (data.checkout_items.length === 0) {
            toast.info('옵션을 선택해주세요.');
            return;
        }
        setIsLoading(true);
        try {
            const res = await order(data);
            if (res.status < 400) {
                const ordNum = res.data.order_number;
                const checkoutUrl = `/store/orders/${ordNum}/checkout`;
                if (user) {
                    router.push(checkoutUrl);
                } else {
                    redirectToLogin(router, true, undefined, checkoutUrl);
                }
            } else {
                captureMessage(JSON.stringify(res.data));
                toast.info(res.data.display_message || '일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 시도해주세요.');
            }
        } catch (e) {
            captureException(e);
            toast.info('일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }
    }, [selectedOptions, user, mode]);

    const buttonHeight = type === 2 ? 56 : type === 3 ? 48 : 68;
    const fontSize = type === 2 ? 18 : type === 3 ? 16 : 20;

    const closeModal = () => {
        setIsPouchModalOpen(false);
        handleCancel();
        if (resetSelectedOptions) {
            resetSelectedOptions();
        }
    };

    const [recommendedProducts, setRecommendedProducts] = useState([]);

    useEffect(() => {
        fetchRecommendedProducts();
    }, []);

    const fetchRecommendedProducts = async () => {
        try {
            const res = await Axios.get('v1/curation/pouch_product_recommendation/');
            if (res.status < 400) {
                const products = shuffle(res.data.item_list).slice(0, 10);
                setRecommendedProducts(products);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        } finally {
        }
    };

    const groupIdToGroup = useMemo(() => {
        const output = {};
        for (const group of product.product_option_group_list) {
            output[group.group_id] = group;
        }
        return output;
    }, [product]);

    useEffect(() => {
        if (Object.keys(groupIdToGroup).length === 0) {
            return;
        }
        const groupIdToOptionList = {};
        for (const selectedOptionId of Object.keys(selectedOptions)) {
            const selectedOption = optionIdToOption[selectedOptionId];
            if (selectedOption) {
                const groupId = selectedOption.option_group_id;
                const optionList = groupIdToOptionList[groupId] || [];
                optionList.push(selectedOption);
                groupIdToOptionList[groupId] = optionList;
            }
        }

        let isExtraOptionDeleted = false;

        for (const selectedOptionId of Object.keys(selectedOptions)) {
            const selectedOption = optionIdToOption[selectedOptionId];
            if (selectedOption) {
                const groupId = selectedOption.option_group_id;
                const group = groupId ? groupIdToGroup[groupId] : null;
                if (group && Boolean(group.parent_group_id)) {
                    const optionList = groupIdToOptionList[group.parent_group_id] || [];
                    if (optionList.length === 0) {
                        deleteOption(selectedOption);
                        isExtraOptionDeleted = true;
                    }
                }
            }
        }

        if (isExtraOptionDeleted) {
            toast.info('메인 옵션이 삭제돼서 추가 옵션이 삭제되었어요.');
        }
    }, [selectedOptions, optionIdToOption, groupIdToGroup]);

    const deleteOption = (option) => {
        onOptionDelete(option);
        deleteOptionCount(option.id);
    };

    const hasExtraOptions = product.product_option_group_list && product.product_option_group_list.length > 1;
    const isDirectPurchase = product.is_direct_web_purchase_product || hasValidDirectPurchaseInfo(product.id);
    console.log('product', product);


    return (
        <>
            <OptionSelectorPouchAddedModal
                isOpened={isPouchModalOpen}
                handleClose={closeModal}
                product={product}
                recommendedProducts={recommendedProducts}
            />
            <div className={classNames(utilStyles.fullWidth, type === 2 ? styles.containerType2 : null)}>
                {
                    !isGiftMode && product.purchase_info_announcement &&
                    <div className={styles.purchaseInfoContainer}>
                        <SaleIcon/>
                        <span>{product.purchase_info_announcement}</span>
                    </div>
                }
                {
                    isGiftMode &&
                    <div className={styles.giftInfoContainer}>
                        <span>
                            선물 받는 분도 옵션 변경을 할 수 있으며<br/>제품 옵션은 1가지만 선택할 수 있어요
                        </span>
                    </div>
                }
                <div className={styles.bodyContainer}>
                    <div style={{minHeight: 0, flex: type === 2 ? 1 : undefined}}
                         className={type === 2 ? utilStyles.flexCol : undefined}>
                        <OptionSelectionDropDown
                            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
                            product={product}
                            small={small} basePrice={basePrice} options={options}
                            onClick={
                                (e) => {
                                    onOptionSelect(e);
                                    if ((selectedOptions[e.id] || 0) > 0) {
                                        toast.info('이미 선택한 옵션이에요.');
                                    } else if (hasReachedMaxBuyCount) {
                                        toast.info(`${product.max_buy_count}개까지만 구매 가능한 제품이에요.`);
                                    } else if (maxBuyCountReachedOptionIds.includes(e.id)) {
                                        const maxReachedOption = optionIdToOption[maxBuyCountReachedOptionIds[0]];
                                        toast.info(`${maxReachedOption.name}${checkHasConsonant(maxReachedOption.name) ? '은' : '는'} ${maxReachedOption.max_buy_count}개까지만 구매 가능한 옵션이에요.`);
                                    } else {
                                        addOptionCount(e.id, 1);
                                    }
                                }
                            }
                            selectedOptions={selectedOptions}
                            isExtraOptionNotAllowed={isGiftMode}
                        />
                        {isGiftMode && hasExtraOptions && <div className={classNames(styles.giftInfoContainer, styles.giftAdditionalInfo)}>* 선물하기에서는 추가 제품 옵션을 선택할 수 없어요</div>}
                        {
                            (type !== 3 || !isMobile || !isExpanded) &&
                            <div {...{'body-scroll-lock-ignore': "true"}} className={styles.resultContainer} style={{
                                flex: type === 2 ? 1 : undefined,
                                marginTop: options.length > 1 && Object.keys(selectedOptions).length > 0 ? 12 : 0,
                                overflow: 'auto',
                            }}>
                                {
                                    Object.keys(selectedOptions).filter((key) => key in optionIdToOption).map((key, i) => {
                                        const count = selectedOptions[key];
                                        const option = optionIdToOption[key];
                                        const optionPriceInfo = priceInfo.option_id_to_discount_info[option.id];
                                        const discountRate = optionPriceInfo?.discount_rate || option.discount_rate;
                                        return (
                                            <div className={styles.result} key={option.id}>
                                                <div className={styles.optionNameRow}>
                                                    <OptionText name={option.name}/>
                                                    {
                                                        options.length !== 1 &&
                                                        <CloseIcon
                                                            className={styles.floatingClose}
                                                            viewBox="0 0 20 20"
                                                            onClick={
                                                                () => {
                                                                    deleteOption(option);
                                                                }
                                                           }
                                                        />
                                                    }
                                                </div>
                                                <div className={styles.selectorContainer} style={{
                                                    alignItems: 'center', marginTop: 12
                                                }}>
                                                    <div
                                                        className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                                                        <NewQuantitySelector
                                                            count={optionPriceInfo?.count ?? count}
                                                            fontSize={14}
                                                            onPlus={() => {
                                                                onOptionCountChange(option, count + 1);
                                                                if (hasReachedMaxBuyCount) {
                                                                    toast.info(`${product.max_buy_count}개까지만 구매 가능한 제품이에요.`);
                                                                    return;
                                                                }
                                                                if (maxBuyCountReachedOptionIds.includes(option.id)) {
                                                                    const maxReachedOption = optionIdToOption[maxBuyCountReachedOptionIds[0]];
                                                                    toast.info(`${maxReachedOption.name}${checkHasConsonant(maxReachedOption.name) ? '은' : '는'} ${maxReachedOption.max_buy_count}개까지만 구매 가능한 옵션이에요.`);
                                                                    return;
                                                                }
                                                                addOptionCount(option.id, 1);
                                                            }}
                                                            onMinus={() => {
                                                                onOptionCountChange(option, count - 1);
                                                                addOptionCount(option.id, -1);
                                                            }}
                                                            setCount={(val) => {
                                                                onOptionCountChange(option, val);
                                                                if (maxBuyCountReachedOptionIds.includes(option.id) && val > (selectedOptions[option.id] || 0)) {
                                                                    const maxReachedOption = optionIdToOption[maxBuyCountReachedOptionIds[0]];
                                                                    toast.info(`${maxReachedOption.name}${checkHasConsonant(maxReachedOption.name) ? '은' : '는'} ${maxReachedOption.max_buy_count}개까지만 구매 가능한 옵션이에요.`);
                                                                    return;
                                                                }
                                                                setOptionCount(option.id, val);
                                                            }}
                                                        />
                                                        <div style={{width: 12}}/>
                                                    </div>
                                                    <div>
                                                        {
                                                            isSoldOut ?
                                                                <span className={styles.soldOutText}>품절</span>
                                                                :
                                                                <>
                                                                    {
                                                                        isPriceLoading
                                                                            ? <LoadingDot/>
                                                                            :
                                                                            <div className={styles.priceTextWrapper}>
                                                                                {
                                                                                    Boolean(discountRate) && discountRate > 0 &&
                                                                                    <span className={styles.discountRateText}>
                                                                                        {discountRate}%
                                                                                    </span>
                                                                                }
                                                                                <span className={styles.price}>
                                                                                    {numberWithComma((optionPriceInfo?.discounted_price ? optionPriceInfo?.discounted_price * optionPriceInfo.count : option.discounted_price * count))}
                                                                                </span>
                                                                                <span className={styles.won}>원</span>
                                                                            </div>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }

                        {
                            type === 1 &&
                            <div style={{height: 8}}/>
                        }
                    </div>
                    <div className={styles.bottomContainer} style={{marginTop: type === 2 ? 20 : 32}}>
                        <div
                            className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter)}>
                            <span className={styles.sum} style={{fontSize: type === 3 && !isMobile ? 14 : undefined}}>주문금액 합계 :</span>
                            <div className={styles.totalPriceContainer}>
                            {
                                isPriceLoading
                                    ? <LoadingDot />
                                    :
                                    <div>
                                        <span className={classNames(styles.price, styles.totalPrice)}
                                              style={{fontSize: type === 3 && !isMobile ? 20 : undefined}}
                                        >
                                            {
                                                numberWithComma(priceInfo.total_discounted_price || Object.keys(selectedOptions).reduce(
                                                    (prev, curr) => prev + optionIdToOption[curr].discounted_price * selectedOptions[curr], 0
                                                ))
                                            }
                                        </span>
                                        <span className={classNames(styles.won, styles.totalWon)}
                                              style={{fontSize: type === 3 && !isMobile ? 16 : undefined}}>원</span>
                                    </div>
                            }
                            </div>
                        </div>
                        <div className={styles.orderContainer}>
                            {
                                type !== 3 &&
                                <div
                                    className={classNames(styles.likeButtonContainer, type === 2 && styles.likeButtonContainerType2)}>
                                    <ProductScrapButton
                                        productId={product.id}
                                        renderScrapped={() => <ScrapIconActive viewBox="0 0 24 24"
                                                                               className={styles.likeButton}/>}
                                        renderUnscrapped={() => <ScrapIcon viewBox="0 0 24 24"
                                                                           className={styles.likeButton}/>}/>
                                </div>
                            }
                            {
                                !isGiftMode && isPouchForced
                                    ?
                                    <div className={classNames(styles.buyButtonWrapper)}>
                                        <FullButton
                                            disabled={isPriceLoading}
                                            light={isSoldOut} onClick={isLoading || isSoldOut ? () => {
                                        } : checkOut}
                                            fontSize={fontSize} title={isSoldOut ? "품절" : (buttonText || "장바구니 담기")}
                                            height={buttonHeight}
                                        />
                                    </div>
                                    :
                                    <>
                                        {
                                            !isDirectPurchase && !isSoldOut &&
                                            <a className={classNames(styles.pouchButton, type === 2 && styles.pouchButtonType2, type === 3 && styles.pouchButtonType3)}
                                               onClick={() => {
                                                   if (isLoading || isPriceLoading) return;
                                                   addToPouch();
                                               }}>
                                                장바구니에 담기
                                            </a>
                                        }
                                        <div className={classNames(styles.buyButtonWrapper)}>
                                            <FullButton
                                                disabled={isPriceLoading}
                                                light={isSoldOut} onClick={isLoading || isSoldOut ? () => {
                                            } : checkOut}
                                                fontSize={fontSize}
                                                title={isSoldOut ? "품절" :
                                                    (buttonText || (isGiftMode ? "바로 선물하기" : "구매하기"))
                                                }
                                                height={buttonHeight}
                                            />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
