export const faq = {
    order: {
        name: '주문/결제',
        qaList: [
            {
                question: '주문 내역은 어떻게 확인할 수 있나요?',
                answer: '주문 내역은 [마이 페이지 > 나의쇼핑 정보] 에서 확인할 수 있습니다. ',
            },
            {
                question: '비회원 주문이 가능한가요?',
                answer: '비회원도 주문이 가능하며, 비로그인 상태에서 제품 상세페이지에서 바로 구매 버튼을 누르신 후 옵션을 선택하고 구매 단계로 넘어가시면 됩니다.',
            },
            {
                question: '주문 후 퀵이나 방문 수령으로 제품을 받을수 있나요?',
                answer: '헤메코의 모든 판매 제품은 브랜드사에서 직접 출고하고 택배를 발송하는 시스템입니다. 따라서 퀵 배송과 방문 수령은 어려운점 참고 부탁드립니다.',
            },
            {
                question: '장바구니란 무엇인가요?',
                answer: '장바구니란 헤메코 제품 중 마음에 드는 제품을 담아 두었다가 한꺼번에 구매할 수 있는 공간입니다. 주문을 원하시는 제품의 상세페이지에서 [장바구니 담기]를 클릭하여 최대 50개 까지 제품을 보관하실 수 있습니다. 담은 제품은 판매가 종료되기 전까지 보관됩니다.',
            },
            {
                question: '장바구니에 담은 제품이 사라졌어요',
                answer: '장바구니에 담은 제품은 판매기간이 종료되어 더이상 구매 할 수 없는 경우 자동으로 장바구니에서 삭제 됩니다. 해당 경우가 아니라면 1:1 문의를 통해 구매하고 싶은 제품의 브랜드/제품명을 알려주세요. '
            },
            {
                question: '결제방법에는 어떤것들이 있나요?',
                answer: '헤메코에서는 신용(체크)카드, 네이버페이, 토스, 카카오페이, 가상계좌를 통한 결제를 지원하고 있습니다.',
            },
            {
                question: '신용카드로 결제 후 할부 개월수를 변경 하고 싶어요.',
                answer: '결제 이후 할부 개월수는 변경이 불가합니다. 이 경우에는 주문을 취소 후 재주문을 통해 변경이 가능합니다.',
            },
            {
                question: '가상계좌 은행을 잘못 선택했는데, 변경할수 있나요?',
                answer: '한번 발급받은 계좌번호 및 은행은 변경이 불가합니다. 새로운 주문을 통해 계좌를 새로 발급 받으신 후 입금 부탁드립니다.'
            },
            {
                question: '가상계좌 입금은 언제 확인 되나요?',
                answer: '자동 입금 확인까지는 최소 20분에서 2시간 정도 소요됩니다. 그 이후에도 문제가 발생한다면 고객센터 1:1 문의하기를 통해 문의 바랍니다.'
            },
            {
                question: '가상계좌 입금인/입금액을 다르게 입금한 경우 어떻게 해야하나요?',
                answer: '입금자명이 다르더라도 입금액이 같다면 자동으로 확인이 됩니다. 다만, 입금액이 다른 경우 고객센터 1:1 문의를 통해 문의 바랍니다.'
            },
            {
                question: '무통장입금(가상계좌) 결제가 안되는데 어떻게 해야하나요?',
                answer: '가상거래 시 결제가 어렵다면 아래에 해당하는 항목이 있는지 확인해주세요.\n' +
                    '\n' +
                    '1. 입금을 시도한 주문의 가상계좌 번호가 정확히 일치하는지 확인해주세요.\n' +
                    '2. 입금 기한을 확인해주세요. (가장계좌의 입금기한은 주문 완료 후 3일 후 자정까지 입니다.)\n' +
                    '3. 주문한 금액과 입금한 금액이 정확히 일치해야 입금이 가능하므로, 입금 금액을 확인해주세요.\n' +
                    '4. 은행 점검시간에는 무통장 입금이 불가합니다. 다른 시간에 재시도 해주세요.'
            },
            {
                question: '여러개의 결제수단으로 나눠서 결제가 가능한가요?',
                answer: '헤메코에서는 주문 건당 1개의 수단으로 결제가 가능합니다. 다만, 선택한 결제수단 + 포인트의 복합구매는 가능하므로 주문 시에는 한가지의 결제 수단을 이용하거나 헤메코 포인트를 함께 사용하여 구매를 진행해주세요.'
            }
        ],
    },
    delivery: {
        name: '배송',
        qaList: [
            {
                question: '배송비는 얼마인가요?',
                answer: '헤메코에서는 전제품이 \'무료배송\' 입니다.\n' +
                    '\n' +
                    '다만 일부 도서산간지역과 제주지역은 추가배송비가 부과될 수 있습니다.',
            },
            {
                question: '배송 확인은 어떻게 하나요?',
                answer: '배송확인을 원하시면 메인 화면에서 우측 상단의 트럭 모양의 아이콘을 누르시면 바로 배송확인 화면으로 이동합니다.\n' +
                    '\n' +
                    '또한, [마이페이지 - 나의 쇼핑정보 - 주문/배송 조회] 탭에서도 주문 하신 제품의 주문 및 배송 상태를 확인할 수 있습니다.'
            },
            {
                question: '주문 후 배송지 주소를 변경하고 싶은데 어떻게 해야하나요?',
                answer: '한번 주문이 완료 되면 주소지 변경이 어렵습니다. 주문 완료 상태에서는 주문 취소 후, 재주문을 부탁드립니다. 배송 준비중이거나 배송 중 상태에서는 주문 시 입력하신 배송지에서 물건을 수령 부탁드립니다.',
            },
            {
                question: '배송은 얼마나 걸리나요?',
                answer: '헤메코에서는 주문 후 입금 완료 시점부터 배송준비중 상태로 변경되며 출고 진행을 합니다. 일반적으로 결제완료일 기준 3-5일 이내로 준비기간이 소요됩니다. (공휴일 및 주말은 배송일에서 제외) 주문 폭주로 배송이 지연되거나 품절일 경우 브랜드사에서 별도로 고객님게 연락을 드리고 있습니다.'
            },
            {
                question: '여러 제품을 묶음 배송 받으려면 어떻게 해야하나요?',
                answer: '헤메코의 제품은 각 브랜드사별로 출고지가 상이할수 있기때문에 묶음 배송은 어렵습니다. 단, 동일한 브랜드사의 경우 브랜드 정책에 따라 가능할 수 있습니다.',
            },
            {
                question: '한번에 주문한 제품이 일부만 왔어요.',
                answer: '헤메코에서는 다른 판매자의 제품들을 한번에 주문할 후는 있으나 각 브랜드사별(혹은 제품별)로 출고지가 다르기 때문에, 제품 발송 및 도착 시점이 상이할 수 있습니다. 아직 도착하지 않은 제품이 있다면 미도착한 제품의 송장 번호로 배송현황을 추적하거나 판매자쪽으로 연락해 배송 일정을 확인해주세요.',
            },
            {
                question: '운송장 번호는 확인이 되는데 배송 현황이 확인이 되지 않을땐 어떻게 해야하나요?',
                answer: '일반적으로 운송장 번호 등록 후 1영업일 이내 배송현황이 가능합니다. 판매자가 송장을 먼저 출력하여 송장번호부터 입력하는 경우가 있으니, 이틀이상 배송현황 조회가 되지 않으면 판매자 측으로 문의 부탁드립니다.',
            },
            {
                question: '배송 조회를 해보면 배송 완료로 뜨는데 택배를 받지 못했어요. 어떻게 해야 하나요?',
                answer: '경비실 또는 무인 택배함을 먼저 확인 부탁드립니다. 별도의 위탁 장소가 없는 경우 배송 기사님께 직접 문의 부탁드립니다.'
            },
            {
                question: '해외로도 배송이 가능한가요?',
                answer: '헤메코는 현재 국내의 주문 및 배송만 가능하며, 해외로의 배송은 어려운점 양해 부탁드립니다.',
            },
            {
                question: '기본 주소록은 어떻게 수정할수 있나요?',
                answer: '주소록은 [마이 페이지 > 나의 정보 관리 > 주소록 관리] 를 통해 수정이 가능하며, 제품 구매 시 제품을 수령하실 배송지 정보를 직접 관리할 수 있습니다. 배송지는 최대 10개까지 등록이 가능합니다.'
            },
            {
                question: '배송지는 몇개까지 등록이 가능한가요?',
                answer: '배송지는 최대 20개까지 등록 가능하며, 신규 주소 추가를 원하시는 경우 기존 주소록을 삭제 한 후 신규 주소를 추가하여 이용 부탁드립니다.',
            },
        ],
    },
    return: {
        name: '취소/환불',
        qaList: [
            {
                question: '환불 또는 교환 시, 배송 비용은 얼마인가요?',
                answer: '반품 또는 교환 사유에 따라 반품 비용이 상이할 수 있습니다. 반품/교환 요청 시 반품/교환 예상금액 확인이 가능합니다. 기타 궁금한 사항이 있는 경우 [마이페이지 - 고객센터 - 1:1 문의] 를 통해 문의해주세요.',
            },
            {
                question: '환불처리가 지연되고 있어요.',
                answer: '반품제품은 판매자가 제품을 확인 한 후 반품이 완료되며, 반품 제품 발송이 지연되는 경우에는 반품 처리 및 환불이 지연될 수 있습니다.'
            },
            {
                question: '환불된 금액이 처음에 결제한 금액과 달라요.',
                answer: '다음과 같은 경우 환불된 금액과 처음 결제 금액이 다를 수 있어요.\n' +
                    '\n' +
                    '1. 결제 시점에서 카드사 할인 또는 PG사 할인을 받으셨을 경우, 해당 할인금액이 차감된 후 환불됩니다. 이 경우 추가 문의는 해당 카드사로 문의 부탁드립니다.\n' +
                    '2. 헤메코에서는 전제품 무료배송이나, 환불 사유가 단순 변심일 경우 초기 배송비 (제품이 배송될 때의 배송비)와 반품배송비 (제품이 수거될 때의 배송비)를 제한 후 환불 됩니다.\n' +
                    '3. 쿠폰 할인을 적용한 주문건에 대해 부분취소/반품 하는 경우, 취소/반품한 제품에 적용된 쿠폰 할인 금액 만큼 차감된 후 환불됩니다.',
            },
            {
                question: '환불계좌를 등록/변경하고 싶어요.',
                answer: '환불계좌는 [마이페이지 - 나의 정보관리 - 환불계좌 관리] 에서 등록 및 수정이 가능합니다.'
            },
            {
                question: '환불 받았는데 카드는 환불되지 않은채 그대로 청구되었어요.',
                answer: '신용카드는 결제일에 따라 카드사로 결제 취소를 요청하더라도 청구되는 경우가 있습니다. 취소 시점에 따라 혹시라도 대금이 먼저 결제된 경우에는 익월 카드사에서 자동으로 고객님 계좌로 입금 또는 기존 청구 금액에서 차감하여 청구됩니다. 해당 내용은 각 카드사 및 카드 종류에 따라 상이하므로 보다 자세한 확인은 해당 카드사로 문의 부탁드립니다.',
            },
            {
                question: '주문 취소는 어떻게 하나요?',
                answer: '결제 전 또는 결제 완료단계에서는 [마이페이지] - [주문내역] 에서 주문 취소가 가능합니다. 판매자 제품 준비중 단계 이후에서는 주문 취소는 불가합니다.'
            },
            {
                question: '취소한 주문의 처리 현황을 알고 싶어요. 어디에서 확인하나요?',
                answer: '취소 처리 현황은 [마이페이지] - [취소/교환/반품 조회]에서 확인 가능합니다.',
            },
            {
                question: '여러 개 제품 주문 했는데 부분 취소가 가능한가요?',
                answer: '부분 취소는 불가하므로 ‘결제 완료\' 상태에서 전체 주문을 취소 후, 원하는 제품으로 재주문 해주세요.'
            },
            {
                question: '주문이 자동 취소 되어있어요.',
                answer: '가상계좌는 주문 후 3일 이내 미입금시, 다음날 주문이 자동으로 취소됩니다. 입금 지연으로 인해 취소된 주문은 철회가 불가하므로 재주문 해주셔야 합니다.\n' +
                    '무통상 입금 시 사용되는 가상계좌는 매 주문시 마다 새로운 계좌번호가 부여되므로, 다른 주문건에 대한 가상계좌로 입금하시거나 잘못된 계좌로 입금하시면 자동으로 입금확인이 되지 않습니다. 이런 경우에는  [마이페이지 - 고객센터 - 1:1 문의] 를 통해 문의해주세요.',
            },
        ],
    },
    change: {
        name: '반품/교환',
        qaList: [
            {
                question: '반품 배송비는 어떻게 부과 되나요?',
                answer: '제품을 반품하는 경우 반품 원인을 제공한 측에서 배송비를 부담 합니다. 반품 시 귀책사유에 따른 상황별 배송비 부담기준은 아래와 같습니다.\n' +
                    '\n' +
                    '1. 구매자 사유 (단숨 변심, 옵션 변경, 주소지 오류 등) \n' +
                    '- 부담 배송비: 초기 배송비 + 반품 배송비 \n' +
                    '- 결제 금액에서 왕복 배송비 차감 후 환불 또는 왕복 배송비 별도 결제 후 전액 환불 \n' +
                    '2. 판매자 사유 (제품 불량, 파손, 하자, 오배송 등)\n' +
                    '- 부담 배송비: 없음 \n' +
                    '- 판매자가 반품 배송비를 부담하며, 결제금액 전액 환불 됩니다.',
            },
            {
                question: '교환이나 반품은 어떻게 하나요?',
                answer: '구매자의 단순변심으로 인한 반품/교환은 제품 수령 후 7일 이내 가능합니다.\n' +
                    '\n' +
                    '교환은 동일 제품 혹은 동일 제품 내 추가금액이 없는 옵션으로만 교환 가능하며, 동일 브랜드 내 다른 제품으로 교환을 원하시는 경우, 기존 구매 제품은 반품 후 재주문을 부탁드립니다.\n' +
                    '\n' +
                    '교환 접수 절차 없이 임의로 반송할 경우, 처리가 지연될 수 있으니 꼭 MY PAGE에서 교환 신청 후 제품을 반송해주세요. 아래의 경우에는 교환/반품이 불가하니 유의해주세요.\n' +
                    '\n' +
                    '1. 배송 완료일로부터 7일 초과 시\n' +
                    '2. 구매자의 책임 사유로 제품 등이 멸실 또는 훼손 된 경우'
            },
            {
                question: '제품을 교환하고 싶어요. 절차를 알려주세요.',
                answer: '1. [마이페이지] - [주문/배송조회] - [교환신청] 에서 교환 신청을 완료해주세요.\n' +
                    '2. 교환 제품의 배송지와 교환 비용을 결제해주세요.\n' +
                    '3. 판매자가 요청된 내용을 확인하면, 판매자가 1-3영업일 이내로 연락 드릴 예정입니다.\n' +
                    '- 교환제품을 박스에 넣어 준비해주세요.\n' +
                    '- 판매자가 교환제품을 확인한 후 교환이 완료됩니다.\n' +
                    '- 교환제품 발송이 지연되는 경우교환처리가 지연될 수 있습니다.\n' +
                    '4. 수거완료된 제품은 검수 과정을 거치며, 검수완료까지 1-3영업일 정도 소요될 수 있습니다.\n' +
                    '5. 검수가 완료되면 새제품이 발송 됩니다.',
            },
            {
                question: '제품을 반품하고 싶어요. 절차를 알려주세요.',
                answer: '1. [마이페이지] - [주문/배송조회] - [반품신청] 에서 반품 신청을 완료해주세요.\n' +
                    '2. 반품 비용을 추가 결제해주세요.\n' +
                    '3. 판매자가 요청된 내용을 확인하면, 판매자가 1-3영업일 이내로 연락 드릴 예정입니다. 아래의 경우에는 반품이 불가하니 유의해주세요.\n' +
                    '- 반품제품을 박스에 넣어 준비해주세요.\n' +
                    '- 판매자가 반품제품을 확인한 후 반품이 완료됩니다.\n' +
                    '- 반품제품 발송이 지연되는 경우반품처리 및 환불이 지연될 수 있습니다.\n' +
                    '4. 수거완료된 제품은 검수 과정을 거치며, 검수완료까지 1-3영업일 정도 소요될 수 있습니다.\n' +
                    '5. 검수가 완료되면 환불이 완료 됩니다.'
            },
            {
                question: '구매 확정했는데 반품/교환 하고 싶어요.',
                answer: '이미 구매 확정된 주문건은 시스템상 반품/교환 요청이 어려워 1:1 문의를 통해 문의 부탁드립니다. 또한 배송완료 후 7일이 경과하여 자동 구매 확정된 경우, 반품/교환 요청이 어려울 수 있습니다.'
            },
            {
                question: '반품/교환 신청 버튼이 안뜨는데 어떻게 해야하나요?',
                answer: '기본적으로 제품 수령 후 7일 이내 반품/교환 신청이 가능하지만, 아래의 경우에는 교환/반품이 불가하니 유의해주세요.\n' +
                    '\n' +
                    '- 배송 완료일로부터 7일 초과 시\n' +
                    '- 구매자의 책임 사유로 제품 등이 멸실 또는 훼손 된 경우\n' +
                    '- 시간이 경과되어 재판매가 어려울 정도로 제품 가치가 상실된 경우',
            },
        ],
    },
    member: {
        name: '회원',
        qaList: [
            {
                question: '헤메코 가입 절차가 궁금해요.',
                answer: '헤메코 신규 가입은 이메일 가입, SNS 가입 두 가지 방법으로 가능합니다.\n' +
                    '1) 이메일 가입 \n' +
                    '- 본인의 이메일 주소를 아이디로 사용하여 가입이 가능합니다. \n' +
                    '2) SNS 가입 \n' +
                    '- 사용하고 계신 SNS 계정을 통해 간편한 회원가입이 가능합니다. \n' +
                    '(네이버, 카카오, 구글 등) \n' +
                    '\n' +
                    '* 유의사항\n' +
                    '헤메코에서는 첫 주문시, 휴대폰 본인인증을 진행합니다. \n' +
                    '본인 인증은 사용 중인 휴대폰 번호를 통한 인증방식입니다.',
            },
            {
                question: '본인인증은 언제 진행하나요?',
                answer: '본인인증은 개인정보 보호가 필요한 주문이나, 회원정보 변경등에 주로 적용하고 있으며, 헤메코는 아래와 같이 휴대폰 본인인증을 진행하고 있습니다. \n' +
                    '*(주)다날에서 제공하는 본인확인서비스, 본인확인 기관 > 이동통신사로 이동 \n' +
                    '1) 가입 후 첫 제품 주문 시 본인 인증을 합니다.\n' +
                    '2) 이메일 찾기 또는 비밀번호를 잊어 변경을 시도하는 등의 경우에 본인 인증을 합니다. \n' +
                    '* 위 2가지 사항에 해당될 경우 본인 인증 진행은 필수이며, 본인 명의 휴대폰번호에 1개의 아이디만 인증 가능합니다. 최초 본인인증을 완료한 아이디로 헤메코 서비스를 이용하실 수 있으니,이전 사용하신 아이디가 있으시면 해당 아이디를 이용해주세요.'
            },
            {
                question: '헤메코 웹에서는 SNS 로그인이 가능한가요?',
                answer: '헤메코 웹에서는 네이버/카카오/구글 연동 SNS 로그인이 가능합니다.',
            },
            {
                question: '비밀번호 변경은 어떻게 하나요?',
                answer: '비밀번호 변경은 [마이페이지 > 회원정보 수정> 비밀번호 변경] 페이지에서 변경 가능합니다.'
            },
            {
                question: '회원정보는 어디에서 수정하나요?',
                answer: '회원정보는 [마이페이지 > 회원정보 수정] 페이지에서 직접 수정 가능합니다. 원활한 주문과 배송을 위해서 회원정보를 정확하게 입력해주세요.',
            },
            {
                question: '이름 개명 시 정보 수정은 어디에서 하나요?',
                answer: '[마이페이지 > 회원정보 수정 > 기본정보] 탭 에서 본인 인증을 통해 기본정보를 등록 및 수정할 수 있으며, 개명하신 경우 본인인증을 통해 자동으로 이름이 변경됩니다.'
            },
            {
                question: '회원탈퇴는 어떻게 하나요? ',
                answer: '회원탈퇴는 헤메코 PC/모바일웹 [마이페이지 > 회원정보 수정 > 회원탈퇴] 탭을 통해 고객님께서 직접 진행하시면 됩니다.',
            },
            {
                question: '탈퇴 후 재가입이 가능하나요?',
                answer: '동일한 아이디로의 헤메코 재가입은 탈퇴 후 30일 이후에 가능해요 😊 탈퇴 후 30일 이내에 동일한 아이디로의 계정복구를 원하신다면 고객센터로 문의 주시면 처리 도와 드리겠습니다. '
            },
            {
                question: '유저네임은 최대 몇글자 까지 사용가능한가요?',
                answer: '최소 2글자, 최대 20글자 까지 사용 가능합니다.',
            },
        ],
    },
    etc: {
        name: '기타',
        qaList: [
            {
                question: '선물 포장도 가능하나요?',
                answer: '헤메코에서는 선물 포장은 현재 제공하지 않습니다.',
            },
            {
                question: '오프라인 매장에서 제품을 확인할 수 있나요?',
                answer: '헤메코에서는 현재 오프라인 매장을 운영하고 있지 않습니다.'
            }
        ],
    }
}
